import {requestFailed, requestFinished, requestStarted} from '../store/actions/RequestActions'

export const requestHelper = (dispatch: any, requestName: any, request: any) => {
    const requestId = [Date.now(), requestName].join('_');

    dispatch(requestStarted(requestId, requestName))

    request().then(
        () => {
            dispatch(requestFinished(requestId, requestName))
        },
        (error: any) => {
            console.log(error)
            if (error.message !== 'canceled') {
                dispatch(requestFailed({requestId, requestName, error}))
            }
        }
    )
}

import {forwardRef} from 'react'
import PropTypes from 'prop-types'
import {IMaskInput} from 'react-imask'

import TDXOutlinedInput from './TDXOutlinedInput'

interface CustomPhoneMask {
    onChange: (val: any) => void,
    other?: Record<string, any>
}

const CustomPhoneMask = forwardRef((props: CustomPhoneMask, ref: React.Ref<HTMLInputElement>) => {
    const {onChange, ...other} = props

    return (
        <IMaskInput
            {...other}
            mask={[
                {
                    mask:"+{#} (000) 000-00-00",
                    definitions: {
                        '#': /[7]/
                    }
                },
                {
                    mask:"+{#./} (00) 000-00-00",
                    definitions: {
                        '#': /[3]/,
                        '.': /[7]/,
                        '/': /[5]/
                    }
                },
            ]}
            //mask="+{375} (00) 000-00-00"
            inputRef={ref}
            onAccept={value =>
                onChange({target: {id: (other as Record<string, any>)?.id || '', name: (other as Record<string, any>)?.name || '', value}})
            }
            overwrite
        />
    )
})
CustomPhoneMask.displayName = 'CustomPhoneMask'

export default function TDXMobileNumberInput (props: Record<string, any>) {
    return (
        <TDXOutlinedInput
            {...props}
            value={props.value || ''}
            InputProps={{
                inputComponent: CustomPhoneMask,
            }}
        />
    )
}

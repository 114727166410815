import {ProfileReducer} from './ProfileReducer'
import {ProductReducer} from './ProductReducer'
import {GeneralReducer} from './GeneralReducer'
import {ManagerReducer} from './ManagerReducer'
import {CompareReducer} from './CompareReducer'
import {RequestReducer} from './RequestReducer'
import {AuctionReducer} from './AuctionReducer'

export default {
    Manager: ManagerReducer,
    Profile: ProfileReducer,
    Product: ProductReducer,
    General: GeneralReducer,
    CompareList: CompareReducer,
    Request: RequestReducer,
    Auction: AuctionReducer,
}

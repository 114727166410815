import {TextField} from '@mui/material'

const TDXOutlinedInput = (props: Record<string, any>) => (
    <TextField
        {...props}
        className={`tdx-outlined-input ${props.className ? props.className : ''}`}
        variant="outlined"
    />
)

export default TDXOutlinedInput

import {Button, CircularProgress, Tooltip} from '@mui/material'

interface TDXButtonInterface extends JSX.IntrinsicAttributes {
    id?: string,
    bg?: string,
    size?: string,
    label?: string,
    company?: string,
    count?: number | boolean,
    availability?: string | number | boolean,
    loading?: boolean,
    disabled?: boolean,
    endIcon?: any,
    startIcon?: any,
    onClick?: (e?: any) => any,
    className?: string,
    type?: "submit" | "reset" | "button" | undefined,
    children?: any,
    sx?: any,
    href?: string,
    title?: string,
    tooltipLabel?: string,
}

export default function TDXButton (props: TDXButtonInterface) {
    const {
        id,
        bg,
        size,
        label,
        company,
        count = false,
        availability,
        loading,
        disabled,
        endIcon = null,
        startIcon = null,
        onClick = (e: any) => e,
        className = '',
        tooltipLabel = '',
        ...other
    } = props

    const content = (
        <>
            {props.children || company || availability || label ? (
                <span className="tdx-button__content">
                    {props.children}
                    {company ? <p className="tdx-button__company">{company}</p> : null}
                    {availability ? <p className="tdx-button__availability">{availability}</p> : null}
                    {label ? <span className="tdx-button__label">{label}</span> : null}
                    {count ? <span className="tdx-button__counter">{count}</span> : null}
                </span>
            ):''}
        </>
    )

    const handleClick = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        !loading && onClick(e)
    }

    const btnTemplate = (
        <Button
            {...other}
            id={id}
            className={`tdx-button ${size} ${'bg-' + bg} ${loading ?? ''} ${className ?? ''}`}
            variant="contained"
            disabled={disabled}
            startIcon={loading ? null : startIcon}
            endIcon={loading ? null : endIcon}
            disableElevation
            onClick={handleClick}>
            {loading ? <CircularProgress color="inherit" size={16} /> : content}
        </Button>
    )

    return tooltipLabel.length > 0 ? (
        <Tooltip title={tooltipLabel}>
            <div>
                {btnTemplate}
            </div>
        </Tooltip>
    ): btnTemplate
}

import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSearchParams} from 'react-router-dom'
import {parseSearchParams} from '../../AsideFilter/utils'
import {useAppDispatch, useAppSelector} from '../../../../shared/hooks/hooks'
import {asideFiltersActions} from '../../../../store/slice/asideFiltersSlice/asideFiltersSlice'
import {usePrevious} from '../../../customHooks'
import TDXInput from "../TDXInput";
import {FilterData, FilterTextData} from "../../../../store/slice/asideFiltersSlice/types";

const TDXFilterText = ({id, data, rememberKey, pattern = ""}:{
    id: string,
    data: FilterTextData,
    rememberKey?: string,
    pattern?: string,
}) => {
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams()
    const throttle = 1500
    const {isActive, values, allowFiltered, smartFilters} = useAppSelector(state => state.AsideFilters)
    const [isChanged, setChanged] = useState(false)

    const getState = (): string => {
        /*let params: Record<string, any> = parseSearchParams(searchParams)

        if (rememberKey) {
            params = {
                ...params,
                ...parseSearchParams(JSON.parse(localStorage.getItem(rememberKey)) ?? {}),
            }
        }*/
        //return params[id] ? params[id]['text']: values[id]['text']
        return values[id]['text'].toString() ?? ''
    }

    const [state, setState] = useState<string>(() => getState())

    useEffect(() => {
        const state_tmp = getState()

        if(state_tmp !== state) {
            setState(state_tmp)
        }
    },[values, smartFilters])

    const prevState = usePrevious(state)

    const handleChange = (newVal: any) => {
        const pattern_tmp = new RegExp(pattern)

        if (pattern_tmp.test(newVal.target.value) || newVal.target.value === '') {
            setChanged(true)
            setState(newVal.target.value)
        }
    }

    useEffect(() => {
        if (Object.keys(isActive).length > 0 && !isActive[id]) {
            setState('')
        }
    }, [values])

    useEffect(() => {
        if (prevState!==state && isChanged) {
            const timeout = setTimeout(() => {
                dispatch(asideFiltersActions.updateFilterValue({id, newValue: {'text': state.trim()}}))
                clearTimeout(timeout)
            }, throttle)
            setChanged(false)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [state])

    return (
        <div className="tdx-filter-text">
            <TDXInput
                label={data?.label}
                value={state}
                onChange={handleChange}
                className="tdx-outlined-input"
            />
        </div>
    )
}

export default TDXFilterText

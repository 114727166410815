import {Link} from 'react-router-dom'

interface TDXNewsInterface {
    img: string,
    name: string,
    description: string,
    date: string,
    to: string
}

export default function TDXNews ({img, name = '', description = '', date = '', to = ''}: TDXNewsInterface) {
    return (
        <Link to={to} className="tdx-news-card">
            <div className="image-container">
                <div className="blackout" />
                <img src={img} alt={name} />
            </div>
            <div className="info-container">
                <p className="body-2">{date}</p>
                <h6>{name}</h6>
                <p className="body-2 description">{description}</p>
            </div>
        </Link>
    )
}

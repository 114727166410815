import {SvgIcon} from '@mui/material'

const PhoneIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '13px', height: '13px', color: 'rgba(0, 0, 0, 0.3)'}}
        viewBox="0 0 13 13">
        <path d="M12.9996 9.733V11.69C13.0003 11.8717 12.963 12.0515 12.8901 12.218C12.8172 12.3845 12.7102 12.5339 12.5761 12.6567C12.442 12.7795 12.2836 12.873 12.1111 12.9312C11.9387 12.9894 11.756 13.0111 11.5747 12.9947C9.56332 12.7766 7.63128 12.0907 5.93379 10.992C4.35449 9.99045 3.01552 8.65414 2.01197 7.07797C0.907309 5.37614 0.219857 3.43855 0.00530538 1.42217C-0.0110287 1.24177 0.0104527 1.05996 0.0683815 0.888307C0.12631 0.716653 0.219418 0.558917 0.341775 0.425143C0.464133 0.291368 0.61306 0.184486 0.779074 0.111302C0.945089 0.0381173 1.12455 0.00023382 1.30604 6.3246e-05H3.26695C3.58417 -0.00305263 3.89169 0.109056 4.13221 0.315492C4.37273 0.521928 4.52983 0.808606 4.57422 1.12209C4.65699 1.74838 4.81048 2.36332 5.03177 2.95517C5.11971 3.18866 5.13874 3.44241 5.08661 3.68637C5.03448 3.93032 4.91337 4.15425 4.73763 4.33161L3.90751 5.16008C4.838 6.79325 6.19292 8.14549 7.82933 9.07413L8.65945 8.24566C8.83717 8.07027 9.06154 7.9494 9.30598 7.89737C9.55041 7.84534 9.80467 7.86434 10.0386 7.95211C10.6316 8.17296 11.2478 8.32614 11.8753 8.40874C12.1929 8.45345 12.4828 8.61306 12.6901 8.85723C12.8974 9.10139 13.0076 9.41307 12.9996 9.733Z" />
    </SvgIcon>
)

export default PhoneIcon

import {getFileAsBlob, get, post} from './api'

export const PriceListService = {
    getPriceList: (hash: string, type: string, columns: string | null = null) => {
        const params = columns ? {columns} : null
        return getFileAsBlob(`/price/${type}/${hash}.${type}`, params)
    },
    getPriceLists: () => {
        return get('/price-list/settings')
    },
    updatePriceListSettings: (id: number, columnsOrder: string[] = [''], show_reserve: boolean) => {
        return post('/price-list/settings', {
            id,
            pricelist_settings: columnsOrder,
            pricelist_show_reserve: show_reserve,
        })
    },
}

import {createAsyncThunk} from '@reduxjs/toolkit'
import {PriceListService} from '../../../../../api/priceList.service'
import {PriceListSchema} from '../../types'

export const getPriceLists = createAsyncThunk<
    PriceListSchema[], void
>(
    'priceList/getPriceLists',
    async (_, {rejectWithValue}) => {
        try {
            const pricelists = await PriceListService.getPriceLists()

            pricelists.forEach(
                (pricelist : any, index: number) => {
                    const columnsOrderEntries = (
                        pricelist.settings.pricelist_settings.map(
                            (column: string, i: number) => [i + 1, column]
                        )
                    );
                    pricelists[index].settings = {
                        columnsOrder: Object.fromEntries(columnsOrderEntries),
                        show_reserve: pricelist.settings.pricelist_show_reserve
                    }
                }
            )

            return pricelists
        } catch (e) {
            console.error(e)
            rejectWithValue('priceList/getPriceLists server error, see logs')
        }
    }
)

import {get, post, Ws} from './api'

const AuctionService = {
    getAuctionList: () => get('/auctions/'),
    getAuction: (id?: string, params?: Record<string, any>) => get(`/auctions/${id ? `${id}` : ''}`, {params}),
    setBid: (id: string, body: Record<string, any>) => post(`/auctions/${id}/set_bid`, body),
    getAuctionData: (id: string) => Ws(`/auctions/${id}`),
}

export const AuctionRequestsEnum = {
    getAuctionList: '/auctions/',
    getAuction: (id: string) => `/auctions/${id}`,
}

export default AuctionService

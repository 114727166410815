import {useState} from 'react'
import {Menu, MenuItem, PopoverProps} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const HeaderLinkMenu = ({title, children}: {
    title: string,
    children: any,
}) => {
    const [anchorEl, setAnchorEl] = useState<PopoverProps["anchorEl"]>(null)
    const open = Boolean(anchorEl)

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)
    const closeMenu = () => setAnchorEl(null)

    const createLinks = (children: any): React.ReactNode => {
        return Array.isArray(children) ? (
            children.map((link, i) => {
                return Array.isArray(link) ? (
                    createLinks(link)
                ) : link && (
                    <MenuItem key={i} onClick={closeMenu}>
                        {link}
                    </MenuItem>
                )
            })
        ) : (
            <MenuItem onClick={closeMenu}>{children}</MenuItem>
        )
    }

    return (
        <div className="header-link-menu link">
            <button className="tdx-nav-link" onClick={openMenu}>
                <h5>{title}</h5>
                <KeyboardArrowDownIcon />
            </button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                elevation={2}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                PopoverClasses={{paper: 'header-link-menu-paper'}}
                onClose={closeMenu}>
                {createLinks(children)}
            </Menu>
        </div>
    )
}

export default HeaderLinkMenu

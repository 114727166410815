import {
    SET_PROFILE,
    SET_PROFILE_ORDERS,
    SET_PROFILE_RESERVE_ORDERS,
    SET_PROFILE_SUPPLIES,
    SET_PROFILE_PAYMENTS,
    SET_PROFILE_CONTRACTS,
    SET_PROFILE_WAITING_LIST,
    UPDATE_PROFILE,
    ADD_PRODUCT_WAITING_LIST,
    REMOVE_PRODUCT_WAITING_LIST,
    UPDATE_PROFILE_ORDER,
    UPDATE_PROFILE_SUPPLY,
    SET_SUPPLIERS,
    SET_NEW_SUPPLY,
    SET_PROFILE_REFUSALS,
    SET_PROFILE_MATCHING,
    SET_API_DATA,
    SET_WAITING_LIST, ProfileState, UserState,// OrderSerializer
    WaitListSerializer,
} from '../actions/ProfileActions'
import {OrderSerializer} from '../slice/cartSlice/types'
import {prepareProductId} from '../../utils/prepareProduct'

const initialState = {
    profile: {
        id: 0,
        name: '',
        money: 0,
        isManager: false,
        isSpecial: false,
        isCustomPrice: false,
        isMegaSklad: false,
        isElektronmir: false,
        currency: {},
        rates: {},
        formValues: {
            general: {},
            delivery: {},
            billing: {},
            legalAddress: {},
            dischargeAddress: {},
            partners: [],
        },
        notify: [],
    } as ProfileState,
    payments: [],
    orders: [],
    reserveOrders: [],
    waitList: [],
    waitList_mini: [],
    supplies: [],
    suppliers: [],
    refusals: [],
    contracts: [],
    matching: [],
} as UserState

export const ProfileReducer = (state = initialState, action: any) : UserState => {
    switch (action.type) {
        case UPDATE_PROFILE:
        case SET_PROFILE: {
            const profile = action.data.profile as ProfileState
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...profile,
                },
            }
        }
        case SET_PROFILE_PAYMENTS:
            return {
                ...state,
                payments: action.data.payments,
            }

        case SET_PROFILE_CONTRACTS:
            return {
                ...state,
                contracts: action.data.contracts,
            }

        case SET_PROFILE_ORDERS:
            return {
                ...state,
                orders: action.data.orders,
            }

        case SET_PROFILE_RESERVE_ORDERS:
            return {
                ...state,
                reserveOrders: action.data.reserve_orders,
            }

        case UPDATE_PROFILE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order => {
                    if (order.id === action.data.order.id) {
                        return {
                            ...order,
                            ...action.data.order,
                        }
                    }

                    return order
                }),
            }
        case SET_PROFILE_SUPPLIES:
            return {
                ...state,
                supplies: action.data.supplies,
            }

        case UPDATE_PROFILE_SUPPLY:
            return {
                ...state,
                supplies: state.supplies.map(supply => {
                    if (supply.id === action.data.supply.id) {
                        return {
                            ...supply,
                            ...action.data.supply,
                        }
                    }
                    return supply
                }),
            }

        case SET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.data.suppliers,
            }

        case SET_NEW_SUPPLY:
            return {
                ...state,
                supplies: [action.data.supply, ...state.supplies],
            }

        case SET_PROFILE_WAITING_LIST:
        {
            if (!action?.data?.waitList)
            {
                throw Error('not_implemented');
            }

            const waitlists = action.data.waitList as WaitListSerializer[];

            waitlists.forEach((w) => {
                w.offers.forEach((o) => {
                    o._id = prepareProductId(o);
                })
            });

            return {
                ...state,
                waitList: waitlists
            }
        }
        case ADD_PRODUCT_WAITING_LIST:
            return {
                ...state,
                waitList: action.data,
            }
        case REMOVE_PRODUCT_WAITING_LIST:
            return {
                ...state,
                waitList: [...state.waitList.filter(item => item.sku !== action.data[0])],
            }
        case SET_PROFILE_REFUSALS:
            return {
                ...state,
                refusals: action.data.refusals,
            }
        case SET_PROFILE_MATCHING:
            return {
                ...state,
                matching: action.data.matching,
            }
        case SET_WAITING_LIST:
            return {
                ...state,
                waitList_mini: action.data.waitList_mini,
            }
        case SET_API_DATA:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.data.profile,
                },
            }
        default:
            return state
    }
}

import {getSettings} from '../store/actions/GeneralActions'
//import {useAppSelector} from "../shared/hooks/hooks";
//import {useDispatch} from "react-redux";

const settings_init = (
    settings:any = undefined,
    dispatch:any = undefined,
    {settings2 = undefined}: {settings2?: any} = {}
) => {
    if (dispatch === undefined)
    {
        throw Error('dispatch is not provided');
    }

    if (settings === undefined && settings2 !== undefined)
    {
        settings = settings2;
    }

    if (settings === null || settings === undefined) {
        dispatch(getSettings())
    }
}

export default {settings_init};

import {createAsyncThunk} from '@reduxjs/toolkit'
import CatalogService from '../../../../../api/catalog.service'
import {Category} from '../../types'

export const getCategories = createAsyncThunk<Category[]>(
    'catalogCategories/getCategories',
    async () => {
        try {
            return (await CatalogService.getCategories()) || []
        } catch (e) {
            console.error(e)
            return []
        }
    }
)

export type OrderStatus =
    | 'NONE'
    | 'NEW'
    | 'RESERVE'
    | 'RESERVE_EXTERNAL'
    | 'RESERVE_PARTIAL'
    | 'WAIT'
    | 'ASSEMBLING'
    | 'WAIT_CLIENT'
    | 'WAIT_DELIVERY'
    | 'DELIVERY'
    | 'READY'
    | 'REMOVED'

export const orderStatuses : Record<number, OrderStatus> = {
    0: 'NONE',
    1: 'NEW',
    11: 'RESERVE',
    12: 'RESERVE_EXTERNAL',
    14: 'RESERVE_PARTIAL',
    20: 'WAIT',
    30: 'ASSEMBLING',
    31: 'WAIT_CLIENT',
    32: 'WAIT_DELIVERY',
    33: 'DELIVERY',
    90: 'READY',
    91: 'REMOVED',
}

export const orderStatusesDescription : Record<OrderStatus, string> = {
    'NONE': '',
    'NEW': 'Что-то пошло не так, товар НЕ в резерве',
    'RESERVE': 'Товар зарезервирован на основном складе',
    'RESERVE_EXTERNAL': 'Товар зарезервирован на удаленном складе',
    'RESERVE_PARTIAL': 'Заказ ожидает подтверждения менеджера',
    'WAIT': 'Заказ в пути на основной склад',
    'ASSEMBLING': 'Заказ собирается складом',
    'WAIT_CLIENT': 'Заказ собран складом и готов к получению',
    'WAIT_DELIVERY': 'Заказ собран складом и ожидает передачу курьеру',
    'DELIVERY': 'Заказ передан курьеру для доставки',
    'READY': '',
    'REMOVED': '',
}
const ColumnResizer = ({
    id,
    initialWidth = 0,
    initialMinWidth = null,
    onChange,
    disabled
}: {
    id: number,
    initialWidth?: number,
    initialMinWidth?: number,
    onChange: (id: number, newWidth: number) => void,
    disabled?: boolean,
}) => {
    let dragging = false
    let cell: any = null
    let cells: any[] = []
    let minWidth = 0
    let x = 0
    let newWidth = initialWidth

    const mouseDownHandler = (e: any) => {
        if (disabled) return

        dragging = true

        cell = e.target.closest('[data-resizable="true"]') ?? null

        const table = cell.closest('.tdx-table-container')
        if (cell && table) {
            cells = [...table.querySelectorAll(`[data-cell="${cell.dataset.cell}"]`)]
            cell.classList.add('resizing')

            const styles = window.getComputedStyle(cell)
            const contentStyles = window.getComputedStyle(e.target.previousElementSibling)

            if (!initialWidth) {
                initialWidth = parseInt(styles.width, 10)
            }

            x = e.clientX
            if (initialMinWidth !== null)
            {
                minWidth = initialMinWidth;
            }
            else
            {
                minWidth =
                    parseInt(contentStyles.width, 10) +
                    parseInt(styles.paddingLeft, 10) +
                    parseInt(styles.paddingRight, 10) +
                    8 // + offset

                minWidth < 40 && (minWidth = 40)
            }

            document.addEventListener('mousemove', mouseMoveHandler)
            document.addEventListener('mouseup', mouseUpHandler)
        }
    }

    const mouseMoveHandler = (e: any) => {
        if (dragging) {
            const dx = e.clientX - x
            newWidth = initialWidth + dx < minWidth ? minWidth : initialWidth + dx

            cells.forEach(col => {
                col.style.width = `${newWidth}px`
                col.style.minWidth = `${newWidth}px`
                col.style.maxWidth = `${newWidth}px`
            })
        }
    }

    const mouseUpHandler = () => {
        cell.classList.remove('resizing')

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)

        onChange(id, newWidth)
        dragging = false
    }

    return <div className="resizer" onMouseDown={mouseDownHandler} />
}

export default ColumnResizer

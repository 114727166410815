import {useCallback} from 'react'

import {
    SmartFilter, FilterType,
} from '../../../store/slice/asideFiltersSlice/types'

import {
    TDXFilterDictionary,
    TDXFilterRangeSlider,
    TDXFilterRange,
    TDXFilterDateRange,
    TDXFilterText,
} from '../index'

const AsideFilterItem = ({
    filter = {},
    rememberKey = null,
    smartFilter,
} : {
    filter: any,
    rememberKey: string | null,
    smartFilter: any

}) => {
    const typeToJSX = useCallback((type : FilterType) => {
        switch (type) {
            case 'slider_range': {
                return (
                    <TDXFilterRangeSlider
                        id={filter.id}
                        data={filter.data}
                        //rememberKey={rememberKey}
                    />
                )
            }
            case 'dictionary': {
                return (
                    <TDXFilterDictionary
                        id={filter.id}
                        data={filter.data}
                        rememberKey={rememberKey}
                    />
                )
            }
            case 'number_range':
            case 'dictionary_range': {
                return (
                    <TDXFilterRange
                        id={filter.id}
                        type={type}
                        data={filter.data}
                        //rememberKey={rememberKey}
                    />
                )
            }
            case 'date_range': {
                return (
                    <TDXFilterDateRange
                        id={filter.id}
                        data={filter.data}
                        //rememberKey={rememberKey}
                    />
                )
            }
            case 'text': {
                return (
                    <TDXFilterText
                        id={filter.id}
                        data={filter.data}
                        rememberKey={rememberKey}
                    />
                )
            }
            case 'text_number': {
                return (
                    <TDXFilterText
                        id={filter.id}
                        data={filter.data}
                        rememberKey={rememberKey}
                        pattern={"^[0-9]+$"}
                    />
                )
            }
        }
    }, [filter, smartFilter])

    return (
        <div className="filter">
            <div className="body-1">{filter.name}</div>
            {typeToJSX(filter.type)}
        </div>
    )
}

export default AsideFilterItem

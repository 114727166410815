import {getCart} from "../../../store/slice/cartSlice/asyncActions";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";
import {useCallback, useEffect, useState} from "react";
import TDXBufferModal from "../../Buffer/TDXBufferModal";
import {separateThousands} from '../../../utils/separateThousands'

const TDXBufferListButton = () => {
    const dispatch = useAppDispatch()
    const cart = useAppSelector(state => state.Cart.data)
    const {isCartLoading} = useAppSelector(state => state.Cart)
    const {region, isSpecial} = useAppSelector(state => state.Profile.profile)
    const [open, setOpen] = useState<HTMLDivElement>(null)

    const toggleMenu = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            setOpen(null)
        } else {
            setOpen(e?.currentTarget)
        }
    }, [open])

    useEffect(() => {
        if (!isCartLoading) {
            dispatch<any>(getCart())
        }
    },[])

    return cart?.products?.length > 0 && (
        <div className="tdx-buffer-list-button">
            <div className="button-block">
                <div className="button body-2" onClick={toggleMenu}>
                    {cart?.products?.length} товар(а) в {region === 'ru' ? 'корзине': 'буфере'}. Сумма: {separateThousands(cart.total)} {!isSpecial ? cart.currency ?? '': ''}
                </div>
                <TDXBufferModal
                    open={open}
                    disablePortal={true}
                    placement={'top-end'}
                />
            </div>
        </div>
    )
}

export default TDXBufferListButton

export const createSearchParams = filters =>
    Object.keys(filters).reduce(
        (params, id) => ({
            ...params,
            ...Object.fromEntries(
                Object.keys(filters[id])
                    .filter(key => !!filters[id][key])
                    .map(key => [`${id}[${key}]`, filters[id][key]])
            ),
        }),
        {}
    )

export const parseSearchParams = (params, need_page=false) => {
    const paramsObject = Object.fromEntries(
        params instanceof URLSearchParams ? params.entries() : Object.entries(params)
    )

    return Object.keys(paramsObject).reduce((params, id) => {
        const newId = id.substring(0, id.indexOf('['))
        const key = id.substring(id.indexOf('['), id.length).replace(/\W/g, '')
        let value;

        if (!need_page && key === 'page') {
            return {...params}
        }

        try {
            value = JSON.parse(paramsObject[id])
        } catch (e) {
            value = paramsObject[id]
        }

        const result = {...params}

        if (key === 'page') {
            result['page'] = value
        } else {
            result[newId] = {
                ...params[newId],
                [key]: value,
            }
        }

        return result
    }, {})
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CatalogCategoriesSchema, Category} from './types'
import {getCategories} from './services/getCategories/getCategories'
import {resetCatalogCache} from './services/resetCatalogCache/resetCatalogCache'

const initialState: CatalogCategoriesSchema = {
    categories: [],
    lastFetch: null,
    activeCategory: null,
    activeSubCategory: null,
    open: false,
    loading: false,
    resetting: false,
    status: null,
}

const catalogCategoriesSlice = createSlice({
    name: 'catalogCategories',
    initialState,
    reducers: {
        setActiveCategory: (state, action: PayloadAction<Category>) => {
            state.activeCategory = action.payload
            state.activeSubCategory = action.payload.subcategories[0]
        },
        setActiveSubCategory: (state, action: PayloadAction<Category>) => {
            state.activeSubCategory = action.payload
        },
        setVisibility: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload
        },
        resetActiveCategories: state => {
            state.activeCategory = null
            state.activeSubCategory = null
        },
    },
    extraReducers: builder => {
        builder.addCase(getCategories.pending, state => {
            state.loading = true
        })
        builder.addCase(getCategories.rejected, state => {
            state.loading = false
        })
        builder.addCase(getCategories.fulfilled, (state, action: PayloadAction<Category[]>) => {
            state.categories = action.payload
            state.activeCategory = action.payload?.[0]
            state.activeSubCategory = action.payload?.[0]?.subcategories?.[0]
            state.lastFetch = (new Date()).valueOf();
            state.loading = false
        })

        builder.addCase(resetCatalogCache.pending, state => {
            state.loading = true
            state.resetting = true
        })
        builder.addCase(resetCatalogCache.rejected, state => {
            state.loading = false
            state.resetting = false
        })
        builder.addCase(resetCatalogCache.fulfilled, (state, action) => {
            state.resetting = false
            state.loading = false
            state.status = action?.payload?.meta
        })
    },
})

export const {actions: catalogCategoriesActions} = catalogCategoriesSlice
export const {reducer: catalogCategoriesReducer} = catalogCategoriesSlice

import {Link} from 'react-router-dom'
import {ArrowIcon} from '../../Icons'

export default function TDXArrowLink ({title = '', to = ''}: {
    title: string,
    to: string,
}) {
    return (
        <Link to={to} className="tdx-arrow-link">
            <h2>{title}</h2>
            <ArrowIcon />
        </Link>
    )
}

import {SvgIcon} from '@mui/material'

const XLSIcon = (props: any) => (
    <SvgIcon {...props} sx={{width: '35px', height: '40px', fill: 'none'}} viewBox="0 0 35 40">
        <path d="M0 19L3.18182 17H31.8182L35 19V21H0V19Z" fill="#33864A" />
        <path d="M3 40H32V11.8919L20 0H3V40Z" fill="#EEEEEE" />
        <path d="M20 12H32L20 0V12Z" fill="#E0E0E0" />
        <rect y="19" width="35" height="13" fill="#33864A" />
        <path
            d="M11.8535 24.7461L13.0605 22.6602H14.6426L12.7031 25.7832L14.707 29H13.1367L11.8711 26.832L10.6113 29H9.0293L11.0332 25.7832L9.09961 22.6602H10.6699L11.8535 24.7461ZM18.127 29H16.7031V20H18.127V29ZM24.2363 27.2773C24.2363 27.0234 24.1309 26.8301 23.9199 26.6973C23.7129 26.5645 23.3672 26.4473 22.8828 26.3457C22.3984 26.2441 21.9941 26.1152 21.6699 25.959C20.959 25.6152 20.6035 25.1172 20.6035 24.4648C20.6035 23.918 20.834 23.4609 21.2949 23.0938C21.7559 22.7266 22.3418 22.543 23.0527 22.543C23.8105 22.543 24.4219 22.7305 24.8867 23.1055C25.3555 23.4805 25.5898 23.9668 25.5898 24.5645H24.166C24.166 24.291 24.0645 24.0645 23.8613 23.8848C23.6582 23.7012 23.3887 23.6094 23.0527 23.6094C22.7402 23.6094 22.4844 23.6816 22.2852 23.8262C22.0898 23.9707 21.9922 24.1641 21.9922 24.4062C21.9922 24.625 22.084 24.7949 22.2676 24.916C22.4512 25.0371 22.8223 25.1602 23.3809 25.2852C23.9395 25.4062 24.377 25.5527 24.6934 25.7246C25.0137 25.8926 25.25 26.0957 25.4023 26.334C25.5586 26.5723 25.6367 26.8613 25.6367 27.2012C25.6367 27.7715 25.4004 28.2344 24.9277 28.5898C24.4551 28.9414 23.8359 29.1172 23.0703 29.1172C22.5508 29.1172 22.0879 29.0234 21.6816 28.8359C21.2754 28.6484 20.959 28.3906 20.7324 28.0625C20.5059 27.7344 20.3926 27.3809 20.3926 27.002H21.7754C21.7949 27.3379 21.9219 27.5977 22.1562 27.7812C22.3906 27.9609 22.7012 28.0508 23.0879 28.0508C23.4629 28.0508 23.748 27.9805 23.9434 27.8398C24.1387 27.6953 24.2363 27.5078 24.2363 27.2773Z"
            fill="white"
        />
    </SvgIcon>
)

export default XLSIcon

import {MenuItem} from '@mui/material'
import TDXOutlinedInput from './TDXOutlinedInput'
import {TDXCircularProgress} from '../index'

const SelectorItem = (props: Record<string, any>) => (
    <MenuItem {...props} className="tdx-select-item" value={props['data-value']}>
        {props.name}
    </MenuItem>
)

interface TDXSelector {
    options: Record<string, any>,
    placeholder?: string | number,
    value?: any,
    paperClassName?: string,
    error?: boolean,
    label?: string,
    preValueLabel?: string,
    className?: string,
    name?: string,
    multiple?: boolean,
    loading?: boolean,
    onChange?: (val: any) => void
}

export default function TDXSelector (props: TDXSelector) {
    const {options, placeholder, paperClassName, preValueLabel, multiple, loading, ...other} = props

    const handleChange = (e: any) => {
        const {
            target: {value},
        } = e

        props.onChange(value)
    }

    return (
        <TDXOutlinedInput
            {...other}
            className={`select ${props.className ? props.className : ''}`}
            value={props.value || ''}
            onChange={handleChange}
            select
            SelectProps={{
                multiple: !!multiple,
                displayEmpty: true,
                renderValue: (val: any) => {
                    const isValue = Array.isArray(val) ? !!val.length : !!val

                    if (isValue) {
                        if (Array.isArray(val)) {
                            return options
                                .filter((opt: Record<string, any>) => val.includes(opt.value))
                                .map((opt: Record<string, any>) => opt.name)
                                .join(', ')
                        } else {
                            const opt = options.find((opt: Record<string, any>) => opt.value === val)

                            return opt
                                ? preValueLabel
                                    ? `${preValueLabel} ${opt.name.toLowerCase()}`
                                    : opt.name
                                : null
                        }
                    }

                    return <div className="placeholder">{placeholder}</div>
                },
                MenuProps: {
                    PaperProps: {
                        className: `tdx-selector-menu tdx-scrollbar ${
                            paperClassName || ''
                        }`,
                        elevation: 3,
                    },
                },
            }}>
            {loading ? (
                <TDXCircularProgress size={20} />
            ) : (
                options.map((opt: Record<string, any>) => (
                    <SelectorItem
                        key={opt.value}
                        value={opt.value}
                        name={opt.name}
                        disabled={opt.disabled}
                    />
                ))
            )}
        </TDXOutlinedInput>
    )
}

export const REQUEST_STARTED = 'REQUEST_STARTED'
export const REQUEST_FINISHED = 'REQUEST_FINISHED'
export const REQUEST_FAILED = 'REQUEST_FAILED'

export const requestStarted = (requestId: string, requestName: any) => ({
    type: REQUEST_STARTED,
    request: {
        requestId,
        requestName,
        inProgress: true,
    },
})

export const requestFinished = (requestId: string, requestName: any) => ({
    type: REQUEST_FINISHED,
    request: {
        requestId,
        requestName,
        inProgress: false,
    },
})

export const requestFailed = ({requestId, requestName, error}: {requestId: string, requestName: any, error: any}) => ({
    type: REQUEST_FAILED,
    request: {
        requestId,
        requestName,
        inProgress: false,
        error,
    },
})

import {TDXButton, TDXCircularProgress, TDXIntegerInput} from '../UI'
import NotificationsIcon from '@mui/icons-material/Notifications'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {changeWaitingList} from '../../store/actions/ProfileActions'
import {useCallback, useState, useEffect} from 'react'
import {IconButton, Tooltip,ClickAwayListener} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import {enqueueSnackbar} from 'notistack'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {addToCart, removeFromCart} from '../../store/slice/cartSlice/asyncActions'
import {splitProductId} from "../../utils/prepareProduct";
import {ProductOfferSerializer, ProductSerializer} from "../../store/slice/catalogSlice/types";
import {SearchSerializer} from "../../store/actions/GeneralActions";

const createLabelForOfferItem = ({stock = '', warranty: months = 0} : {
    stock: ProductOfferSerializer['stock'],
    warranty: ProductOfferSerializer['warranty']
}) =>
    `${stock} | ${Number(months) >= 0 ? months+'мес.' : ''}`

const CatalogItemOffer = ({offer, itemId, filter={} }:{
    offer?: ProductOfferSerializer | null,
    itemId: number,
    filter?: any
}) => {
    const dispatch = useAppDispatch()

    const {isSpecial, region} = useAppSelector(state => state.Profile.profile)
    const [loading, setLoading] = useState(false)
    const {settings} = useAppSelector(state => state.General.common)
    const [isCounterOpen, setIsCounterOpen] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [quantityCart, setQuantityCart] = useState(0)
    const [cartId, setCartId] = useState<string | null>(null)
    const [isActive, setActive] = useState(false)
    const {products} = useAppSelector(state => state.Cart.data)
    const [isExistsCart, setExistsCart] = useState(false)

    const underOrderChoices = ['transit'] // Варианты статусов если позиция заказная

    useEffect(() => {
        if (products.length > 0) {
            const product = products.filter(p =>
                splitProductId(p._id).contract_id === offer?.contract &&
                splitProductId(p._id).catalogapp_id === offer?.ext_id &&
                splitProductId(p._id).supplier_id === offer?.supplier_id
            )
            if (product.length > 0) {
                setExistsCart(true)
                if (product?.[0]?.quantity) {
                    setQuantity(product[0].quantity)
                    setQuantityCart(product[0].quantity)
                    setCartId(product[0]._id)
                }
            } else {
                setExistsCart(true)
                setQuantityCart(0)
                setCartId(null)
                setQuantity(1)
            }
            setIsCounterOpen(false)
        }
    }, [products])

    const toggleCounter = useCallback(() => {
        if (isCounterOpen && quantityCart !== quantity) {
            setQuantity(quantityCart > 0 ? quantityCart: 1)
        }
        setIsCounterOpen(prev => !prev)
    }, [quantityCart, quantity])

    const onChangeQuantity = useCallback((value: number) => {
        setQuantity(value)
    }, [])

    const addProductToCart = useCallback(async () => {
        if (loading) return

        if (quantityCart == 0 && !quantity) {
            enqueueSnackbar('Укажите количество', {variant: 'warning'})
            return
        }

        setLoading(true)

        if (quantity > 0) {
            let offer_id = null;
            let supplier_id = null;

            if (offer?.status === 'transit')
            {
                //offer_id = sku
                const o = offer?.sku.split(':')
                offer_id = parseInt(o[1], 10);
                supplier_id = parseInt(o[0], 10);
            }
            else
            {
                offer_id = offer?.ext_id
                supplier_id = offer?.supplier_id
            }

            await dispatch<any>(addToCart({
                body: [
                    {
                        contract: offer?.contract,
                        //id: offer.ext_id,
                        id: offer_id,
                        status: offer?.status,
                        supplier_id,
                        quantity,
                    }
                ],
                region: region
            }))
        } else {
            await dispatch<any>(removeFromCart({ids: [cartId], region}))
        }

        setLoading(false)
        //toggleCounter()
    }, [loading, quantity])

    const addToWaitList = useCallback(async () => {
        if (loading) return

        setLoading(true)

        await dispatch<any>(changeWaitingList('add', [itemId]))

        setLoading(false)
    }, [loading])

    if (isCounterOpen) {
        return (
            <div className="tdx-button large bg-gray counter">
                <Tooltip title="Закрыть">
                    <IconButton className="icon-button clear-icon" onClick={toggleCounter}>
                        <ClearIcon className="icon clear-icon" />
                        <span className={'label'}>Закрыть</span>
                    </IconButton>
                </Tooltip>
                <TDXIntegerInput
                    counter
                    max={offer?.quantity ?? 0}
                    maxStr={offer?.max_quantity_template ?? 0}
                    value={quantity}
                    onChange={onChangeQuantity}
                />
                <Tooltip title="Подтвердить количество">
                    <IconButton className="icon-button description-icon" onClick={addProductToCart}>
                        {loading ? (
                            <TDXCircularProgress size={20} />
                        ) : (
                            <>
                                <DoneIcon className="icon description-icon" />
                                <span className={'label'}>{region === 'ru' ? 'В корзину': 'В буфер'}</span>
                            </>
                        )}
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const bgColor = (offer: ProductOfferSerializer) => {
        let color = 'green'
        if (offer?.is_reserve || offer?.quantity === 0) {
            color = 'gray'
        } else if (underOrderChoices.includes(offer?.status)) {
            color = 'yellow-light'
        } else if (offer?.order) {
            color = 'blue'
        }
        return color
    }

    const isHover = (offer: ProductOfferSerializer) => {
        return offer?.is_reserve || offer?.quantity === 0
    }

    return (
        filter?.length === 0 ||
        (underOrderChoices.includes(offer?.status) && filter.inOrder) ||
        (offer?.quantity>0 && !offer?.is_reserve && !underOrderChoices.includes(offer?.status) && filter.inStock) ||
        (offer?.is_reserve && filter.inReserve) ||
        (offer?.quantity === 0 && !offer?.is_reserve && filter.outOfStock)
    ) ? (
        <ClickAwayListener onClickAway={() => setActive(false)}>
            <div className={`buttons-wrapper ${isHover(offer) ? 'hover' : ''} ${isActive ? 'is-active': ''}`}>
                <TDXButton
                    size="large"
                    bg={bgColor(offer)}
                    label={createLabelForOfferItem(offer)}
                    company={
                        [offer['company'] ?? '', offer['warehouse'] ?? ''].filter(item => item.length > 0).join(' | ')
                    }
                    availability={underOrderChoices.includes(offer.status) ? offer['available_date'] ?? '': ''}
                    count={isExistsCart ? quantityCart: false}
                    onClick={offer?.is_reserve || offer?.quantity === 0 ? () => { setActive(true) }: toggleCounter}
                    loading={loading}>
                    {(
                      offer?.price_old > 0 &&
                      settings?.ui_settings?.need_old_price?.value === true
                    )? (
                        <div className="prices">
                            <span className="old_price">{offer?.price_old.toFixed(2)} {!isSpecial ? offer?.currency ?? '': ''}</span>
                            <span className="new_price">{offer?.price.toFixed(2)} {!isSpecial ? offer?.currency ?? '': ''}</span>
                            <span className="icon-promo"></span>
                        </div>
                    ) : (
                        <>
                            {`${offer?.price.toFixed(2) ?? 0} ${!isSpecial ? offer?.currency ?? '': ''}`}
                        </>
                    )}
                </TDXButton>
                {isHover(offer) ? (
                    <TDXButton
                        size="large"
                        className="hover wait-list-button"
                        bg="gray"
                        startIcon={<NotificationsIcon />}
                        loading={loading}
                        onClick={addToWaitList}>
                        Добавить в лист ожидания
                    </TDXButton>
                ) : ''}
            </div>
        </ClickAwayListener>
    ) : null
}

const CatalogItemOffers = ({item, filter={}, max_offers = 3 }:{
    item: ProductSerializer | SearchSerializer,
    filter: any,
    max_offers?: number,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [offers] = useState(item['offers'] ?? [])
    const [loading, setLoading] = useState(false)

    const addToWaitList = useCallback(async () => {
        if (loading) return

        setLoading(true)

        await dispatch<any>(changeWaitingList('add', [item.ext_id]))

        setLoading(false)
    }, [loading])

    if (!offers.length) {
        return (
            <TDXButton
                size="large"
                bg="gray"
                startIcon={<NotificationsIcon />}
                loading={loading}
                onClick={addToWaitList}>
                Добавить в лист ожидания
            </TDXButton>
        )
    }

    return offers.length > max_offers ? (
        <>
            {offers.slice(0, max_offers-1).map((o, id) => (
                <CatalogItemOffer key={id} itemId={item?.ext_id} offer={o} filter={filter} />
            ))}
            <div className={`buttons-wrapper`}>
                <TDXButton size="large" bg="gray" onClick={() => navigate(`/product/${item?.id}`)}>
                    Больше вариантов
                </TDXButton>
            </div>
        </>
    ) : (
        offers.map((o, id) => <CatalogItemOffer key={id} itemId={item?.ext_id} offer={o} filter={filter} />)
    )
}

export default CatalogItemOffers

import {SvgIcon} from '@mui/material'

const ArrowIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '14px', height: '14px', color: 'rgba(0, 0, 0, 0.87)'}}
        viewBox="0 0 14 14">
        <path d="M7 0L5.76625 1.23375L10.6488 6.125L0 6.125L0 7.875L10.6488 7.875L5.7575 12.7575L7 14L14 7L7 0Z" />
    </SvgIcon>
)

export default ArrowIcon

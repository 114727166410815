import {SvgIcon} from '@mui/material'

const FacebookIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '10px', height: '18px', color: 'rgba(0, 0, 0, 0.8)'}}
        viewBox="0 0 10 18">
        <path d="M6.32522 17.1849V9.34456H8.95702L9.35111 6.28903H6.32522V4.33932C6.32522 3.45455 6.57115 2.85186 7.83935 2.85186H9.45719V0.118716C8.67412 0.0364372 7.88715 -0.00312895 7.09977 0.000192988C4.76723 0.000192988 3.17013 1.42247 3.17013 4.03887V6.29081H0.53418V9.34456H3.1725V17.1855H6.32522V17.1849Z" />
    </SvgIcon>
)

export default FacebookIcon

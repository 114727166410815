import {NavLink} from 'react-router-dom'

export default function TDXNavLink ({
    title = '', to = '',
    onActive = undefined,
    onClick = undefined,
    className = undefined,
    end = false,
}:{
    title: string,
    to: string,
    onActive?: (val: boolean) => void,
    onClick?: () => void,
    className?: string,
    end?: boolean,
}) {
    return (
        <NavLink
            className={({isActive}) => {
                if (onActive !== undefined)
                {
                    onActive(isActive)
                }

                let res = `tdx-nav-link link ${isActive ? 'active' : ''}`
                if (className !== undefined)
                {
                    res += ' ' + className;
                }
                return res
            }}
            end={end}
            to={to} onClick={onClick}>
            <h5>{title}</h5>
        </NavLink>
    )
}

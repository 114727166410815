import {requestHelper} from '../../helpers/requestHelper'
import GeneralService, {GeneralRequestsEnum} from '../../api/general.service'
import {
    ProductOfferSerializer, ProductPropertyGroupSerializer,
    ProductPropertySerializer,
    ProductSerializer
} from "../slice/catalogSlice/types";

export const SET_FAQ_QUESTIONS = 'SET_FAQ_QUESTIONS'
export const SET_DISCOUNTS = 'SET_DISCOUNTS'
export const SET_NEWS = 'SET_NEWS'
export const SET_COMMON_DATA = 'SET_COMMON_DATA'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_SEARCH_PRICES = 'SET_SEARCH_PRICES'
export const TOGGLE_SEARCH_CATEGORY = 'TOGGLE_SEARCH_CATEGORY'
export const MOVE_SEARCH_CHECKED_CATEGORIES = 'MOVE_SEARCH_CATEGORIES'
export const RESET_SEARCH = 'RESET_SEARCH'
export const POLL_ADD_USER_ANSWER = 'POLL_ADD_USER_ANSWER'

export interface SearchSerializer {
    id: number
    _id?: number
    offers?: ProductOfferSerializer[] | null
    ext_id: number
    general_properties?: ProductPropertySerializer[]
    properties?: ProductPropertyGroupSerializer[] | ProductPropertySerializer[]
    category: number
    photos?: string[]
    name: string
    sku: string
    sku2?: string
    ean: string
    vendor: {
        id: number
        name: string
    }
    image?: string
    product_id?: number
    date?: string
    description?: string
    auctions?: number[]
}

export type CommonSettings = Record<string, Record<
    string, {
        title: string
        alias: string
        type: 'boolean' | 'text' | 'json' | 'image'
        value: boolean | string | any[] | Record<string, any>
    }
>>

export type Question = {
    id: number,
    question: string,
    date: string,
}

export interface NewsSerializer {
    id: number,
    title: string,
    short: string,
    text: string,
    image: string,
    date: string,
    products?: ProductSerializer[],
    prev?: string,
    next?: string,
}

export interface Popup {
    id: number,
    title: string,
    image: string,
    text: string,
    url: string,
    is_target_blank: boolean,
    is_show_more: boolean,
}

export interface GeneralState {
    questions: Question[],
    discounts: any[],
    news: NewsSerializer[],
    quickSearch: {
        keyword: string,
        results: {
            categories: {
                id: number,
                name: string
            }[],
            products: SearchSerializer[],
            count: number,
        },
        selectedCategories: number[],
    },
    search: {
        keyword: string,
        results: {
            categories: {
                id: number,
                name: string
            }[],
            products: SearchSerializer[],
            count: number,
        },
        prices: any[],
        selectedCategories: number[],
    },
    common: {
        cities: any[],
        cities_last_fetched: number | null,
        currencies: any[],
        partnersTypes: any[],
        organisations: any[],
        polls: any[],
        banners: any[],
        popups: Popup[],
        settings: CommonSettings,
    }
}

export function settings_as_str (
    settings : CommonSettings,
    setting_name : string,
    key : string
)
{
    const t = settings?.[setting_name]?.[key];
    if (t && t.type == 'text')
    {
        return settings?.[setting_name]?.[key]?.value as string;
    }
    else
    {
        return '';
    }
}

export const getFAQ = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getFAQ(), async () => {
        const questions = await GeneralService.getFAQ()

        dispatch({
            type: SET_FAQ_QUESTIONS,
            data: {questions},
        })
    })
}

export const getDiscounts = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getDiscounts, async () => {
        const discounts = await GeneralService.getDiscounts()

        dispatch({
            type: SET_DISCOUNTS,
            data: {discounts},
        })
    })
}

export const getNews = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getNews(), async () => {
        const news = await GeneralService.getNews()

        dispatch({
            type: SET_NEWS,
            data: {news},
        })
    })
}

export const getCities = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getCities, async () => {
        const cities = await GeneralService.getCities()

        dispatch({
            type: SET_COMMON_DATA,
            data: {
                common: {
                    cities,
                    cities_last_fetched: (new Date()).valueOf()
                }
            },
        })
    })
}

export const getOrganisations = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getOrganisations, async () => {
        const organisations = await GeneralService.getOrganisations()

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {organisations}},
        })
    })
}

export const getCurrencies = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getCurrencies, async () => {
        const currencies = await GeneralService.getCurrencies()

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {currencies}},
        })
    })
}

export const searchFor =
    (
        keyword : string,
        signal : any,
        id : string,
        quick = false
    ) => (dispatch : any) => {
        requestHelper(
            dispatch,
            GeneralRequestsEnum.searchFor(id),
            async () =>
        {
            const results = await GeneralService.searchFor(
                keyword, signal, quick
            )

            dispatch({
                type: SET_SEARCH,
                data: {
                    quick,
                    keyword,
                    results,
                },
            })
        })
    }

/*
export const getSearchPrices = (keyword : string, signal : any) => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getSearchPrices, async () => {
        const prices = await GeneralService.getSearchPrices(keyword, signal)

        dispatch({
            type: SET_SEARCH_PRICES,
            data: {prices},
        })
    })
}
*/

export const toggleSearchCategory = (
    id : number,
    checked : boolean,
    quick = false
) => ({
    type: TOGGLE_SEARCH_CATEGORY,
    data: {id, checked, quick},
})

export const moveSearchCheckedCategories = () => ({
    type: MOVE_SEARCH_CHECKED_CATEGORIES,
})

export const resetSearch = (quick = false) => ({
    type: RESET_SEARCH,
    data: {quick},
})

export const getPolls = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getPolls, async () => {
        const polls = await GeneralService.getPolls()

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {polls}},
        })
    })
}

export const getBanners = (params = {}) => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getBanners, async () => {
        const banners = await GeneralService.getBanners(params)

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {banners}},
        })
    })
}

export const getPopups = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getPopups, async () => {
        const popups = await GeneralService.getPopups()

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {popups}},
        })
    })
}

export const addUserAnswer = (poll : Record<string, any>) => ({
    type: POLL_ADD_USER_ANSWER,
    data: {poll},
})

export const getSettings = () => (dispatch : any) => {
    requestHelper(dispatch, GeneralRequestsEnum.getSettings, async () => {
        const settings = await GeneralService.getSettings()

        dispatch({
            type: SET_COMMON_DATA,
            data: {common: {settings}},
        })
    })
}

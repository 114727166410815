declare global {
    interface Window {
        __RUNTIME_CONFIG__: any;
    }
}

const API_RESIZER: string = (
    window?.__RUNTIME_CONFIG__?.API_RESIZER !== undefined
    ? window?.__RUNTIME_CONFIG__?.['API_RESIZER']
    : '//resize.unknown.domain/'
)

const HOST = API_RESIZER

function encodeQueryData (data: any) {
    const ret = []
    for (const d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
}

export const resizer = (url: string, w: number, h: number) => {
    url = HOST + url

    const data: {
        w?: number,
        h?: number
    } = {}
    w && (data.w = w)
    h && (data.h = h)

    const query = encodeQueryData(data)
    return query ? url + `?${query}` : url
}

import {useEffect, useRef, useState} from 'react'
import {valuesToFalse} from '../utils/valuesToFalse'
import dotToValue from '../utils/dotToValue'
import {isObject} from '../utils/isObject'
import {findKeyInDeepObject} from '../utils/findKeyInDeepObject'

export const useRenderCounter = () => {
    const counter = useRef(1)

    useEffect(() => {
        counter.current += 1;
    })

    return counter.current
}

export const useIsFirstRender = () => {
    const isFirst = useRef(true)

    if (isFirst.current) {
        isFirst.current = false

        return true
    }

    return isFirst.current
}

export const useHasChanged = val => {
    const prev = usePrevious(val)
    return prev ? prev !== val : false
}

export const usePrevious = val => {
    const ref = useRef()

    useEffect(() => {
        ref.current = val;
    })

    return ref.current
}

const resetPhoneMask = obj => {
    if (!isObject(obj)) return

    if (obj['phone']) {
        obj['phone'] = obj['phone'].replace(/[\s+()-]/g, '')
    } else {
        Object.keys(obj).forEach(key => resetPhoneMask(obj[key]))
    }
}

export const useForm = (initial = {}, requiredValues = {}) => {
    const [values, setValues] = useState(JSON.parse(JSON.stringify(initial)))
    const [required, setRequired] = useState(JSON.parse(JSON.stringify(requiredValues)))
    const [errors, setErrors] = useState(valuesToFalse(initial))
    const [checkForm, setCheckForm] = useState(false)

    const onFormInputChange = e => {
        const {
            target: {name, type, value, checked},
        } = e
        const isCheckbox = type === 'checkbox'

        dotToValue.set(values, name, isCheckbox ? checked : value)
        setValues({...values})
        setRequired({...required})
        setCheckForm(true)
        validate({name, value})
    }

    const validate = ({name, value}) => {
        const keys = name.split('.')
        const type = keys.slice(-1)[0]

        switch (type) {
            case 'email': {
                value = !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value)
                break
            }
            case 'phone': {
                value = value.length < 18
                break
            }
            case 'username': {
                value = value.length < 2
                break
            }
            case 'volume':
            case 'total': {
                value = !/^\d+$/.test(value)
                break
            }
            case 'oldPassword': {
                value = value.length < 7
                break
            }
            case 'password': {
                const {secondPassword = ''} =
                    [...keys].slice(0, -1).reduce((o, i) => o[i], values) || {}

                if (secondPassword) {
                    dotToValue.set(
                        errors,
                        [...keys.slice(0, -1), 'secondPassword'],
                        !(value === secondPassword)
                    )
                }

                value = value.length < 7
                break
            }
            case 'secondPassword': {
                const {password = ''} = [...keys].slice(0, -1).reduce((o, i) => o[i], values) || {}

                value = !(value === password)
                break
            }
            case 'city':
            case 'name': {
                value = !value
                break
            }
            default: {
                value = false
                break
            }
        }

        dotToValue.set(errors, keys, value)
        setErrors({...errors})
    }

    const isFormValid = () => {
        if (checkForm) {
            const isErrors = obj =>
                Object.values(obj).some(val => (typeof val === 'object' ? isErrors(val) : !!val))

            Object.values(required).forEach(val => {
                if (!values[val]) {
                    dotToValue.set(errors, val, true)
                }
            })

            const isChanges = () => {
                const initialClone = JSON.parse(JSON.stringify(initial))
                const valuesClone = JSON.parse(JSON.stringify(values))

                if (findKeyInDeepObject(values, 'phone')) {
                    resetPhoneMask(valuesClone)
                    resetPhoneMask(initialClone)
                }

                return !(JSON.stringify(initialClone) === JSON.stringify(valuesClone))
            }

            return !isErrors(errors) && isChanges()
        }
        return false
    }

    return {
        values,
        errors,
        required,
        onFormInputChange,
        isFormValid,
    }
}

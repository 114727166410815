import {createTheme} from '@mui/material/styles'

export const general = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '28px',
            fontWeight: 700,
            lineHeight: '34px',
        },
        h2: {
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '30px',
        },
        h3: {
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '26px',
        },
        h4: {
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '24px',
        },
        h5: {
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '22px',
        },
        h6: {
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20px',
        },
        body1: {
            fontSize: '14px',
            lineHeight: '17px',
        },
        body2: {
            fontSize: '12px',
            lineHeight: '17px',
        },
        subtitle1: {
            fontSize: '11px',
            fontWeight: 700,
            lineHeight: '15px',
        },
        subtitle2: {
            fontSize: '10px',
            fontWeight: 700,
            lineHeight: '14px',
        },
        button: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
            textTransform: 'none',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    body1: 'p',
                    body2: 'p',
                    subtitle1: 'h4',
                    subtitle2: 'h6',
                    button: 'span',
                    buttonSmall: 'span',
                },
            },
        },
    },
})

import {enqueueSnackbar} from 'notistack'
import {requestHelper} from '../../helpers/requestHelper'
import ProfileService, {NotificationSerializer, ProfileRequestsEnum} from '../../api/profile.service'
//import {Link} from 'react-router-dom'
import {parseSearchParams} from '../../components/UI/AsideFilter/utils'
import {asideFiltersActions} from '../slice/asideFiltersSlice/asideFiltersSlice'
import {authActions} from '../slice/authSlice/authSlice'
import {OrderSerializer} from '../slice/cartSlice/types'
import {ProductOfferSerializer} from "../slice/catalogSlice/types";

//export {OrderSerializer}

export const SET_PROFILE = 'SET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const SET_PROFILE_PAYMENTS = 'SET_PROFILE_PAYMENTS'
export const SET_PROFILE_CONTRACTS = 'SET_PROFILE_CONTRACTS'
export const SET_PROFILE_ORDERS = 'SET_PROFILE_ORDERS'
export const SET_PROFILE_RESERVE_ORDERS = 'SET_PROFILE_RESERVE_ORDERS'
export const UPDATE_PROFILE_ORDER = 'UPDATE_PROFILE_ORDER'
export const SET_PROFILE_SUPPLIES = 'SET_PROFILE_SUPPLIES'
export const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const SET_NEW_SUPPLY = 'SET_NEW_SUPPLY'
export const UPDATE_PROFILE_SUPPLY = 'UPDATE_PROFILE_SUPPLY'
export const SET_PROFILE_WAITING_LIST = 'SET_PROFILE_WAITING_LIST'
export const ADD_PRODUCT_WAITING_LIST = 'ADD_PRODUCT_WAITING_LIST'
export const REMOVE_PRODUCT_WAITING_LIST = 'REMOVE_PRODUCT_WAITING_LIST'
export const SET_PROFILE_REFUSALS = 'SET_PROFILE_REFUSALS'
export const SET_PROFILE_MATCHING = 'SET_PROFILE_MATCHING'
export const SET_WAITING_LIST = 'SET_WAITING_LIST'
export const SET_API_DATA = 'SET_API_DATA'
export type ProfileRegion = 'by' | 'ru'
export interface ProfileState {
    id: any,
    name: any,
    isManager: boolean,
    isSpecial: boolean,
    isCustomPrice: boolean,
    isMegaSklad: boolean,
    isElektronmir: boolean,
    region: ProfileRegion,
    money: any,
    currency: any,
    rates: any,
    formValues: {
        general: any,
        delivery: any,
        billing: any,
        legalAddress: any,
        dischargeAddress: any,
        partners: any,
        newsletter: any,
    },
    notify: NotificationSerializer[],
    api: {
        client_id: string,
        client_secret: string,
    }
}

export interface WaitListMiniSerializer {
    id: number,
    product_id: number
    stock_id: number
}

export interface WaitListSerializer {
    id: number
    available: boolean
    date: string
    ext_id: number
    image: string
    name: string
    offers: ProductOfferSerializer[]
    product_id: number
    sku: string
    stock: string
    stock_id: number
    auctions?: number[]
}

export interface UserState {
    profile: ProfileState,
    payments: any[],
    orders: OrderSerializer[],
    reserveOrders: any[],
    waitList: WaitListSerializer[],
    waitList_mini: WaitListMiniSerializer[],
    supplies: any[],
    suppliers: any[],
    refusals: any[],
    contracts: ContractSerializer[],
    matching: any[],
}

export interface ContractSerializer {
    id: string,
    balance: number,
    name: string,
    number: string,
    organisation: string,
    organisation_code: number,
    date: string,
    grace_period: number,
    sum_credit: number,
    debt: number,
    arreas: number,
    currency: string
}

export const getProfile = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getProfile, async () => {
        const profile = await ProfileService.getProfile()

        dispatch({
            type: SET_PROFILE,
            data: {
                profile: {
                    id: profile.id,
                    name: profile.name,
                    isManager: profile.is_manager,
                    isSpecial: profile.is_special,
                    isCustomPrice: profile.is_custom_price,
                    isMegaSklad: profile.is_megasklad,
                    isElektronmir: profile.is_elektronmir,
                    region: profile.region,
                    money: profile.money,
                    currency: profile.currency,
                    rates: profile.rates,
                    formValues: {
                        general: profile.general,
                        delivery: profile.delivery,
                        billing: profile.billing,
                        legalAddress: profile.legalAddress,
                        dischargeAddress: profile.dischargeAddress,
                        partners: profile.partners,
                        newsletter: profile.newsletter,
                    },
                    notify: profile.notify,
                    api: profile.api
                } as ProfileState,
            },
        })

        dispatch(authActions.setPartners(profile.partners))
    })
}

export const updateProfile = (values : any) => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.updateProfile, async () => {
        const profile = await ProfileService.updateProfile(values)

        dispatch({
            type: UPDATE_PROFILE,
            data: {
                profile: {
                    formValues: {
                        general: profile.general,
                        delivery: profile.delivery,
                        billing: profile.billing,
                        legalAddress: profile.legalAddress,
                        dischargeAddress: profile.dischargeAddress,
                        partners: profile.partners,
                        newsletter: profile.newsletter,
                    },
                },
            },
        })

        enqueueSnackbar('Профиль успешно изменён', {variant: 'success'})
    })
}

export const getProfilePayments = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getProfilePayments, async () => {
        const payments = await ProfileService.getProfilePayments()

        dispatch({
            type: SET_PROFILE_PAYMENTS,
            data: {payments},
        })
    })
}

export const getProfileContracts = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getProfileContracts, async () => {
        const contracts = await ProfileService.getProfileContracts()

        dispatch({
            type: SET_PROFILE_CONTRACTS,
            data: {
                contracts
            },
        })
    })
}

export const getProfileOrders = (signal : any, params : any) => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getOrders, async () => {
        const orders = await ProfileService.getOrders(signal, params)

        dispatch({
            type: SET_PROFILE_ORDERS,
            data: {orders},
        })
    })
}

export const updateProfileOrder = (order : OrderSerializer) => ({
    type: UPDATE_PROFILE_ORDER,
    data: {order},
})

export const getProfileOrdersFilters = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getOrdersFilters, async () => {
        const filters = await ProfileService.getOrdersFilters()
        const savedValues = parseSearchParams(
            JSON.parse(localStorage.getItem('orderFilters')) ?? {}
        )

        dispatch(asideFiltersActions.setFiltersValues({filters: filters ?? [], savedValues}))
    })
}

export const getProfileSupplies = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getSupplies, async () => {
        const supplies = await ProfileService.getSupplies()
        dispatch({
            type: SET_PROFILE_SUPPLIES,
            data: {supplies},
        })
    })
}

export const updateProfileSupply = (supply : any) => ({
    type: UPDATE_PROFILE_SUPPLY,
    data: {supply},
})

export const getSuppliers = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getSuppliersList, async () => {
        const suppliers = await ProfileService.getSuppliersList()
        dispatch({
            type: SET_SUPPLIERS,
            data: {suppliers},
        })
    })
}

export const createNewSupply = (supply : any) => ({
    type: SET_NEW_SUPPLY,
    data: {supply},
})

export const getProfileWaitingList = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getWaitingList, async () => {
        const waitList = await ProfileService.getWaitingList()

        dispatch({
            type: SET_PROFILE_WAITING_LIST,
            data: {waitList},
        })
    })
}

export const changeWaitingList = (type : any, skuArray : string[] | number[]) => (dispatch : any) => {
    requestHelper(
        dispatch,
        ProfileRequestsEnum.updateWaitingList(skuArray[0]),
        async () => {
            const waitListAction = await ProfileService.updateWaitingList({type, body: skuArray})
            const waitList = await ProfileService.getWaitingList()

            dispatch({
                type: SET_PROFILE_WAITING_LIST,
                data: {waitList},
            })

            enqueueSnackbar(
                <>
                    {waitListAction['msg']}
                </>,
                {variant: waitListAction['type']}
            )
        }
    )
}

export const getRefusals = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getRefusals, async () => {
        const refusals = await ProfileService.getRefusals()

        dispatch({
            type: SET_PROFILE_REFUSALS,
            data: {refusals},
        })
    })
}

export const getMatching = (values : any) => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getMatching, async () => {
        const matching = await ProfileService.getMatching(values)

        dispatch({
            type: SET_PROFILE_MATCHING,
            data: {matching},
        })
    })
}

export const getWaitingListMini = () => (dispatch : any) => {
    requestHelper(dispatch, ProfileRequestsEnum.getWaitingListMini, async () => {
        const waitList_mini = await ProfileService.getWaitingListMini()

        dispatch({
            type: SET_WAITING_LIST,
            data: {
                waitList_mini
            },
        })
    })
}

export const setApiData = (api : any) => ({
    type: SET_API_DATA,
    data: {
        profile: {api}
    },
})
import {Box, Fade} from '@mui/material'

interface TabPanelInterface {
    value: number,
    index: number,
    children: React.ReactNode
}

const TabPanel = (props: TabPanelInterface) => {
    const {value, index, children} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tdx-tabpanel-${index}`}
            aria-labelledby={`tdx-tab-${index}`}>
            {value === index && (
                <Fade in={true} timeout={500}>
                    <Box>{children}</Box>
                </Fade>
            )}
        </div>
    )
}

export default TabPanel

import Pagination from '@mui/material/Pagination'
//import PropTypes from 'prop-types'
import {SyntheticEvent, useEffect, useState} from 'react'
import {parseSearchParams, createSearchParams} from '../AsideFilter/utils'
import {useSearchParams} from "react-router-dom";
export default function TDXPagination ({
    page = 0,
    count = 0,
    onChange = null,
    disabled = false,
    scrollBlock = undefined,
} : {
    page: number,
    count: number,
    onChange: null | ((e : any, page : number) => void),
    disabled?: boolean,
    scrollBlock?: {
        current: HTMLDivElement
    },
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const currentPage = () => {
        let params = parseSearchParams(searchParams, true) as Record<string, string>
        return params?.page ? Number(params.page): 1
    }
    const scrollUp = () => {
        scrollBlock?.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        })

        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth',
        // })
    }

    const onChangePage = (e : SyntheticEvent<HTMLDivElement>, page : number) => {
        const params = parseSearchParams(searchParams)
        const newParams = {...createSearchParams(params)} as Record<string, string>
        if (page > 1) {
            newParams['page'] = String(page)
        }
        setSearchParams(newParams)

        onChange(e, page)
        scrollUp()
    }

    useEffect(() => onChangePage(undefined, currentPage()), [count])

    return (
        <Pagination
            className="tdx-pagination"
            count={count}
            page={page}
            onChange={onChangePage}
            disabled={disabled}
        />
    )
}

/*
TDXPagination.propTypes = {
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}
*/

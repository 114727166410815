import React from 'react'
import {createRoot} from 'react-dom/client'
//import {render} from 'react-dom'
import {Provider as StoreProvider} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'
import {store} from './store/store'
import theme from './styles/theme'
import {AxiosInterceptorsSetup} from './api/setupInterceptors'
import {RouterProvider} from './app/providers/RouterProvider'
import './styles/index.scss'

AxiosInterceptorsSetup(store.dispatch)

// eslint-disable-next-line react/no-deprecated
createRoot(document.getElementById('root')).render(
    <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
            <RouterProvider />
        </ThemeProvider>
    </StoreProvider>
);

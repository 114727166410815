import {useState} from 'react'
import {IconButton, InputAdornment} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'

import TDXOutlinedInput from './TDXOutlinedInput'

export default function TDXPasswordInput (props: Record<string, any>) {
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => setShowPassword(!showPassword)

    return (
        <TDXOutlinedInput
            {...props}
            type={showPassword ? 'text' : 'password'}
            value={props.value || ''}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={toggleShowPassword} disabled={props.disabled}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

import {useMemo, useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {TDXCheckbox, TDXIntegerInput, TDXSelector} from '../index'
import {useHasChanged} from '../../../customHooks'
import {useSearchParams} from 'react-router-dom'
import {parseSearchParams} from '../../AsideFilter/utils'
import {useAppDispatch, useAppSelector} from '../../../../shared/hooks/hooks'
import {asideFiltersActions} from '../../../../store/slice/asideFiltersSlice/asideFiltersSlice'
import {FilterRangeData, FilterRangePredefined, RangeValue} from "../../../../store/slice/asideFiltersSlice/types";

const TDXFilterRange = ({id, type, data} : FilterRangeData) => {
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams()
    const {isActive, values, allowFiltered, smartFilters} = useAppSelector(state => state.AsideFilters)
    const params = useMemo<Record<string, RangeValue>>(() => parseSearchParams(searchParams), [searchParams])
    const [isChanged, setChanged] = useState(false)
    const throttle = 1500

    const getState = () => {
        if (params[id]) {
            return [params[id].from ? +params[id].from : '', params[id].to ? +params[id].to : '']
        }

        return [values[id].from, values[id].to]
    }

    const [state, setState] = useState(() => getState())

    useEffect(() => {
        const state_tmp = getState()

        if(state_tmp !== state) {
            setState(state_tmp)
        }
    },[values, smartFilters])

    const [predefined, setPredefined] = useState<Record<string, FilterRangePredefined>>(
        Object.fromEntries(
            data.predefined_ranges?.map(opt => [
                [opt.name],
                {
                    from: opt.from,
                    to: opt.to,
                    checked: params[id]
                        ? opt.from >= params[id]?.from && opt.to <= params[id]?.to
                        : false,
                },
            ])
        )
    )
    const needCommit = useHasChanged(state)

    const handleChangeCheckbox = (opt: RangeValue) => {
        opt.from = Number(opt.from)
        opt.to = Number(opt.to)

        return (e: React.ChangeEvent<HTMLInputElement>) => {
            predefined[opt.name].checked = e.target.checked

            const checkedValues = Object.values(predefined).filter(val => val.checked)
            let from = null;
            let to = null;

            if (checkedValues.length) {
                from = Math.min(...Object.values(checkedValues).map(val => val.from))
                to = Math.max(...Object.values(checkedValues).map(val => val.to))
            }

            setState([from, to])
            setPredefined({...predefined})

            dispatch(asideFiltersActions.updateFilterValue({
                id,
                newValue: {
                    from: from,
                    to: to,
                }
            }))
        }
    }

    const handleChange = (index: number) => (newVal: number | string) => {
        const newVal_prepared = typeof newVal === 'number' || (typeof newVal === 'string' && newVal.length > 0) ? Number(newVal): null
        setChanged(true)
        if (index === 0) {
            setState([newVal_prepared, state[1]])
        } else {
            setState([state[0], newVal_prepared])
        }
    }

    useEffect(() => {
        if (Object.keys(isActive).length > 0 && !isActive[id]) {
            setState([null, null])
        }
    }, [values])

    useEffect(() => {
        if (needCommit && isChanged) {
            const timeout = setTimeout(() => {
                dispatch(
                    asideFiltersActions.updateFilterValue({
                        id,
                        newValue: {
                            from: state[0],
                            to: state[1],
                        },
                    })
                )
                setChanged(false)
                clearTimeout(timeout)
            }, throttle)

            return () => {
                clearTimeout(timeout)
            }
        }
    },[state])

    return (
        <div className="tdx-filter-range">
            {!!data.predefined_ranges?.length &&
                data.predefined_ranges.map(opt => (
                    <TDXCheckbox
                        checked={predefined[opt.name].checked}
                        key={opt.name}
                        label={opt.name}
                        onChange={handleChangeCheckbox(opt)}
                    />
                ))}
            <div className="inputs-container">
                {type === 'number_range' ? (
                    <>
                        <TDXIntegerInput
                            placeholder={data.placeholder?.from}
                            value={state[0] as number}
                            max={data.placeholder?.to as number}
                            onChange={handleChange(0)}
                            allowNull={true}
                        />
                        <TDXIntegerInput
                            placeholder={data.placeholder?.to}
                            value={state[1] as number}
                            max={data.placeholder?.to as number}
                            onChange={handleChange(1)}
                            allowNull={true}
                        />
                    </>
                ) : (
                    <>
                        <TDXSelector
                            placeholder={data.placeholder?.from}
                            options={data.options}
                            value={state[0].toString()}
                            onChange={handleChange(0)}
                        />
                        <TDXSelector
                            placeholder={data.placeholder?.to}
                            options={data.options}
                            value={state[1].toString()}
                            onChange={handleChange(1)}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default TDXFilterRange

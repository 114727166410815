import {createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {CertificatesRequest, OrderCertificate, OrdersSchema} from './types'
import {getCertificates} from "./asyncActions";

const initialState: OrdersSchema = {
    certificates: {},
}

const initialCertificate: OrderCertificate = {
    order_id: null,
    products: null,
    url: null,
    loading: false,
    polling: 0,
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setCertificateData: (
            state,
            action
        ) => {
            state.certificates[action.payload.order_id] = {
                ...state.certificates[action.payload.order_id],
                ...action.payload
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(getCertificates.pending, (state: OrdersSchema, action) => {
            let args = action.meta.arg as CertificatesRequest
            state.certificates[args.order_id] = initialCertificate
            state.certificates[args.order_id] = {
                ...state.certificates[args.order_id],
                products: [
                    ...args.products
                ]
            }
            //state.certificates[args.order_id].loading = true
        })
        // builder.addCase(getCertificates.rejected, (state: OrdersSchema, action) => {
        //     //state.certificates.loading = false
        //     console.log('certificates loading false', action)
        //     //state.certificates[action.meta.arg.order_id] = initialCertificate
        //     //state.certificates[action.meta.arg.order_id].loading = false
        // })
        // builder.addCase(getCertificates.fulfilled, (state: OrdersSchema, action) => {
        //     console.log('certificates loading false', action)
        //     //state.certificates[action.meta.arg.order_id].loading = false
        // })
    },
})

export const {actions: ordersActions} = ordersSlice
export const {reducer: ordersReducer} = ordersSlice

import {useDispatch, useSelector} from 'react-redux'
import {/*useNavigate, */Link} from 'react-router-dom'
import {namedRequestsInProgress} from '../../../store/RequestSelectors'
import {ProfileRequestsEnum} from '../../../api/profile.service'
import {changeWaitingList} from '../../../store/actions/ProfileActions'
import {TDXButton} from '../Buttons'
//import {getNoun} from '../../../utils/getNoun'
import {getSearchHighlight} from '../../../utils/getSearchHighlight'
import CatalogItemOffers from "../../Catalog/CatalogItemOffers";
import {SearchSerializer} from "../../../store/actions/GeneralActions";

const TDXSearchInputItem = ({product, closePopper, searchQuery = null}: {
    product: SearchSerializer,
    closePopper?: any,
    searchQuery?:string,
}) => {
    const dispatch = useDispatch()
    //const navigate = useNavigate()

    const {updatingWaitList} = useSelector(state => ({
        updatingWaitList: namedRequestsInProgress(
            state,
            ProfileRequestsEnum.updateWaitingList(product?.sku)
        ),
    }))

    /*
    const handleShowOffers = id => () => {
        closePopper()
        navigate(`/product/${id}#product-offers`)
    }
    */

    const addToWaitList = (sku: string) => () => {
        !updatingWaitList && dispatch<any>(changeWaitingList('add', [sku]))
    }

    const getProductSKU = () => {
        const result = []

        const p = product?.offers.filter(p => p.supplier_id == -1)
        if (p.length > 0) {
            if (p[0].sku.length > 0) {
                result.push('Код поставщика: '+p[0].sku)
            }
        }

        if (product?.ext_id) {
            result.push('Id: '+product.ext_id)
        }

        return result.join('<br>')
    }

    return (
        <div className="paper-search-item product" key={product.id}>
            <div className="search-product-content">
                {/*<img src={product.image} alt={product.name} />*/}
                <Link to={`/product/${product.id}?r=search`}>
                    <h6 dangerouslySetInnerHTML={{__html: getSearchHighlight(product.name, searchQuery)}} />
                </Link>
                <p className="code body-2" dangerouslySetInnerHTML={{__html: getSearchHighlight(product.sku, searchQuery)}} />
                <p className="code body-2 ext_id" dangerouslySetInnerHTML={{__html: getSearchHighlight(getProductSKU(), searchQuery)}} />
            </div>
            <div className="offers body-2">
                {product['offers'] ? (
                    <CatalogItemOffers item={product} filter={[]} max_offers={2} />
                    // <TDXButton
                    //     key={product.id}
                    //     size="small"
                    //     bg="gray"
                    //     onClick={handleShowOffers(product.id)}>
                    //     {product['offers']}{' '}
                    //     {getNoun(product['offers'], 'предложение', 'предложения', 'предложений')}
                    // </TDXButton>
                ) : (
                    <TDXButton
                        key={product.id}
                        size="small"
                        bg="gray"
                        onClick={addToWaitList(product.sku)}
                        loading={updatingWaitList}>
                        Добавить в лист ожидания
                    </TDXButton>
                )}
            </div>
        </div>
    )
}

export default TDXSearchInputItem

import {useAppSelector} from "../../shared/hooks/hooks";
//import {ProfileState, UserState} from "../../store/actions/ProfileActions";
import {RootState} from '../../store/store'
import {NotificationSerializer} from "../../api/profile.service";

const HeaderNotify = () => {
    const notify : NotificationSerializer[] = useAppSelector(
        (state : RootState) => state.Profile.profile.notify
    )

    return (
        <div className="header-notify">
            {notify?.map((item, i) => {
                return (
                    <div key={i} className={`header-notify-item ${item.type}`}>
                        <span dangerouslySetInnerHTML={{__html: item.msg}}></span>
                    </div>
                )
            })}
        </div>
    )
}

export default HeaderNotify

import {getNgram} from "./getNgram";

const translate = (str: string, reverse: boolean = false) => {
    let replacer: Record<string, string> = {
        "q": "й", "w": "ц", "e": "у", "r": "к", "t": "е", "y": "н", "u": "г", "i": "ш",
        "o": "щ", "p": "з", "[": "х", "{": "х", "]": "ъ", "}": "ъ", "a": "ф", "s": "ы",
        "d": "в", "f": "а", "g": "п", "h": "р", "j": "о", "k": "л", "l": "д", ";": "ж",
        ":": "ж", "'": "э", '"': "э", "z": "я", "x": "ч", "c": "с", "v": "м", "b": "и",
        "n": "т", "m": "ь", ",": "б", "<": "б", ".": "ю", ">": "ю", "/": ".", "?": ".",
        "-": "-",
    };

    if (reverse) {
        replacer = Object.fromEntries(Object.entries(replacer).map(([k, v]) => [v, k]))
    }

    return str.replace(/[A-zА-яЁё/,.;/-:<>?{}/"'\][]/g, function (x) {
        return (
            replacer[x] ||
            (
                replacer[x.toLowerCase()]
                ? x === x.toLowerCase()
                ? replacer[x]
                : replacer[x.toLowerCase()].toUpperCase()
                : ''
            )
        );
    });
}

export function getSearchHighlight (name: string, searchQuery: string) {
    if (name.length>0 && searchQuery.length>0) {
        const searchList = searchQuery.split(' ')

        searchList.forEach(search => {
            const translate_word = [translate(search), translate(search, true)]
            translate_word.forEach((word) => {
                if (word.length>0) searchList.push(word)
            })
        })

        searchList.sort(function (a, b) {
            return b.length - a.length;
        });

        searchList.forEach(search => {
            const ngram = getNgram(search, 2, 30)
            ngram.every(str => {
                const str_c = str.toLocaleLowerCase().replace('c', 'с')
                if (name.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) >= 0 || name.toLocaleLowerCase().indexOf(str_c) >= 0) {
                    const re = new RegExp(
                        str.replace(
                            /[-[\]{}()*+?.,\\^$|#\s]/g,
                            "\\$&"
                        ).replaceAll(
                            /[cCсС]/g,
                            "[сc]"
                        ),
                        "gi"
                    );
                    name = name.replaceAll(re, '{{|}}$&{{/|}}')
                    return false
                }
                return true
            })
        })
    }

    name = name.replaceAll('{{|}}', '<b class="green">')
    name = name.replaceAll('{{/|}}', '</b>')

    return name
}

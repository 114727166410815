import {SvgIcon} from '@mui/material'

const TelegramIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '17px', height: '15px', color: 'rgba(0, 0, 0, 0.8)'}}
        viewBox="0 0 17 15">
        <path d="M15.6873 0.875864L1.38553 6.4195C0.810004 6.67764 0.615341 7.19465 1.2464 7.47522L4.91546 8.64724L13.7867 3.1363C14.2711 2.79032 14.767 2.88258 14.3403 3.26315L6.72106 10.1975L6.48171 13.1321C6.7034 13.5852 7.10931 13.5873 7.36822 13.3621L9.47619 11.3572L13.0864 14.0746C13.9249 14.5736 14.3812 14.2516 14.5616 13.337L16.9296 2.06633C17.1754 0.940562 16.7562 0.444548 15.6873 0.875864Z" />
    </SvgIcon>
)

export default TelegramIcon

import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {ClickAwayListener} from '@mui/material'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {catalogCategoriesActions} from '../../store/slice/catalogCategoriesSlice/catalogCategoriesSlice'
import {CSVMenu} from "../Icons";
import {TDXButton, TDXNavLink} from "../UI";
import TDXBufferModal from "../Buffer/TDXBufferModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function HeaderCatalogCategories () {
    const dispatch = useAppDispatch()
    const {key} = useLocation()

    const categories = useAppSelector(state => state.CatalogCategories.categories)
    const isOpen = useAppSelector(state => state.CatalogCategories.open)
    const isLoading = useAppSelector(state => state.CatalogCategories.loading)

    const closeCategoriesMenu = () => {
        dispatch(catalogCategoriesActions.setVisibility(false))
        // setTimeout(() => {
        //     dispatch(catalogCategoriesActions.resetActiveCategories())
        // }, 300)
    }

    const targetMenu = () => {
        dispatch(catalogCategoriesActions.setVisibility(!isOpen))
    }

    const onClickAway = (e: MouseEvent | TouchEvent) => {
        if (!isOpen) return

        const {target} = e
        const catalogMenu = ((target as Element)?.closest('[data-catalog-menu="true"]') as HTMLElement)?.dataset?.catalogMenu ?? false

        if (catalogMenu) return

        closeCategoriesMenu()
    }

    useEffect(() => {
        isOpen && closeCategoriesMenu()
    }, [key])

    if (!isLoading && !categories.length) return null

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div className="header-link-menu link">
                <button
                    className={`tdx-nav-link link tdx-nav-catalog ${isOpen ? 'active': ''}`}
                    onClick={() => targetMenu()}
                >
                    <CSVMenu className={'csv-menu'} />
                    <h5>Каталог</h5>
                    <KeyboardArrowDownIcon className={'arrow-down'} />
                </button>
            </div>
            {/*<TDXButton*/}
            {/*    size="small"*/}
            {/*    bg={'transparent'}*/}
            {/*    startIcon={<CSVMenu />}*/}

            {/*    className="logoMenu"*/}
            {/*    id="burgerMenu"*/}
            {/*/>*/}
        </ClickAwayListener>
    )
}

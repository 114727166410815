import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import TokenService from '../../../api/token.service'
import {AuthState, Partner, User} from './types'
import {emptyUser} from './const'

const LCUser = TokenService.getUser()

const initialState: AuthState = {
    isLoggedIn: Boolean(LCUser),
    user: LCUser || emptyUser,
    partners: [],
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<User>) => {
            TokenService.setUser(action.payload)

            state.isLoggedIn = true
            state.user = action.payload
        },
        logout: () => {
            TokenService.removeUser()

            return {
                isLoggedIn: false,
                user: emptyUser,
                partners: [],
            }
        },
        setPartners: (state, action: PayloadAction<Partner[]>) => {
            state.partners = action.payload
        },
        updateUserPartner: (state, action: PayloadAction<Partner>) => {
            state.user.partner = action.payload

            TokenService.setUser(state.user)
        },
    },
})

export const {actions: authActions} = authSlice
export const {reducer: authReducer} = authSlice

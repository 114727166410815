import {Link, NavLink} from 'react-router-dom'
import {ClickAwayListener, IconButton, List, ListItem, Paper, Popper} from "@mui/material";
import {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {InfoOutlined, InfoRounded} from "@mui/icons-material";
import {getWaitingListMini, UserState} from "../../../store/actions/ProfileActions";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";

export default function TDXWaitList () {
    const dispatch = useAppDispatch()

    const {profile, waitList_mini} = useAppSelector<UserState>(state => state.Profile)
    const [anchorEl, setAnchorEl] = useState(null)
    const [notifyList, setNotifyList] = useState<Record<string, string>>({})
    const closeMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const openMenu = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const isExistsAvailableItems = () => {
        let isExists = false
        waitList_mini.forEach((product) => {
            if (product.stock_id>0) isExists = true
        })
        return isExists
    }

    useEffect(() => {
        if (profile.id > 0) {
            dispatch<any>(getWaitingListMini())
        }
    }, [profile])

    useEffect(() => {
        if (waitList_mini.length > 0) {
            const notify = {} as Record<string,string>
            if (isExistsAvailableItems()) {
                notify['available_products'] = `Появились предложения по товарам из листа ожидания`
            }
            setNotifyList({...notify })
        }
    }, [waitList_mini])

    return (
        <NavLink to="/user/waiting-list" className="tdx-nav-link link tdx-waiting-list">
            <h5>Лист ожидания</h5>
            {Object.keys(notifyList).length > 0 ? (
                <>
                    <IconButton className="icon-button info-rounded-icon" onMouseEnter={openMenu}>
                        <InfoRounded className="icon info-rounded-icon" />
                    </IconButton>
                    <Popper
                        id="waiting-list-popper"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                      >
                        <ClickAwayListener onClickAway={closeMenu}>
                            <Paper elevation={3}>
                                <p className="popper-title body-1 light">Внимание!</p>
                                <List>
                                    {Object.entries(notifyList).map(notify => {
                                        return (
                                            <ListItem key={notify[0]}>
                                                <InfoOutlined className="icon info-outlined-icon"/>
                                                <span className="body-1 light">{notify[1]}</span>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                <Link to="/user/waiting-list" className="popper-link">Подробнее</Link>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </>
            ) : (<></>)}
        </NavLink>
    )
}

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {IconButton} from '@mui/material'

interface TDXSortButtonInterface {
    type?: string,
    onClick?: () => void,
    sortingField: string,
    field: string
}

const TDXSortButton = ({type = '', onClick, sortingField, field}: TDXSortButtonInterface) => (
    <IconButton
        className={`tdx-sort-button  ${
            sortingField === field && type
                ? type === 'desc'
                    ? 'active rotate'
                    : 'active'
                : 'none-type'
        }`}
        onClick={onClick}>
        <ArrowDownwardIcon sx={{fontSize: '1rem'}} />
    </IconButton>
)

export default TDXSortButton

import ClearIcon from "@mui/icons-material/Clear";
import {IconButton, Tooltip} from '@mui/material'
import {TDXButton} from '../Buttons'
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";
import {useEffect, useState} from 'react'
import {parseSearchParams} from '../AsideFilter/utils'
import {useSearchParams} from "react-router-dom";
import {asideFiltersActions} from "../../../store/slice/asideFiltersSlice/asideFiltersSlice";
import ungapStructuredClone from '@ungap/structured-clone'
import dayjs from 'dayjs'
import {
    AsideFiltersState,
    DictValue,
    FilterValue,
    RangeValue,
    TextValue
} from "../../../store/slice/asideFiltersSlice/types";

const AsideFilterItems = ({
    rememberKey = null
} : {
    rememberKey?: string | null
}) => {
    const dispatch = useAppDispatch()
    const {data, values, smartFilters} = useAppSelector<AsideFiltersState>(state => state.AsideFilters)
    const [searchParams] = useSearchParams()
    const [state, setState] = useState<Record<string, RangeValue | DictValue> | null>(() => null)
    const [filterValues, setFilterValues] = useState([])

    useEffect(() => {
        let values_tmp = values
        let params = parseSearchParams(searchParams)

        if (rememberKey) {
            params = {
                ...params,
                ...parseSearchParams(JSON.parse(localStorage.getItem(rememberKey)) ?? {}),
            }
        }

        if (params) {
            values_tmp = {
                ...values,
                ...params,
            }
        }

        setState(values_tmp)
    }, [values, smartFilters])

    useEffect(() => {
        if(data && state && Object.entries(state).length > 0) {
            const filterValues_tmp: FilterValue[] = []

            Object.entries(state).forEach(filter => {
                const fil = data.filter(f => f.id === filter[0])?.[0]

                if (['dictionary'].includes(fil.type)) {
                    Object.entries(filter[1]).forEach(val => {
                        const opt = fil?.data?.options?.filter(o => o.id.toString() === val[0])?.[0]
                        if (opt && val[1]) {
                            filterValues_tmp.push({
                                filter:filter[0],
                                id:val[0],
                                name: opt?.name,
                                filter_name: fil.name,
                                type: fil.type
                            })
                        }
                    })
                }

                if (['text_number'].includes(fil.type)) {
                    Object.entries(filter[1]).forEach(val => {
                        if (val[1]) {
                            filterValues_tmp.push({
                                filter:filter[0],
                                id:val[0],
                                name: val[1],
                                filter_name: fil.name,
                                type: fil.type
                            })
                        }
                    })
                }

                if (['number_range', 'slider_range', 'date_range'].includes(fil.type)) {
                    const opt = fil?.data?.placeholder

                    if (fil && (filter[1]?.from || filter[1]?.to)) {
                        let name_from: string = filter[1].from ? String(filter[1].from): String(opt.from)
                        let name_to: string = filter[1].to ? String(filter[1].to): String(opt.to)

                        if (fil.type === 'number_range') {
                            name_from = name_from.replace('.00', '')
                            name_to = name_to.replace('.00', '')
                        }

                        if (!(fil.type === 'date_range' && name_from == opt.from && name_to == opt.to)) {

                            if (fil.type === 'date_range') {
                                name_from = dayjs(name_from, "DD.MM.YYYY").format('DD.MM.YYYY')
                                name_to = dayjs(name_to, "DD.MM.YYYY").format('DD.MM.YYYY')
                            }

                            filterValues_tmp.push({
                                filter:filter[0],
                                id:null,
                                name: name_from+" - "+name_to,
                                filter_name: fil.name,
                                type: fil.type
                            })
                        }
                    }
                }
            })

            setFilterValues(filterValues_tmp)
        }
    }, [state])

    const updateFilter = (id: string, opt_id: string | number, type: string) => {
        let state_tmp = ungapStructuredClone(state[id])

        if(opt_id) {
            let value = false

            if (type === 'text_number') {
                value = null
            }

            (state_tmp as DictValue)[opt_id] = value
        } else {
            const fil = data.filter(f => f.id === id)?.[0]
            const opt = fil?.data?.placeholder as RangeValue
            if (opt) {
                state_tmp = fil.type === 'date_range' ? opt: Object.fromEntries(Object.entries(opt).map(p => {p[1] = null; return p;}))
            }
        }

        dispatch(asideFiltersActions.updateFilterValue({id, newValue: state_tmp}))
    }

    return filterValues.length > 0 && (
        <div className="filter-items">
            {filterValues.map(filter => (
                <Tooltip key={filter.id} title={filter.filter_name}>
                    <IconButton className="filter-item bg-gray" onClick={() => updateFilter(filter.filter, filter.id, filter.type)}>
                        <span className={'label'}>{filter.name}</span>
                        <ClearIcon className="icon clear-icon" />
                    </IconButton>
                </Tooltip>
            ))}
        </div>
    )
}

export default AsideFilterItems

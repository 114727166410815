import {
    MANAGER_ADD_NEW_PARTNER,
    MANAGER_ADD_NEW_USER,
    MANAGER_SET_PARTNERS,
    MANAGER_SET_USERS,
    MANAGER_SET_USERS_1C,
    MANAGER_UPDATE_PARTNER,
    MANAGER_UPDATE_USER,
} from '../actions/ManagerActions'
import {Partner} from '../slice/authSlice/types'

const initialState = {
    users: [],
    partners: [],
    users1C: [],
} as {
    users: any[],
    partners: Partner[],
    users1C: any[],
}

export const ManagerReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MANAGER_SET_USERS:
            return {
                ...state,
                users: action.data.users,
            }
        case MANAGER_ADD_NEW_USER:
            return {
                ...state,
                users: [action.data.user, ...state.users],
            }
        case MANAGER_UPDATE_USER:
            return {
                ...state,
                users: state.users.map(user => {
                    if (user.id === action.data.user.id) {
                        return {
                            ...user,
                            ...action.data.user,
                        }
                    }

                    return user
                }),
            }
        case MANAGER_SET_PARTNERS:
            return {
                ...state,
                partners: action.data.partners,
            }
        case MANAGER_ADD_NEW_PARTNER:
            return {
                ...state,
                partners: [action.data.partner, ...state.partners],
            }
        case MANAGER_UPDATE_PARTNER:
            return {
                ...state,
                partners: state.partners.map(partner => {
                    if (partner.id === action.data.partner.id) {
                        return {
                            ...partner,
                            ...action.data.partner,
                        }
                    }

                    return partner
                }),
            }
        case MANAGER_SET_USERS_1C:
            return {
                ...state,
                users1C: action.data.users1C,
            }
        default:
            return state
    }
}

import {SvgIcon} from '@mui/material'

const RangeSliderThumbIcon = (props: any) => (
    <SvgIcon {...props} sx={{width: '10px', height: '16px', fill: 'none'}} viewBox="0 0 10 16">
        <path d="M0 4.57143L5 0L10 4.57143V16H0V4.57143Z" fill="white" />
        <path
            d="M5 0.67748L9.5 4.79177V15.5H0.5V4.79177L5 0.67748Z"
            stroke="#3F51B5"
            strokeOpacity="0.3"
        />
    </SvgIcon>
)

export default RangeSliderThumbIcon

import {useMemo, memo, useEffect, useState, useCallback} from 'react'
import {Skeleton, Tooltip} from '@mui/material'
import dotToValue from '../../../utils/dotToValue'
import {translit} from '../../../utils/translit'
//import {/*TDXButton, */TDXPagination} from '../index'
import TDXPagination from '../Buttons/TDXPagination'
import TDXSortButton from '../Buttons/TDXSortButton'
import ColumnResizer from './ColumnResizer'
import {TableColumn, Row, TDXTableProps} from './types'
import TokenService from '../../../api/token.service'
import {useSearchParams} from "react-router-dom";
import {parseSearchParams} from '../AsideFilter/utils'


const sortFunctions = (sortingField: string) => ({
    asc: (a: any, b: any) => (dotToValue.get(a, sortingField) < dotToValue.get(b, sortingField) ? 1 : -1),
    desc: (a: any, b: any) => (dotToValue.get(a, sortingField) > dotToValue.get(b, sortingField) ? 1 : -1),
}) as Record<any, any>

/*
const HEIGHTS = {
    header: 40,
    pagination: 52,
    scroll: 9,
    tableBorders: 2,
}
*/

export default function TDXSuperTable<
    I extends Row<T>,
    T=number
> ({
    id,
    columns = [],
    items = null,
    pageItems = null,
    metaGet = null,
    selected = [],
    errors = [],
    success = [],
    disabledItems = [],
    disabledLabel = '',
    loading = false,
    rows = {},
    onResize = null,
    rowToggle = null
} : TDXTableProps<T, I>)
{
    const rowHeight = rows?.height ?? 45
    const rowsCount = rows?.count ?? 12
    const rowsCountMin = 1
    /*
    const tableHeight =
        rowHeight * rowsCount +
        HEIGHTS.header +
        HEIGHTS.scroll +
        HEIGHTS.pagination +
        HEIGHTS.tableBorders
    */

    //const uniqueArr = arr => Array.from(new Set(arr))

    const LCSettings = TokenService.tableSettings
    const config = LCSettings.get(id, {})
    const [searchParams, setSearchParams] = useSearchParams()
    const getPage = (): number => {
        let params = parseSearchParams(searchParams, true) as Record<string, string>
        return params?.page ? Number(params.page): 1
    }
    const [page, setPage] = useState(getPage);
    const [_meta, setMeta] = useState(null);

    const [sortBy, setSortBy] = useState<
        {
            sortingField: string,
            type: 'asc' | 'desc' | ''
        }
    >(() => config?.sorting ?? {sortingField: '', type: ''})

    useEffect(() => {
        if (page !== getPage()) {
            setPage(getPage())
        }
    },[searchParams])

    const [resizableColsWidth, setResizableColsWidth] = useState<
        Record<number,number>
    >(
        () =>
            config?.resize ??
            Object.fromEntries(
                columns
                    .filter(col => col.resizable)
                    .map(col => [col.id, col.width])
            )
    )
    const [resizableColsMinWidth,/* setResizableColsMinWidth*/] = useState<
        Record<number,number | null>
    >(
        () => Object.fromEntries(
            columns.filter(col => col.resizable).map(col => [col.id, col.minWidth || null])
        )
    )

    useEffect(() => {
        if (pageItems2 !== null && metaGet !== null)
        {
            setMeta(metaGet(page));
        }
        else
        {
            setMeta(null);
        }
    }, [pageItems, page])

    const rowTemplate = (item: I) => {
        return (
            <div>
                <div
                    className={`row ${errors.includes(item.id) ? 'error' : ''} ${success.includes(item.id) ? 'success' : ''} ${disabledItems.includes(item.id) ? 'disabled':''}`}
                    style={{height: loading ? rowHeight : 'auto'}}
                    data-selected={selected.includes(item.id)}>
                    {columns
                        .filter(col => !col.hide)
                        .map(col => (
                            <div
                                key={col.id}
                                className={`cell body-1 col-${translit(String(col?.header), 'unknown', true)}`}
                                data-cell={col.id}
                                style={{
                                    ...createCellStyles(col),
                                    ...(col?.cellStyle || {}),
                                }}
                                data-editable={col.editable}>
                                {loading ? (
                                    col.skeleton ? (
                                        col.skeleton
                                    ) : (
                                        <Skeleton width={col.width}/>
                                    )
                                ) : (
                                    col.body(item)
                                )}
                            </div>
                        ))}
                </div>
            </div>
        )
    }

    const pageItems2 = useCallback(() => {
      let items2 = [...items].sort(sortFunctions(sortBy.sortingField)[sortBy.type])
      //let _meta = null;

      if (pageItems !== null)
      {
        //_meta = metaGet(page);

        items2 = [...pageItems()].sort(sortFunctions(sortBy.sortingField)[sortBy.type]);
      }
      else
      {
        const meta = paginatorMeta;

        items2 = items2.slice(meta.per_page * (meta.page - 1)).slice(0,meta.per_page);
      }

      if (items2.length>0 && !loading)
      {
        return items2
          .map((item, i) => (
            <div
                key={`${item.id ?? i}`}
                className={
                    `row-wrapper ${
                        rowToggle !== null &&
                        rowToggle.toggled.includes(item.id) ? 'show':''
                    }`
                }>

                {disabledItems.includes(item.id) ? (
                    <Tooltip followCursor title={disabledLabel}>
                        {rowTemplate(item)}
                    </Tooltip>
                ): rowTemplate(item)}

                {rowToggle !== null ? (
                    rowToggle.rowsTemplate(item, rowToggle)
                ) : (<></>)}

            </div>
        ));
      }
      else
      {
        return Array(items2.length>0 ? items2.length : rowsCountMin)
          .fill('')
          .map((_, i) => i)
          .map(id => (
              <div key={id} className="row" style={{height: rowHeight}}>
                  {columns
                      .filter(col => !col.hide)
                      .map(col => (
                          <div
                              key={col.id}
                              className={`cell body-1 col-${translit(String(col?.header), 'unknown', true)}`}
                              data-cell={col.id}
                              style={createCellStyles(col)}>
                              {loading && (
                                  <>
                                      {col.skeleton ? (
                                          col.skeleton
                                      ) : (
                                          <Skeleton width={col.width}/>
                                      )}
                                  </>
                              )}
                          </div>
                      ))}
              </div>
          ))
      }
    }, [
        items, pageItems,
        //metaGet,
        _meta,
        page, loading,
        selected, errors,
        sortBy,
    ])

    const paginatorMeta = useMemo(() => {
        let meta = null;

        if (items !== null)
        {
            meta = {
                total: items.length,
                per_page: rowsCount,
                page: page,
            };
        }
        else if (metaGet !== null)
        {
            //const meta2 = metaGet(page);
            const meta2 = _meta;

            if (meta2 !== null)
            {
                meta = meta2;
            }
        }

        return meta;
    }, [
        items, pageItems,
        //metaGet,
        _meta,
        rowsCount,
        page, loading
    ]);

    const paginatorGet = useCallback(() => {
        const meta = paginatorMeta;

        if (meta !== null && meta.total > meta.per_page)
        {
          return <TDXPagination
              key={meta.total}
              count={Math.ceil(meta.total / meta.per_page)}
              page={meta.page}
              onChange={(e, page) => setPage(page)}
              disabled={loading}
          />;
        }
        else
        {
            return <></>
        }
    }, [
        //items,
        //pageItems,
        //metaGet,
        //_meta,
        page, loading,
        paginatorMeta,
    ])

    const resizeColumn = (id : number, newWidth : number) => {
        if (onResize != null)
        {
            onResize(id, newWidth)
        }

        setResizableColsWidth({...resizableColsWidth, [id]: newWidth})
    }

    const sortColumn = (sortingField : string) => () => {
        const type =
            sortBy.sortingField === sortingField
                ? sortBy.type
                    ? sortBy.type === 'asc'
                        ? 'desc'
                        : ''
                    : 'asc'
                : 'asc'

        setSortBy({
            sortingField,
            type,
        })
    }

    function createCellStyles (
        col : TableColumn<I>
    ) : Record<string, any>
    {
        const width = col.resizable
            ? {
                width: resizableColsWidth[col.id],
                maxWidth: resizableColsWidth[col.id],
                minWidth: resizableColsWidth[col.id],
            }
            : col.width
                ? {
                    width: col.width,
                    maxWidth: col.width,
                    minWidth: col.width,
                }
                : {}
        const flex = col.flex ? {flex: col.flex} : {}

        return {
            ...width,
            ...flex,
        }
    }

    useEffect(() => {
        const newConfig = {resize: resizableColsWidth, sorting: sortBy}
        const needUpdate = JSON.stringify(config) !== JSON.stringify(newConfig)

        if (needUpdate) {
            const timeout = setTimeout(() => {
                LCSettings.set(id, newConfig)
                clearTimeout(timeout)
            }, 1000)

            return () => clearTimeout(timeout)
        }
    }, [resizableColsWidth, sortBy])

    return (<>
        <div className="tdx-table-container">
            <div className="scroll-fix tdx-scrollbar">
                <div className="table">
                    <div className="row header">
                        {columns
                            .filter(col => !col.hide)
                            .map(col => (
                                <div
                                    key={col.id}
                                    className={`cell subtitle-2 col-${translit(String(col?.header), 'unknown', true)}`}
                                    data-resizable={col.resizable}
                                    data-cell={col.id}
                                    style={{
                                        ...createCellStyles(col),
                                        ...(col?.headerStyle || {}),
                                    }}>
                                    <div style={{overflow: 'hidden'}} className="content">
                                        {col.header || ''}
                                        {col.sortingField && (
                                            <TDXSortButton
                                                {...sortBy}
                                                field={col.sortingField}
                                                onClick={sortColumn(col.sortingField)}
                                            />
                                        )}
                                    </div>
                                    {col.resizable && (
                                        <ColumnResizer
                                            id={col.id}
                                            initialWidth={resizableColsWidth[col.id]}
                                            initialMinWidth={resizableColsMinWidth[col.id]}
                                            onChange={resizeColumn}
                                            disabled={loading}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className='table-content'>
                      {pageItems2()}
                    </div>
                </div>
            </div>
            <div className="pagination">
                {paginatorGet()}
            </div>
        </div>
    </>);
}

//export default TDXSuperTable

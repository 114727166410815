import {FilterType, FilterDataData, FilterData, RangeValue, DictValue} from './types'

export const typeToStateValue = (
    type : FilterType,
    data : FilterDataData
) => {
    switch (type) {
        case 'dictionary': {
            return Object.fromEntries([...data.options.map(opt => [opt.id, false])])
        }
        case 'date_range': {
            return {...data.option}
        }
        case 'text': {
            return {...data.options}
        }
        case 'text_number': {
            return {...data.options}
        }
        default: {
            return {
                from: null,
                to: null,
            }
        }
    }
}

export const createFiltersValues = (filters : FilterData[]) => {
    const res = Object.fromEntries(
        filters.map(f => [f.id, typeToStateValue(f.type, f.data)])
    );
    return res as Record<string, RangeValue | DictValue>
}

import {TextField} from '@mui/material'

const TDXInput = (props: Record<string, any>) => (
    <TextField
        {...props}
        className={`tdx-input ${props.className ? props.className : ''}`}
        variant="outlined"
    />
)

export default TDXInput

import {Link} from 'react-router-dom'
import {Skeleton} from "@mui/material";
import {getNoun} from "../../../utils/getNoun"
import {
    AuctionListSerializer,
} from "../../../store/actions/AuctionActions";
import {TDXButton} from "../Buttons";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../shared/hooks/hooks";

interface TDXAuctionInterface {
    auction?: AuctionListSerializer,
    loading?: boolean
}

interface ButtonData {
    color?: string,
    text?: string
}

export default function TDXAuction ({auction = null, loading=false}: TDXAuctionInterface) {
    const currentUserId = useAppSelector(state => state.Profile.profile.id)
    const [dateFinishText, setDateFinishText] = useState<string>('')
    const [onTimer, setTimer] = useState(false)
    const [isFinish, setFinish] = useState(false)
    const [buttonData, setButtonData] = useState<ButtonData>({color: '', text: ''})
    const [isMyBid, setIsMyBid] = useState<boolean>(false)
    const [myLastBid, setMyLastBid] = useState(null)

    useEffect(() => {
        if (onTimer){
            const interval = setInterval(() => setTime(auction?.date_finish), 1000);

            return () => {
                clearInterval(interval)
                setTimer(false)
            };
        }
    }, [onTimer])

    const setTime = (deadline: number) => {
        const seconds = Math.floor(deadline - Date.now()/1000)

        if (seconds > 0) {
            //const d = Math.floor(seconds / (3600*24));
            const h = Math.floor(seconds / 3600);
            const m = Math.floor(seconds % 3600 / 60);
            const s = Math.floor(seconds % 60);

            setDateFinishText(h+' '+getNoun(h, 'час', 'часа', 'часов')+' : '+m+' '+getNoun(m, 'минута', 'минуты', 'минут')+' : '+s+' '+getNoun(s, 'секунда', 'секунды', 'секунд'))
        } else if (seconds <= 0) {
            setFinish(true)
            setTimer(false)
            setDateFinishText('Аукцион завершен')
        }
    }

    useEffect(() => {
        if (auction?.date_finish) {

            setIsMyBid(auction?.bids?.[0]?.user_id == currentUserId)

            const myBids = auction?.bids.filter(b => b?.user_id == currentUserId)
            if (myBids.length > 0) {
                setMyLastBid(myBids[0].price)
            }

            if (!auction?.active) {
                setFinish(true)
                setDateFinishText('Аукцион завершен')
            } else {
                setTime(auction?.date_finish)
                setTimer(true)
            }
        }
    }, [auction, currentUserId])

    useEffect(() => {
        const data: ButtonData = {color: '', text: ''}

        if (myLastBid > 0) {
            if (isMyBid) {
                data.color = 'green'
                data.text = 'Ваша ставка в игре'
            } else {
                data.color = 'gray'
                data.text = 'Ваша ставка перебита'
            }
        } else {
            data.color = 'black'
            data.text = 'Участвовать'
        }

        setButtonData(data)
    }, [myLastBid, isMyBid])

    return !loading && auction?.id ? (
        <div className={'auction-item'}>
            <div className={'auction-item__column'}>
                <Link to={`/auctions/${auction.id}`} className={'auction-item__title'}>{auction.name}</Link>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__products'}>
                    <span className={'auction-item__products-title'}>Состав лота:</span>
                    <div className={'auction-item__products-list'}>
                        {auction?.products.map((v,k) => (
                            <div className={'auction-item__product'} key={k}>
                                {k+1}. {v?.name} - {v?.quantity ?? 0} шт.
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__prices'}>
                    {myLastBid > 0 && (
                        <div className={'auction-item__prices-row color-green'}>
                            <span>Ваша ставка:</span>
                            <span>{myLastBid} {auction?.currency}</span>
                        </div>
                    )}
                    <div className={'auction-item__prices-row'}>
                        <span>Начальная цена:</span>
                        <span>{auction?.price} {auction?.currency}</span>
                    </div>
                    {auction?.bids?.[0]?.price > 0 && (
                        <div className={`auction-item__prices-row ${myLastBid > 0 && myLastBid < auction?.bids?.[0]?.price ? 'color-red': ''}`}>
                            <span>Текущая ставка:</span>
                            <span>{auction?.bids?.[0]?.price} {auction?.currency}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__actions'}>
                    {!isFinish && (
                        <TDXButton
                            href={`/auctions/${auction.id}`}
                            size={'medium'}
                            bg={buttonData.color}
                        >{buttonData.text}</TDXButton>
                    )}
                    <div className={'auction-item__times'}>
                        {!isFinish && (<span>До конца аукциона:</span>)}
                        <span>{dateFinishText}</span>
                        {isFinish && myLastBid > 0 && (
                            <>
                                {isMyBid ? (
                                    <span className={'color-green'}>Ваша ставка победила!</span>
                                ): (
                                    <span className={'color-red'}>Ваша ставка превышена!</span>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ): (
        <div className={'auction-item'}>
            <div className={'auction-item__column'}>
                <Skeleton className={'auction-item__title'} height={'100%'} />
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__products'}>
                    <Skeleton className={'auction-item__products-title'} height={'100%'} />
                    <div className={'auction-item__products-list'}>
                        <Skeleton className={'auction-item__product'} height={'100%'} />
                        <Skeleton className={'auction-item__product'} height={'100%'} />
                    </div>
                </div>
            </div>
            <div className={'auction-item__column'}>
                <div className={'auction-item__prices'}>
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                    <Skeleton className={'auction-item__prices-row'} height={'100%'} />
                </div>
            </div>
            <div className={'auction-item__column'}>
                <Skeleton className={'auction-item__actions'} height={'100%'} />
            </div>
        </div>
    )
}

import {SvgIcon} from '@mui/material'

const PriceListIcon = (props: any) => (
    <SvgIcon {...props} sx={{width: '18px', height: '22px', color: '#D91F2A'}} viewBox="0 0 18 22">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H11C11.1989 0.25 11.3897 0.329018 11.5303 0.46967L11.5307 0.470043L17.5303 6.46967L17.5383 6.4778C17.6693 6.61283 17.75 6.797 17.75 7V19C17.75 19.7293 17.4603 20.4288 16.9445 20.9445C16.4288 21.4603 15.7293 21.75 15 21.75H3C2.27065 21.75 1.57118 21.4603 1.05546 20.9445C0.539731 20.4288 0.25 19.7293 0.25 19V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546ZM3 1.75H10.25V7C10.25 7.41421 10.5858 7.75 11 7.75H16.25V19C16.25 19.3315 16.1183 19.6495 15.8839 19.8839C15.6495 20.1183 15.3315 20.25 15 20.25H3C2.66848 20.25 2.35054 20.1183 2.11612 19.8839C1.8817 19.6495 1.75 19.3315 1.75 19V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75ZM11.75 2.81066L15.1893 6.25H11.75V2.81066ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H13C13.4142 12.75 13.75 12.4142 13.75 12C13.75 11.5858 13.4142 11.25 13 11.25H5ZM4.25 16C4.25 15.5858 4.58579 15.25 5 15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H5C4.58579 16.75 4.25 16.4142 4.25 16ZM5 7.25C4.58579 7.25 4.25 7.58579 4.25 8C4.25 8.41421 4.58579 8.75 5 8.75H7C7.41421 8.75 7.75 8.41421 7.75 8C7.75 7.58579 7.41421 7.25 7 7.25H5Z"
        />
    </SvgIcon>
)

export default PriceListIcon

import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PriceListFileTypes, PriceListsSchema, PriceListSchema} from './types'
import {getPriceList} from './services/getPriceList/getPriceList'
import {getPriceLists} from './services/getPriceLists/getPriceLists'

export interface PriceListState {
    list: Record<
        number,
        Record<PriceListFileTypes, PriceListSchema>
    >
    loading: boolean
}

const initialState: PriceListState = {
    list: {},
    loading: false,
}

export const priceListSlice = createSlice({
    name: 'priceList',
    initialState,
    reducers: {
        updateColumn: (
            state,
            action: PayloadAction<{id: number; key: number; value: string}>
        ) => {
            const {id, key, value} = action.payload

            Object.values(PriceListFileTypes).forEach(type => {
                state.list[id][type].settings.columnsOrder[key] = value
            })
        },
        updateColumns: (
            state,
            action: PayloadAction<{id: number; columns: Record<number, string>}>
        ) => {
            const {id, columns} = action.payload

            Object.values(PriceListFileTypes).forEach(type => {
                state.list[id][type].settings.columnsOrder = columns
            })
        },
        setShowReserve: (
            state,
            action: PayloadAction<{id: number; value: boolean}>
        ) => {
            const {id, value} = action.payload

            Object.values(PriceListFileTypes).forEach(type => {
                state.list[id][type].settings.show_reserve = value
            })
        },
        setPolling: (
            state,
            action: PayloadAction<{fileType: PriceListFileTypes; id: number; retryAfter: number}>
        ) => {
            state.list[action.payload.id][action.payload.fileType].polling =
                action.payload.retryAfter
        },
        reset: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(getPriceLists.pending, state => {
            state.loading = true
        })
        builder.addCase(getPriceLists.rejected, state => {
            state.loading = false
        })
        builder.addCase(
            getPriceLists.fulfilled,
            (state : PriceListState, action: PayloadAction<PriceListSchema[]>) => {
                const lists_tmp = {} as Record<
                    number,
                    Record<PriceListFileTypes, PriceListSchema>
                >

                Object.values(action.payload).forEach((payload : PriceListSchema) => {
                    const list_tmp = {} as Record<PriceListFileTypes, PriceListSchema>;

                    Object.values(PriceListFileTypes).forEach(type => {
                        list_tmp[type] = payload
                        list_tmp[type].loading = false
                        list_tmp[type].polling = false
                    })
                    lists_tmp[payload.id] = list_tmp
                })

                state.list = lists_tmp
                state.loading = false
            }
        )

        builder.addCase(getPriceList.pending, (state, action) => {
            state.list[action.meta.arg.id][action.meta.arg.fileType].loading = true
        })
        builder.addCase(getPriceList.fulfilled, (state, action) => {
            state.list[action.meta.arg.id][action.meta.arg.fileType].loading = false
            state.list[action.meta.arg.id][action.meta.arg.fileType].polling = false
        })
    },
})

export const {actions: priceListActions} = priceListSlice
export const {reducer: priceListReducer} = priceListSlice

import {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {ClickAwayListener, Input, InputAdornment, Paper, Popper} from '@mui/material'
import {
    moveSearchCheckedCategories,
    resetSearch,
    searchFor,
    toggleSearchCategory,
} from '../../../store/actions/GeneralActions'
import {SearchIcon} from '../../Icons'
import {TDXCheckbox, TDXSearchInputItem} from './index'
import TDXCircularProgress from '../Loaders/TDXCircularProgress'
import {namedRequestsInProgress} from '../../../store/RequestSelectors'
import {GeneralRequestsEnum} from '../../../api/general.service'
import { useAppSelector } from "../../../shared/hooks/hooks";
import {useWindowSize} from "../../useWindowSize";

interface TDXSearchInputInterface {
    targetMenu?: (e?: MouseEvent | TouchEvent, type?: string) => void,
}

const TDXSearchInput = (prop: TDXSearchInputInterface) => {
    const {targetMenu, ...props} = prop
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const _input = useRef()

    const categories = useAppSelector(state => state.General.quickSearch.results.categories)
    const products = useAppSelector(state => state.General.quickSearch.results.products)
    const selectedCategories = useAppSelector(state => state.General.quickSearch.selectedCategories)
    const isLoading = useAppSelector(state => namedRequestsInProgress(state, GeneralRequestsEnum.searchFor('quickSearch')))
    const isLoadingCatalog = useAppSelector(state => state.CatalogCategories.loading)
    const [windowWidth/*, windowHeight*/] = useWindowSize()

    const filteredProducts = () => {

        const result = products.filter(p => (
            (
                p['offers'] !== undefined &&
                p.offers !== null &&
                p.offers.length > 0 &&
                (
                    p['offers'].some(o => o.quantity>0 && !o.order && !o.is_reserve) ||
                    p['offers'].some(o => o.is_reserve) ||
                    p['offers'].some(o => o.order && !o.is_reserve)
                )
            ) ||
            (
                !p['offers'].length || p.offers.filter(
                    o => o.quantity > 0 || o.is_reserve
                ).length === 0
            )
        ))

        /*const productsWithOffers = products.filter(
            p => (
                p['offers'] !== undefined &&
                p.offers !== null &&
                p.offers.length > 0
            )
        )

        const inStockProducts = productsWithOffers.filter(p => p['offers'].some(o => o.quantity>0 && !o.order && !o.is_reserve))
        const inReserveProducts = productsWithOffers.filter(p => p['offers'].some(o => o.is_reserve))
        const inOrderProducts = productsWithOffers.filter(p => p['offers'].some(o => o.order && !o.is_reserve))
        const outOfStockProducts = products.filter(
            p => (
                !p['offers'].length || p.offers.filter(
                    o => o.quantity > 0 || o.is_reserve
                ).length === 0
            )
        )

        const result = [...new Set(
            [
                ...inStockProducts,
                ...inReserveProducts,
                ...inOrderProducts,
                ...outOfStockProducts,
            ]
        )];*/

        const selectedCategories_prepare: number[] = []

        if (selectedCategories.length > 0) {
            selectedCategories.forEach(category_id => {
                categories.forEach(category => {
                    if (category_id === category.id) {
                        selectedCategories_prepare.push(category_id)
                    }
                })
            })
        }

        return selectedCategories_prepare.length
            ? result.filter(p => selectedCategories_prepare.includes(p.category))
            : result
    }

    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [popper, setPopper] = useState({
        open: false,
        anchorEl: null,
    })

    const closePopper = () => {
        if (popper['open'] === true && targetMenu !== undefined) {
            targetMenu()
        }
        setPopper({open: false, anchorEl: null})
    }
    const openPopper = () => setPopper({open: true, anchorEl: _input.current})

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value
        value.length >= 2 ? !popper.open && openPopper() : closePopper()

        setValue(value)
    }

    const goToSearchPage = () => {
        dispatch(moveSearchCheckedCategories())

        closePopper()
        navigate(`/search?[q]=${value}`)
        if (targetMenu !== undefined) {
            targetMenu()
        }
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.keyCode !== 13 || value.length < 2) return

        goToSearchPage()
    }

    const selectCategory = (id: number) => (e: any) => {
        const checked = e.target.checked
        dispatch(toggleSearchCategory(id, checked, true))
    }

    useEffect(() => {
        if (loading)
        {
            setLoading(false)
        }
    }, [filteredProducts])

    useEffect(() => {
        if (value.length >= 2) {
            const controller = new AbortController()

            const trigger_search = async () => {
                await dispatch<any>(searchFor(value, controller.signal, 'quickSearch', true))
            }

            let timeout = null

            if (!(isLoading || loading))
            {
                trigger_search()
            }
            else
            {
                //const current_value = value

                setLoading(true)
                timeout = setTimeout(() => {
                    trigger_search()
                    //clearTimeout(timeout)
                }, 300)
            }

            return () => {
                if (timeout !== null)
                {
                    clearTimeout(timeout)
                }
                controller.abort()
            }
        }
    }, [value])

    useEffect(() => {
        if (!!value && !pathname.includes('search')) {
            dispatch(resetSearch(true))
            setValue('')
            closePopper()
        }
    }, [pathname])

    return (
        <ClickAwayListener onClickAway={closePopper}>
            <div className="tdx-search-input-container">
                <Input
                    ref={_input}
                    {...props}
                    className="tdx-search-input body-1"
                    placeholder="Введите код или название товара"
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleEnter}
                    onFocus={() => value.length >= 2 && openPopper()}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    disabled={isLoadingCatalog}
                />
                <Popper open={popper.open} id="search-popper" anchorEl={popper.anchorEl} placement="bottom">
                    <Paper className="tdx-search-input-paper" elevation={12}>
                        <div className="paper-container tdx-scrollbar">
                            {loading || isLoading ? (
                                <div className="center">
                                    <TDXCircularProgress size={40} />
                                </div>
                            ) : (
                                <>
                                    {!categories?.length && !products?.length ? (
                                        <h6 className="center">
                                            По вашему запросу ничего не найдено
                                        </h6>
                                    ) : (
                                        <>
                                            {windowWidth > 1023 ? (
                                                <div className="paper-search-group">
                                                    {categories.map(category => (
                                                        <div
                                                            className="paper-search-item category"
                                                            key={category.id}>
                                                            <TDXCheckbox
                                                                checked={selectedCategories.includes(
                                                                    category.id
                                                                )}
                                                                onChange={selectCategory(category.id)}
                                                            />
                                                            <Link
                                                                to={`/catalog/${category.id}`}
                                                                onClick={closePopper}
                                                                className="paper-search-category-link body-1">
                                                                {category.name}
                                                            </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            ):(
                                                <>
                                                    {filteredProducts().length > 10 && (
                                                        <div className="paper-search-item product">
                                                            <h6
                                                                className="more-variants"
                                                                onClick={goToSearchPage}>
                                                                Больше вариантов...
                                                            </h6>
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            {filteredProducts().slice(0, 10).map(product => (
                                                <TDXSearchInputItem
                                                    key={product.id}
                                                    product={product}
                                                    closePopper={closePopper}
                                                    searchQuery={value}
                                                />
                                            ))}
                                            {windowWidth > 1023 && filteredProducts().length > 10 && (
                                                <div className="paper-search-item product">
                                                    <h6
                                                        className="more-variants"
                                                        onClick={goToSearchPage}>
                                                        Больше вариантов...
                                                    </h6>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}


export default TDXSearchInput

import {
    SET_BID_AUCTION,
    SET_AUCTIONS,
    SET_AUCTION,
    AuctionState, AuctionSerializer,
} from '../actions/AuctionActions'

const initialState = {
    auctions: [],
    auction: {}
} as AuctionState

export const AuctionReducer = (state = initialState, action: any) : AuctionState => {
    switch (action.type) {
        case SET_AUCTIONS:
            return {
                ...state,
                auctions: action.data.auctions,
            }
        case SET_AUCTION: {
            const auction_data = action.data.auction as AuctionSerializer
            if (auction_data?.products.length == 0) {
                delete(auction_data.products)
            }
            return {
                ...state,
                auction: {
                    products: state.auction?.products,
                    ...auction_data,
                },
            }
        }
        case SET_BID_AUCTION:
            return {
                ...state,
                auction: {
                    ...state.auction,
                    products: action.data
                },
            }
        default:
            return state
    }
}

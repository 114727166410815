import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    Cart, CartSchema, CartProductSerializer,
    CartAddResponseSerializer, CartStockQuantity
} from './types'
import {prepareProductId} from '../../../utils/prepareProduct'
import {
    addToCart,
    createOrder,
    rearrangeOrder,
    getCart, removeFromCart, updateQuantityInCart, getStockQuantity
} from './asyncActions'

const emptyCart: Cart = {
    id: null,
    products: [] as CartProductSerializer[],
    currency: 'USD',
    total: 0,
}

const initialState: CartSchema = {
    data: emptyCart,
    isCartLoading: false,
    isCartStocksLoading: false,
    isOrderCreating: false,
    isOrderRearranging: false,
    isItemsRemoval: false,
}

const prepareProducts = (
    products : CartProductSerializer[]
) : CartProductSerializer[] => {
    return products.map(item => {
        let status = 0;

        if (item?.max_quantity === 0) {
            status = 0
        } else if (item?.quantity > item?.max_quantity) {
            status = 1
        } else {
            status = item?.order ? 2: 3
        }

        return {
            ...item,
            _id: prepareProductId(item),
            _status: status,
        } as CartProductSerializer
    })
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCart.pending, state => {
            state.isCartLoading = true
        })
        builder.addCase(getCart.rejected, state => {
            state.isCartLoading = false
        })
        builder.addCase(getCart.fulfilled, (state, action: PayloadAction<Cart>) => {
            state.isCartLoading = false
            if (action.payload?.products?.length>0) {
                action.payload.products = prepareProducts(action.payload.products)
            }
            state.data = action.payload
        })

        builder.addCase(addToCart.fulfilled, (
            state,
            action: PayloadAction<
                Cart | CartAddResponseSerializer
            >
        ) => {
            let cart : Cart = null;

            if ((action.payload as any)?.meta !== undefined) {
                cart = (action.payload as CartAddResponseSerializer).data
            } else {
                cart = (action.payload as Cart);
            }

            if (cart?.products?.length>0) {
                cart.products = prepareProducts(cart.products)
            }

            return {
                ...state,
                data: cart
            }
        })

        builder.addCase(updateQuantityInCart.fulfilled, (state, action: PayloadAction<Cart>) => {
            if (action.payload?.products?.length>0) {
                action.payload.products = prepareProducts(action.payload.products)
            }
            state.data = action.payload
        })

        builder.addCase(createOrder.pending, state => {
            state.isOrderCreating = true
        })
        builder.addCase(createOrder.rejected, state => {
            state.isOrderCreating = false
        })
        builder.addCase(createOrder.fulfilled, (
            state,
            action: PayloadAction<Cart>
        ) => {
            state.isOrderCreating = false
            if (action.payload?.products?.length>0) {
                action.payload.products = prepareProducts(action.payload.products)
            }
            state.data = action.payload
        })
        builder.addCase(rearrangeOrder.pending, state => {
            state.isOrderRearranging = true
        })
        builder.addCase(rearrangeOrder.rejected, state => {
            state.isOrderRearranging = false
        })
        builder.addCase(rearrangeOrder.fulfilled, (state,/* action: PayloadAction<Cart>*/) => {
            state.isOrderRearranging = false
        })
        builder.addCase(removeFromCart.pending, state => {
            state.isItemsRemoval = true
        })
        builder.addCase(removeFromCart.rejected, state => {
            state.isItemsRemoval = false
        })
        builder.addCase(removeFromCart.fulfilled, (state, action: PayloadAction<Cart>) => {
            state.isItemsRemoval = false
            if (action.payload?.products?.length>0) {
                action.payload.products = prepareProducts(action.payload.products)
            }
            state.data = action.payload
        })
        builder.addCase(getStockQuantity.pending, state => {
            state.isCartStocksLoading = true
        })
        builder.addCase(getStockQuantity.rejected, state => {
            state.isCartStocksLoading = false
        })
        builder.addCase(getStockQuantity.fulfilled, (state, action: PayloadAction<CartStockQuantity[]>) => {
            state.isCartStocksLoading = false
            state.data.products = state.data.products.map(p => {
                const pp = action.payload.filter(p_p =>
                    p_p.app_id == p.catalogapp_id &&
                    p_p.product_id == p.product_id &&
                    p_p.supplier_id == p.supplier_id
                )
                if (pp.length > 0) {
                    p.max_quantity = pp[0].quantity
                }
                return p
            })
        })
    },
})

export const {actions: cartActions} = cartSlice
export const {reducer: cartReducer} = cartSlice

import {/*memo, */useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {IconButton, Skeleton} from '@mui/material'
import AsideFilterItem from './AsideFilterItem'
import {TDXCheckbox} from '../Inputs'
import {createSearchParams, parseSearchParams} from './utils'
import {useAppDispatch, useAppSelector} from '../../../shared/hooks/hooks'
import {
    smartFiltersFromProducts,
    smartFiltersFromOrders,
    asideFiltersActions
} from '../../../store/slice/asideFiltersSlice/asideFiltersSlice'
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {TDXButton} from '../Buttons'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from "@mui/icons-material/Clear";
import FilterIcon from "../../Icons/FilterIcon"
import {ProductSerializer} from '../../../store/slice/catalogSlice/types'
import {OrderSerializer} from '../../../store/slice/cartSlice/types'
import {useWindowSize} from "../../useWindowSize";
import CatalogCategoriesList from "../../Catalog/CatalogCategoriesList";

const LoadingFilters = () => (
    <>
        <div className="filter">
            <p className="body-1">
                <Skeleton width="40%" />
            </p>
            <div className="tdx-range-slider-container">
                <div className="inputs-container">
                    <div className="range-input">
                        <span className="body-2">от</span>
                        <Skeleton sx={{borderRadius: '4px'}} width={100} variant="rectangular" />
                    </div>
                    <div className="range-input">
                        <span className="body-2">до</span>
                        <Skeleton sx={{borderRadius: '4px'}} width={100} variant="rectangular" />
                    </div>
                </div>
            </div>
        </div>
        <div className="filter">
            <p className="body-1">
                <Skeleton width="80%" />
            </p>
            <div className="tdx-filter-dictionary">
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <Skeleton
                    sx={{borderRadius: '4px', marginTop: '10px'}}
                    height={30}
                    variant="rectangular"
                />
            </div>
        </div>
        <div className="filter">
            <p className="body-1">
                <Skeleton width="80%" />
            </p>
            <div className="tdx-filter-range">
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <div className="inputs-container">
                    <Skeleton
                        sx={{borderRadius: '4px', marginRight: '5px'}}
                        width={100}
                        variant="rectangular"
                    />
                    <Skeleton sx={{borderRadius: '4px'}} width={100} variant="rectangular" />
                </div>
            </div>
        </div>
        <div className="filter">
            <p className="body-1">
                <Skeleton width="80%" />
            </p>
            <div className="tdx-filter-range">
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <TDXCheckbox label={<Skeleton width={100} />} />
                <div className="inputs-container">
                    <Skeleton
                        sx={{borderRadius: '4px', marginRight: '5px'}}
                        width={100}
                        variant="rectangular"
                    />
                    <Skeleton sx={{borderRadius: '4px'}} width={100} variant="rectangular" />
                </div>
            </div>
        </div>
    </>
)

export default function AsideFilters ({
    length, loading, title, rememberKey = null,
    throttle=1500, filter = {}, products = [], orders = [], type = ''
} : {
    length: number,
    loading: boolean,
    title: string,
    throttle?: number,
    filter?: any,
    rememberKey?: string | null
    products?: ProductSerializer[],
    orders?: OrderSerializer[],
    type?: string,
}) {
    const dispatch = useAppDispatch()
    const {categoryId} = useParams()
    const [windowWidth/*, windowHeight*/] = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams()
    const {data, values, allowUpdating, allowFiltered, smartFilters, isActive} =
        useAppSelector(state => state.AsideFilters)
    //const [resultFilters, setResultFilters] = useState([])
    const [isShowFilter, setShowFilter] = useState(true)

    const [isHideFilter, setHideFilter] = useState(false)
    const [isSelectedFilters, setIsSelectedFilters] = useState(false)

    const targetFilter = () => {
        setHideFilter(!isHideFilter)
    }

    useEffect(() => {
        const countFilters = Object.entries(isActive).filter(filter => filter[1]).length
        setIsSelectedFilters(countFilters > 0)
    },[isActive])

    useEffect(() => {
        if (allowUpdating && !rememberKey) {
            const prevParams = parseSearchParams(searchParams)
            dispatch(asideFiltersActions.updateFiltersValues({values: prevParams}))
            //console.log('Update filter value')
        }
    }, [allowUpdating])

    useEffect(() => {
        if (allowFiltered && !rememberKey) {
            const newParams = {...createSearchParams(values)}

            const timeout = setTimeout(() => {
                setSearchParams({...newParams})
                //console.log('Изменение фильтра, подгружаем новые фильтры', categoryId)
                clearTimeout(timeout)
            }, throttle)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [values, allowFiltered])

    const resetPage = () => {
        const params = parseSearchParams(searchParams)
        const newParams = {...createSearchParams(params)}
        setSearchParams(newParams)
    }

    useEffect(() => {
        if (!allowFiltered || !values || !rememberKey) return

        //Колхозим: Запрещаем сохранять дату в фильтрах.
        const values_tmp = Object.fromEntries(Object.entries(values).filter(value => value[0] !== 'date'))

        const params = createSearchParams(values_tmp)

        const timeout = setTimeout(() => {
            resetPage()
            localStorage.setItem(rememberKey, JSON.stringify(params))
            clearTimeout(timeout)
        }, throttle)

        return () => {
            clearTimeout(timeout)
        }
    }, [values, allowFiltered])

    useEffect(() => {
        if (data !== null && window.location.href.match(/catalog/) !== null)
        {
            smartFiltersFromProducts({
                products,
                dispatch,
                data,
            });
        }
    }, [products])

    useEffect(() => {
        if (data !== null && orders.length > 0 && window.location.href.match(/user\/orders/) !== null)
        {
            smartFiltersFromOrders({
                orders,
                dispatch
            });
        }
    }, [orders])

    const resetFilter = () => {
        //console.log('Reset filter');
        if (rememberKey) {
            localStorage.setItem(rememberKey, JSON.stringify([]))
        } else {
            setSearchParams({})
        }
        dispatch(asideFiltersActions.resetFilter())
    }

    const toggleFilter = () => {
        if (windowWidth > 768) {
            setShowFilter(!isShowFilter)
        }
    }

    return (
        <div className={`tdx-aside-filters-container ${isShowFilter ? 'is-show': ''}`}>
            <div className="mobileFilterRender">
                <TDXButton
                    size="large"
                    bg={"transparent"}
                    className="mobileFilterBtn"
                    onClick = {targetFilter}>
                    <p>Фильтр</p>
                     <ArrowDropDownIcon className={`${isHideFilter ? 'is-active-arrow' : ''}`}/>
                </TDXButton>
            </div>
            <div className={`${isHideFilter ? 'is-active-filter-bar' : ''}`}>
                <div className="head-filter">
                    <IconButton className="icon-button filter-icon" onClick={toggleFilter}>
                        <FilterIcon sx={{margin: '8px 16px 6px', verticalAlign: 'middle'}} />
                    </IconButton>

                    {isSelectedFilters ? (
                        <TDXButton
                            size="small"
                            bg={"transparent"}
                            className="reset-filter"
                            onClick = {resetFilter}
                        >
                            <ClearIcon className="icon clear-icon" />
                            <p>Сбросить все</p>
                        </TDXButton>
                    ): ''}
                </div>
                <div className="filters-wrapper">
                    {type === 'catalog' && (
                        <div className={'filter'}>
                            <CatalogCategoriesList only_current={true} label='Категория' />
                        </div>
                    )}

                    {loading ? (
                        <LoadingFilters />
                    ) : (
                        data?.map(f => (
                            <AsideFilterItem
                                key={f.id}
                                filter={f}
                                smartFilter={smartFilters?.[f.id]}
                                rememberKey={rememberKey}
                            />
                        ))
                    )}
                </div>
                <div className="total-goods body-1">
                    <div className="total">
                        <span>Показано</span>
                        {
                            loading
                            ? <Skeleton sx={{margin: '0 10px'}} width={50} />
                            : <span>{length}</span>
                        }
                        <span>{title}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

//export default AsideFilters

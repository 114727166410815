import {SvgIcon} from "@mui/material";

const CSVMenu = (props: any) => (
    <SvgIcon {...props} sx={{width: '30px', height: '30px', fill: 'none'}} viewBox="-2 0 30 30">
        <path d="M3 15.5H8C9.38071 15.5 10.5 16.6193 10.5 18V23C10.5 24.3807 9.38071 25.5 8 25.5H3C1.61929 25.5 0.5 24.3807 0.5 23V18C0.5 16.6193 1.61929 15.5 3 15.5Z" fill="#0E0E0E" stroke="#0E0E0E" strokeMiterlimit="2.3662" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15 15.5H20C21.3807 15.5 22.5 16.6193 22.5 18V23C22.5 24.3807 21.3807 25.5 20 25.5H15C13.6193 25.5 12.5 24.3807 12.5 23V18C12.5 16.6193 13.6193 15.5 15 15.5Z" fill="#0E0E0E" stroke="#0E0E0E" strokeMiterlimit="2.3662" strokeLinecap="round"strokeLinejoin="round"/>
<path d="M3 3H8C9.38071 3 10.5 4.11929 10.5 5.5V10.5C10.5 11.8807 9.38071 13 8 13H3C1.61929 13 0.5 11.8807 0.5 10.5V5.5C0.5 4.11929 1.61929 3 3 3Z" fill="#0E0E0E" stroke="#0E0E0E" strokeMiterlimit="2.3662" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 9.81818V3.68182C12.5 1.94153 13.9683 0.5 15.8182 0.5H22.1818C24.0317 0.5 25.5 1.94153 25.5 3.68182V9.81818C25.5 11.5585 24.0317 13 22.1818 13H15.8182C13.9683 13 12.5 11.5585 12.5 9.81818Z" fill="#B23E29" stroke="#B23E29" strokeMiterlimit="2.3662" strokeLinecap="round" strokeLinejoin="round"/>

    </SvgIcon>
)

export default CSVMenu

import {useState, ReactNode} from 'react'
import {Button} from '@mui/material'
import {copyTextToClipboard} from "../../../shared/utils/copyTextToClipboard/copyTextToClipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from '@mui/material/Tooltip';

export default function TDXCopyButton (props : {
        id ?: string,
        label : string,
        content : any,
        placement ?:
            | "top" | "right" | "bottom" | "left"
            | "top-start" | "top-end" | "bottom-start"
            | "bottom-end" | "right-start" | "right-end"
            | "left-start" | "left-end",
        endIcon ?: ReactNode,
        className ?: string
    }) {
    const {
        id = undefined,
        label,
        content,
        placement = 'top',
        endIcon = <ContentCopyIcon />,
        className= ''
    } = props

    const [open, setOpen] = useState(false)

    const onCopyText = (text : string) => {
        copyTextToClipboard(text).then(() => {
            setOpen(true);
            setTimeout(() => setOpen(false), 2000)
        })
    }

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -5],
                            },
                        },
                    ],
                },
            }}
            placement={placement}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Скопировано"
        >
            <Button
                id={id}
                className={`tdx-button-copy ${className ?? ''}`}
                endIcon={endIcon}
                disableElevation
                onClick={() => onCopyText(content)}>
                <span className={'label'}>{label}</span>
            </Button>
        </Tooltip>
    )
}

export function getNgram (str: string, min: number, max: number = 0) {
    const result = []
    const str_list = str.split('')
    const str_len = str_list.length
    const max_len = max === 0 || str_len < max ? str_len : max

    for (let l = max_len; l >= min; l--) {
        result.push(str_list.slice(0, l).join(''))
    }

    return result
}

import {createAsyncThunk} from '@reduxjs/toolkit'
import CatalogService from '../../../../../api/catalog.service'
import {enqueueSnackbar} from 'notistack'
import {getCategories} from '../getCategories/getCategories'
import {CatalogStatus} from '../../types'

export const resetCatalogCache = createAsyncThunk<
    CatalogStatus, {need_message?: boolean | null, need_reset?: boolean | null}
>(
    'catalogCategories/resetCatalogCache',
    async (arg, thunkAPI) => {
        let need_message = (
            arg.need_message !== undefined ? arg.need_message : null
        );

        if (need_message === null)
        {
            if (arg.need_reset === true)
            {
                need_message = true;
            }
            else
            {
                need_message = false;
            }
        }

        if (need_message)
        {
            enqueueSnackbar('Началось обновление цен и наличия. Пожалуйста, ожидайте', {
                variant: 'success',
            })
        }

        try {
            const status_detailed = await CatalogService.resetProductsCache(arg || {})
            if (arg.need_reset === true)
            {
                await thunkAPI.dispatch(getCategories())
            }

            if (need_message)
            {
                enqueueSnackbar('Цены и наличие успешно обновлены. Приятной работы', {
                    variant: 'success',
                })
            }

            return status_detailed
        } catch (e) {
            console.error(e)
            return thunkAPI.rejectWithValue('error while reset cache')
        }
    }
)

import {useAppSelector} from "../../shared/hooks/hooks";
import {useState, useMemo, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {
    TDXNavLink,
} from '../UI'
import { routeGet, } from '../../shared/config/routerConfig/routerConfig';
import TDXBufferModal from "./TDXBufferModal";

export default function TDXBufferLink () {
    const {region, isSpecial} = useAppSelector(s => s['Profile'].profile)
    const location = useLocation();
    const bufferIsActive = useMemo<boolean>(() => {
        return routeGet('/user/buffer', location.pathname, location.pathname)
    }, [location])
    const [open, setOpen] = useState<HTMLDivElement>(null)

    const toggleMenu = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            setOpen(null)
        } else {
            setOpen(e?.currentTarget)
        }
    }, [open])

    return (
        bufferIsActive ? (<TDXNavLink to="/user/buffer" title={`${region === 'ru' ? 'Корзина': 'Буфер'}`} />)
        : (<div className="link">
            <div className="tdx-nav-link tdx-buffer-modal" onClick={toggleMenu}>
                <h5>{region === 'ru' ? 'Корзина': 'Буфер'}</h5>
                <TDXBufferModal open={open} />
            </div>
        </div>)
    )
}

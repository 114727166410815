import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    AsideFiltersState, RangeValue, DictValue, SmartFilters,
    FilterData, FilterDataData, SmartFiltersData,
} from './types'
import {createFiltersValues, typeToStateValue} from './utils'
import {
    ProductSerializer,
    ProductPropertySerializer, ProductOfferSerializer
} from '../catalogSlice/types'
import {OrderSerializer} from "../cartSlice/types";

const initialState: AsideFiltersState = {
    data: null,
    values: null,
    isActive: {},
    smartFilters: {},
    allowFiltered: false,
    allowUpdating: false,
    disableEmptyValues: true,
}

/*
export interface AsideFiltersOptions {
    fields?: {
        id: string,
        options: {
            id: string,
            total: number,
            available: number,
            is_active: boolean
        }[]
    }[]
}
*/

export function filterIsActive (
    state : AsideFiltersState,
    key : string,
    value : RangeValue | DictValue | null
)
{
    if (value?.from !== undefined)
    {
        const value2 = value as RangeValue;

        let options = {
            from: null,
            to: null
        } as RangeValue

        state.data.forEach(filter => {
            if (filter.id == key) {
                options = typeToStateValue(filter.type, filter.data)
            }
        })

        return JSON.stringify(value2) !== JSON.stringify(options);
    }
    else if (value)
    {
        const value2 = value as DictValue;
        return Object.entries(value2).filter(([k, v]) => v).length > 0;
    }
    else
    {
        return false;
    }
}

export function updateIsActive (state: AsideFiltersState)
{
    if (state.values)
    {
        state.isActive = Object.fromEntries(
            Object.entries(state.values).map(([k, v]) => [k, filterIsActive(state, k, v)])
        );
    }
    else
    {
        state.isActive = {};
    }
}

export function filterIsIsset ({state, filter_id, value_id, value_name} : {
    state : FilterData[],
    filter_id : string,
    value_id?: string,
    value_name?: string,
})
{
    let options : (RangeValue | DictValue | FilterDataData["options"]) = []

    state.forEach(filter => {
        if (filter.id == filter_id) {
            if(filter.type === 'dictionary') {
                options = filter.data.options
            } else {
                options = typeToStateValue(filter.type, filter.data)
            }
        }
    })

    return Object.entries(options).filter(([k, v]) => (
        value_id !== undefined && v.id == value_id ||
        value_name !== undefined && v.name == value_name
    )).length > 0
}

export const asideFiltersSlice = createSlice({
    name: 'asideFilters',
    initialState,
    reducers: {
        setFiltersValues: (state, action) => {
            state.data = action.payload.filters
            state.values = {
                ...createFiltersValues(action.payload.filters),
                ...(action.payload.savedValues ?? {}),
            }
            updateIsActive(state);
            state.allowUpdating = true
            state.allowFiltered = false
        },
        updateFiltersValues: (state, action) => {
            state.values = {
                ...state.values,
                ...action.payload.values,
            }
            updateIsActive(state);
            state.allowFiltered = true
        },
        updateSmartFilters: (state, action: PayloadAction<SmartFiltersData>) => {
            state.smartFilters = action.payload.smartFilters;
            state.disableEmptyValues = action.payload.disableEmptyValues;
        },
        /*
        updateOptions: (state, action: PayloadAction<AsideFiltersOptions>) => {
            action.payload?.fields.forEach((o2) => {
                const field = state?.data.find((o) => o.id === o2.id);
                if (!field)
                {
                    return
                }

                o2.options.forEach((o) => {
                    const present_option = field.data.options.find((o2) => o2.id === o.id);

                    if (present_option)
                    {
                        present_option.is_active = o.is_active;
                        present_option.total = o.total;
                        present_option.available = o.available;
                    }
                });
            })
        },
        */
        updateFilterValue: (state, action) => {
            state.values = {
                ...state.values,
                [action.payload.id]: action.payload.newValue,
            }
            updateIsActive(state);
            state.allowFiltered = true
        },
        resetFilterValues: () => initialState,
        resetFilter: (state) => {
            state.values = createFiltersValues(state.data)
            state.allowUpdating = true
            updateIsActive(state);
        }
    },
})

export const {actions: asideFiltersActions} = asideFiltersSlice
export const {reducer: asideFiltersReducer} = asideFiltersSlice

export function smartFiltersFromProducts (
    {products, dispatch, data} : {
        products : ProductSerializer[],
        dispatch : (stateAction: any) => void,
        data : FilterData[] | null
    }
)
{
    const smartFilters : Record<string, Map<string, number>> = {};

    {
        const vendors = new Map<string,number>();
        const price = new Map<string,number>();

        products.forEach((p) => {
            const vendor_name =
                data.find((o) => o.id === 'vendor')
                ?.data?.options?.find(
                    (o) => o.name === p?.vendor?.name
                )?.name;

            if (vendor_name)
            {
                if (!vendors.has(vendor_name))
                {
                    vendors.set(vendor_name, 0);
                }
                vendors.set(vendor_name, vendors.get(vendor_name) + 1);
            }

            const properties2 = p.properties as ProductPropertySerializer[];
            properties2.forEach((prop) => {
                const prop_id = `fltr${prop.property_id}`;
                const prop_value_name = (
                    data.find((o) => o.id === prop_id)
                    ?.data?.options?.find(
                        (o) => o.name === prop?.value
                    )?.name
                );

                if (prop_value_name)
                {
                    if (smartFilters?.[prop_id] === undefined)
                    {
                        smartFilters[prop_id] = new Map<string, number>();
                    }

                    if (!smartFilters[prop_id].has(prop_value_name))
                    {
                        smartFilters[prop_id].set(prop_value_name, 0);
                    }
                    smartFilters[prop_id].set(
                        prop_value_name,
                        smartFilters[prop_id].get(prop_value_name) + 1
                    );
                }
            })

            const offers = p.offers as ProductOfferSerializer[]

            offers.forEach(o => {
                if (!price.get('min') || price.get('min') > o.price) {
                    price.set('min', o.price)
                }

                if (!price.get('max') || price.get('max') < o.price) {
                    price.set('max', o.price)
                }
            })
        });

        smartFilters['vendor'] = vendors;
        smartFilters['price'] = price;
    }

    dispatch(asideFiltersActions.updateSmartFilters(
        {
            smartFilters: Object.fromEntries(Object.entries(smartFilters).map(([k, v]) => {
                return [
                    k,
                    Object.fromEntries(v.entries())
                ]
            })),
            disableEmptyValues: true
        }
    ));
}

export function smartFiltersFromOrders (
    {orders, dispatch} : {
        orders : OrderSerializer[],
        dispatch : (stateAction: any) => void
    }
)
{
    const smartFilters : Record<string, Map<number, number>> = {};

    {
        orders.forEach((o) => {
            // Компания
            if (o.company.id !== null) {
                if (smartFilters?.organisation === undefined)
                {
                    smartFilters.organisation = new Map<number, number>();
                }
                if (!smartFilters.organisation.has(o.company.id))
                {
                    smartFilters.organisation.set(o.company.id, 0);
                }
                smartFilters.organisation.set(
                    o.company.id,
                    smartFilters.organisation.get(o.company.id) + 1
                );
            }

            // Статус
            if (o.status.id !== null) {
                if (smartFilters?.status === undefined)
                {
                    smartFilters.status = new Map<number, number>();
                }
                if (!smartFilters.status.has(o.status.id))
                {
                    smartFilters.status.set(o.status.id, 0);
                }
                smartFilters.status.set(
                    o.status.id,
                    smartFilters.status.get(o.status.id) + 1
                );
            }
        });
    }

    dispatch(asideFiltersActions.updateSmartFilters(
        {
            smartFilters: Object.fromEntries(Object.entries(smartFilters).map(([k, v]) => {
                return [
                    k,
                    Object.fromEntries(v.entries())
                ]
            })),
            disableEmptyValues: false
        }
    ));
}

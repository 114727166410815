import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import {Link} from 'react-router-dom'
import {ClickAwayListener, IconButton, List, ListItem, Paper, Popper} from "@mui/material";
import {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {InfoOutlined, InfoRounded} from "@mui/icons-material";
import {ContractSerializer, getProfileContracts, UserState} from "../../../store/actions/ProfileActions";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";
import {separateThousands} from '../../../utils/separateThousands'

export default function TDXProfileBalance () {
    const dispatch = useAppDispatch()

    const { profile, contracts } = useAppSelector<UserState>(state => state.Profile)
    const {isSpecial} = useAppSelector(state => state.Profile.profile)
    const [isStartedContracts, setStartedContracts] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [notifyList, setNotifyList] = useState<Record<string, string>>({})
    const closeMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const openMenu = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])

    const isErrorBalance = (item: ContractSerializer) => {
        if (item.balance !== 0 && item.balance <= 0-item.sum_credit) return true
        return false
    }

    const isErrorArreasPayment = (item: ContractSerializer) => {
        if (item.arreas > 0) return true
        return false
    }

    if (profile?.id > 0 && !isStartedContracts) {
        setStartedContracts(true)
    }

    useEffect(() => {
        if (isStartedContracts && profile.id > 0) {
            dispatch<any>(getProfileContracts())
        }
    }, [isStartedContracts])

    useEffect(() => {
        if (contracts.length > 0) {
            const notify: Record<string, string> = {}
            contracts.forEach((contract) => {
                if (isErrorBalance(contract)) {
                    notify[contract.id+'_balance'] = `${contract.organisation} - достигнут лимит кредита по договору ${contract.number} от ${new Date(contract.date).toLocaleDateString('ru-RU')}, произведите, пожалуйста, оплату`
                }
                if (isErrorArreasPayment(contract)) {
                    notify[contract.id+'_arreas-payment'] = `К оплате сегодня ${separateThousands(contract.debt)} ${!isSpecial ? 'BYN': ''}, включая просроченный платеж ${separateThousands(contract.arreas)} ${!isSpecial ? 'BYN': ''}, произведите, пожалуйста, оплату`
                }
            })
            setNotifyList({...notify })
        }
    }, [isStartedContracts, contracts])

    return (
        <div>
            <Link to="/user/balance" className="tdx-profile-balance">
                <AccountBalanceWalletIcon sx={{color: 'rgba(0, 0, 0, 0.2)'}} />
                <h5>Баланс</h5>
                {Object.keys(notifyList).length > 0 ? (
                    <>
                        <IconButton className="balance-notify icon-button info-rounded-icon" onMouseEnter={openMenu}>
                            <InfoRounded className="icon info-rounded-icon" />
                        </IconButton>
                        <Popper
                            id="balance-popper"
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            placement="bottom-start">
                            <ClickAwayListener onClickAway={closeMenu}>
                                <Paper elevation={3}>
                                    <p className="popper-title body-1 light">Внимание!</p>
                                    <List>
                                        {Object.entries(notifyList).map(notify => {
                                            return (
                                                <ListItem key={notify[0]}>
                                                    <InfoOutlined className="icon info-outlined-icon"/>
                                                    <span className="body-1 light">{notify[1]}</span>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                    <Link to="/user/balance" className="popper-link">Подробнее</Link>
                                </Paper>
                            </ClickAwayListener>
                        </Popper>
                    </>
                ) : (<></>)}
            </Link>
        </div>
    )
}

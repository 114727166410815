import {useNavigate} from 'react-router-dom'
import {TDXButton} from './UI'
import {useAppSelector} from "../shared/hooks/hooks";
import {settings_as_str} from "../store/actions/GeneralActions";
import MaintenanceIcon from "../components/Icons/MaintenanceIcon"

export const Maintenance = () => {
    const navigate = useNavigate()
    const {settings} = useAppSelector(state => state.General.common)

    return (
        <div className="wrapper wrapper-error" style={{padding: '20px 0'}}>
            <div className="error-container">
                <div className='image'>
                    <MaintenanceIcon />
                </div>
                {
                    settings?.maintenance?.text?.value &&
                    <h3 dangerouslySetInnerHTML={{
                        __html: settings_as_str(settings, 'maintenance', 'text')
                    }}></h3>
                }
                <div className="buttons">
                    <TDXButton
                        size="large"
                        bg="red"
                        onClick={() => {
                            navigate(0)
                        }}>
                        Обновить страницу
                    </TDXButton>
                </div>
            </div>
        </div>
    )
}

import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Skeleton} from '@mui/material'
import {useAppSelector} from '../../shared/hooks/hooks'
import {Category} from "../../store/slice/catalogCategoriesSlice/types";

const HeaderCatalogCategoriesList = (
    {
        only_current = false,
        label = 'Пусто'
    }: {
        only_current?: boolean,
        label?: string
    }
) => {
    const navigate = useNavigate()
    const categories = useAppSelector<Category[] | null>(state => state.CatalogCategories.categories)
    const isLoading = useAppSelector<boolean>(state => state.CatalogCategories.loading)
    const currentCategory = useAppSelector<Category | null>(state => state.Catalog.category)
    const [toggledCategory, setToggledCategory] = useState<string[]>([])
    const [rootCategory, setRootCategory] = useState<number | null>(null)
    const [needUpdate, setNeedUpdate] = useState<boolean>(true)

    const prepareId = (ids: number[]) => {
        return ids.join('_')
    }

    const toggleCategory = (category_ids: number[]) => {
        let toggledCategory_tmp = toggledCategory
        let current_id = prepareId(category_ids)

        if (toggledCategory_tmp.includes(current_id)) {
            toggledCategory_tmp = toggledCategory_tmp.filter(f => !f.includes(current_id))
        } else {
            for (let i=category_ids.length; i>0; i--) {
                let id = prepareId(category_ids.slice(0, i))
                if (!toggledCategory_tmp.includes(id)) {
                    toggledCategory_tmp.push(id)
                }
            }
        }

        setToggledCategory([...toggledCategory_tmp])
    }

    const setActiveCategory = (category_list: Category[], parent_list: number[] = []) => {
        category_list.some(c => {
            if (c.id === currentCategory.id) {
                setRootCategory(parent_list[0])
                toggleCategory([...parent_list, c.id])
            }

            if (c?.subcategories) {
                setActiveCategory(c?.subcategories, [...parent_list, c.id])
            }
        })
    }

    const getClasses = (id: string, category: Category) => {
        let classes = []

        if (toggledCategory.includes(id)) {
            classes.push('active')
        }

        if (currentCategory.id === category.id) {
            classes.push('current')
        }

        if (category?.subcategories?.length > 0) {
            classes.push('has-dropdown')
        }

        return classes.join(' ')
    }

    const filterCategories = () => {
        if (only_current) {
            return categories.filter(c => c.id == rootCategory)
        }

        return categories;
    }

    const templateCategory = (categories: Category[], parent_list: number[] = []) => {
        return (
            <div className="catalog-categories__list-wrapper">
                <ul className="catalog-categories__list">
                    {categories.map(c => (
                        <li
                            key={c.id}
                            className={getClasses(prepareId([...parent_list, c.id]), c)}
                        >
                            {c?.subcategories ? (
                                <button
                                className={`catalog-categories__item body-2`}
                                onClick={() => toggleCategory([...parent_list, c.id])}
                                >
                                {c.name}
                                </button>
                            ) : (
                                <button
                                    className={`catalog-categories__item body-2`}
                                    onClick={() => navigate('/catalog/'+c.id)}>
                                    {c.name}
                                </button>
                            )}

                            {c?.subcategories && templateCategory(c?.subcategories, [...parent_list, c.id])}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    useEffect(() => {
        if (currentCategory?.id) {
            setToggledCategory([])
            setNeedUpdate(true)
        }
    }, [currentCategory])

    useEffect(() => {
        if (currentCategory?.id && categories.length > 0 && toggledCategory.length === 0 && needUpdate) {
            setActiveCategory(categories)
            setNeedUpdate(false)
        }
    }, [currentCategory?.id, needUpdate, toggledCategory])

    return (
        <div className="catalog-categories">
            {isLoading || !currentCategory ? (
                <>
                    <Skeleton className={`body-1`} variant="rectangular" width="60%" height={15} />
                    <Skeleton className={`catalog-categories__list`} variant="rectangular" width="100%" height={25} />
                </>
            ) : (
                <>
                    {label.length > 0 && (<div className="body-1">{label}</div>)}
                    <ul className={'catalog-categories__list'}>
                        {filterCategories().map(category => (
                            <li
                                key={category.id}
                                className={getClasses(prepareId([category.id]), category)}
                            >
                                <button
                                    className={`catalog-categories__item body-2`}
                                    onClick={() => toggleCategory([category.id])}
                                >
                                    {category.name}
                                </button>

                                {category?.subcategories && templateCategory(category?.subcategories, [category.id])}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    )
}

export default HeaderCatalogCategoriesList

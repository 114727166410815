import {CircularProgress} from '@mui/material'

const TDXCircularProgress = ({size = 80, thickness = 2.5, center = true, ...other}) => (
    <>
        {center ? (
            <span className="tdx-circular-center">
                <CircularProgress
                    className="tdx-circular-progress"
                    size={size}
                    thickness={thickness}
                    {...other}
                />
            </span>
        ) : (
            <CircularProgress
                className="tdx-circular-progress"
                size={size}
                thickness={thickness}
                {...other}
            />
        )}
    </>
)

export default TDXCircularProgress

import {requestHelper} from '../../helpers/requestHelper'
import ManagerService, {ManagerRequestsEnum} from '../../api/manager.service'
import {Partner} from "../slice/authSlice/types";

export const MANAGER_SET_USERS = 'MANAGER_SET_USERS'
export const MANAGER_ADD_NEW_USER = 'MANAGER_ADD_NEW_USER'
export const MANAGER_UPDATE_USER = 'MANAGER_UPDATE_USER'

export const MANAGER_SET_PARTNERS = 'MANAGER_SET_PARTNERS'
export const MANAGER_ADD_NEW_PARTNER = 'MANAGER_ADD_NEW_PARTNER'
export const MANAGER_UPDATE_PARTNER = 'MANAGER_UPDATE_PARTNER'

export const MANAGER_SET_USERS_1C = 'MANAGER_SET_USERS_1C'

export const getUsersList = () => (dispatch: any) => {
    requestHelper(dispatch, ManagerRequestsEnum.getUsersList, async () => {
        const users = await ManagerService.getUsersList()

        dispatch({
            type: MANAGER_SET_USERS,
            data: {users},
        })
    })
}

export const addNewUser = (user: any) => ({
    type: MANAGER_ADD_NEW_USER,
    data: {user},
})

export const updateUser = (user: any) => ({
    type: MANAGER_UPDATE_USER,
    data: {user},
})

export const getPartnersList = () => (dispatch: any) => {
    requestHelper(dispatch, ManagerRequestsEnum.getPartnersList, async () => {
        const partners = await ManagerService.getPartnersList()

        dispatch({
            type: MANAGER_SET_PARTNERS,
            data: {partners},
        })
    })
}

export const addNewPartner = (partner: Partner) => ({
    type: MANAGER_ADD_NEW_PARTNER,
    data: {partner},
})

export const updatePartner = (partner: Partner) => ({
    type: MANAGER_UPDATE_PARTNER,
    data: {partner},
})

export const getUsers1CList = () => (dispatch: any) => {
    requestHelper(dispatch, ManagerRequestsEnum.getUsers1CList, async () => {
        const users1C = await ManagerService.getUsers1CList()

        dispatch({
            type: MANAGER_SET_USERS_1C,
            data: {users1C},
        })
    })
}

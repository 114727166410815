import {SvgIcon} from '@mui/material'

const FilterIcon = (props: any) => (
    <SvgIcon {...props} viewBox="0 0 60 58">
        <g filter="url(#filter0_d_2_15)">
          <path
            d="M53.3813 26.5C54.2098 26.5 54.8813 25.8284 54.8813 25C54.8813 24.1716 54.2098 23.5 53.3813 23.5V26.5ZM4.90755 23.9393C4.32177 24.5251 4.32177 25.4749 4.90755 26.0607L14.4535 35.6066C15.0393 36.1924 15.989 36.1924 16.5748 35.6066C17.1606 35.0208 17.1606 34.0711 16.5748 33.4853L8.08953 25L16.5748 16.5147C17.1606 15.9289 17.1606 14.9792 16.5748 14.3934C15.989 13.8076 15.0393 13.8076 14.4535 14.3934L4.90755 23.9393ZM53.3813 23.5L5.96821 23.5V26.5L53.3813 26.5V23.5Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.8555 41.6034H48.9455C48.2393 38.5159 45.3988 36.2069 42.0022 36.2069C38.6056 36.2069 35.7651 38.5159 35.0589 41.6034H6.44232C5.61389 41.6034 4.94232 42.275 4.94232 43.1034C4.94232 43.9319 5.61389 44.6034 6.44232 44.6034H35.0589C35.7651 47.691 38.6056 50 42.0022 50C45.3988 50 48.2393 47.691 48.9455 44.6034H53.8555C54.6839 44.6034 55.3555 43.9319 55.3555 43.1034C55.3555 42.275 54.6839 41.6034 53.8555 41.6034ZM42.0021 48.2759C44.8825 48.2759 47.2176 45.9601 47.2176 43.1034C47.2176 40.2468 44.8825 37.931 42.0021 37.931C39.1217 37.931 36.7867 40.2468 36.7867 43.1034C36.7867 45.9601 39.1217 48.2759 42.0021 48.2759Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.8555 5.39655H48.9455C48.2393 2.30896 45.3988 0 42.0022 0C38.6056 0 35.7651 2.30896 35.0589 5.39655H6.44232C5.61389 5.39655 4.94232 6.06812 4.94232 6.89655C4.94232 7.72498 5.61389 8.39655 6.44232 8.39655H35.0589C35.7651 11.4841 38.6056 13.7931 42.0022 13.7931C45.3988 13.7931 48.2393 11.4841 48.9455 8.39655H53.8555C54.6839 8.39655 55.3555 7.72498 55.3555 6.89655C55.3555 6.06812 54.6839 5.39655 53.8555 5.39655ZM42.0021 12.069C44.8825 12.069 47.2176 9.7532 47.2176 6.89655C47.2176 4.03991 44.8825 1.72414 42.0021 1.72414C39.1217 1.72414 36.7867 4.03991 36.7867 6.89655C36.7867 9.7532 39.1217 12.069 42.0021 12.069Z"
            fill="black"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2_15"
            x={0.468201}
            y={0}
            width={58.8873}
            height={58}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2_15"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2_15"
              result="shape"
            />
          </filter>
        </defs>
    </SvgIcon>
)

export default FilterIcon

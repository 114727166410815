export enum PriceListFileTypes {
    csv = 'csv',
    xlsx = 'xlsx',
}

/*
export const PriceListFiles = [
    {
        'title': 'Прайс-лист на заказные позиции в формате csv (для заказа обратитесь к менеджеру)',
        'type': 'csv',
        //'url': 'https://b2b.tradeicsbel.by/upload/feed/DSM_BN_Zakaz.csv'
        'url': 'https://b2b.tradeicsbel.by/prices/feed.php?f=SDM_WK_Z7K5T]ZF2'
    },
    {
        'title': 'Прайс-лист на заказные позиции в формате xlsx (для заказа обратитесь к менеджеру)',
        'type': 'xlsx',
        //'url': 'https://b2b.tradeicsbel.by/upload/feed/DSM_BN_Zakaz.xlsx'
        'url': 'https://b2b.tradeicsbel.by/prices/feed.php?f=SDM_WK_Z7K5T(ZL4k'
    },
]
*/

export interface PriceListSettings {
    columnsOrder: Record<number, string>,
    show_reserve: boolean
}

export interface PriceListSchema {
    id: number,
    code: number,
    organisation_name: string,
    priceListHash: string,
    settings: PriceListSettings,
    polling: number | boolean
    loading: boolean
}

export interface PriceListsSchema {
    //list: {},
    list: unknown,
    loading: false,
}

export interface PriceListsCustomSchema {
    title: string,
    type: PriceListFileTypes,
    url: string,
}
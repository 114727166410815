import {memo, useState} from 'react'
import {Fade, Popper, Paper, Radio, RadioGroup, IconButton, FormControlLabel} from '@mui/material'
import {ClickAwayListener} from '@mui/base'
import {TDXCheckbox} from '../Inputs'
import {SettingsIcon} from '../../Icons'

const TableSetting = ({title, type = 'checkbox', value = '', options = [], onChange}:{
    title?: string,
    type?: string,
    value?: string | number,
    options: Record<string, any>[],
    onChange: (val?: any) => () => void,
}) => (
    <div className="setting">
        <h6 className="body-1">{title}</h6>
        {type === 'checkbox' ? (
            options.map((option, i) => (
                <TDXCheckbox
                    key={i}
                    checked={option.value}
                    label={option.label}
                    onChange={onChange(option.name)}
                />
            ))
        ) : (
            <RadioGroup row value={value} onChange={onChange}>
                {options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        value={option.value}
                        label={option.label}
                        control={<Radio disableRipple />}
                    />
                ))}
            </RadioGroup>
        )}
    </div>
)

const TDXTableSettings = ({settings}:{settings?: any[]}) => {
    const [popper, setPopper] = useState({
        open: false,
        anchorEl: null,
    })

    const openPopper = (e: any) => setPopper({open: true, anchorEl: e.currentTarget})
    const closePopper = () => setPopper({open: false, anchorEl: null})

    return (
        <ClickAwayListener onClickAway={closePopper}>
            <div className={'tdx-table-settings-wrapper'}>
                <IconButton
                    disableRipple
                    onClick={popper.open ? closePopper : openPopper}
                    sx={{p: 0}}>
                    <SettingsIcon />
                </IconButton>
                <Popper
                    className="tdx-table-settings"
                    open={popper.open}
                    anchorEl={popper.anchorEl}
                    placement="bottom-start">
                    <Fade in={popper.open} timeout={350}>
                        <Paper elevation={24}>
                            {settings.map((setting, i) => (
                                <TableSetting key={i} {...setting} />
                            ))}
                        </Paper>
                    </Fade>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

export default memo(TDXTableSettings)

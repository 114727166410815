import {SvgIcon} from '@mui/material'

const SkypeIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '20px', height: '20px', color: 'rgba(0, 0, 0, 0.8)'}}
        viewBox="0 0 430.1 430.1">
        <g><g><path d="M412.2,246.2c1.6-10.2,2.5-20.5,2.5-31.1c0-110.2-89.3-199.6-199.6-199.6c-10.6,0-21,0.9-31.1,2.5     C165.5,6.6,143.8,0,120.6,0C54,0,0,54,0,120.6c0,23.2,6.6,44.9,18,63.3c-1.6,10.2-2.4,20.5-2.4,31.1     c0,110.2,89.3,199.5,199.5,199.5c10.6,0,21-0.8,31.2-2.4c18.4,11.4,40.1,17.9,63.3,17.9c66.6,0,120.6-54,120.6-120.6     C430.1,286.3,423.5,264.6,412.2,246.2z M309.8,305.8c-8.4,12.2-20.8,21.6-37,28.5c-16.2,6.8-35.4,10.3-57.6,10.3     c-26.6,0-48.6-4.6-65.9-14c-12.3-6.7-22.3-15.7-30-27c-7.7-11.3-11.6-22.3-11.6-33c0-6.3,2.4-11.9,7.1-16.3     c4.8-4.5,10.9-6.8,18.1-6.8c5.9,0,11,1.8,15.1,5.3c4.1,3.5,7.6,8.7,10.4,15.4c3.4,7.9,7,14.4,11,19.6c3.9,5.2,9.3,9.5,16.4,12.9     c7,3.4,16.3,5.1,28,5.1c15.9,0,28.8-3.4,38.7-10.3c9.9-6.9,14.7-15.2,14.7-25.3c0-8.1-2.6-14.4-7.8-19.4     c-5.3-5.1-12.2-8.9-20.7-11.6c-8.5-2.7-20-5.6-34.4-8.6c-19.3-4.2-35.5-9.1-48.5-14.8c-13.1-5.7-23.5-13.4-31.3-23.1     c-7.7-9.9-11.6-22.1-11.6-36.6c0-13.8,4.1-26.2,12.2-37c8.2-10.8,20-19.1,35.4-24.8c15.3-5.8,33.4-8.6,54.1-8.6     c16.5,0,30.8,1.9,43,5.7c12.1,3.8,22.1,8.9,30.2,15.3c8,6.3,13.9,13,17.6,20c3.7,7,5.6,13.9,5.6,20.6c0,6.2-2.4,11.9-7.1,16.8     c-4.7,5-10.7,7.5-17.8,7.5c-6.3,0-11.3-1.5-14.8-4.6c-3.4-3-6.9-7.8-10.7-14.4c-4.8-9.3-10.6-16.5-17.3-21.7     c-6.5-5.1-17.3-7.7-32.4-7.7c-13.9,0-25,2.8-33.4,8.4c-8.4,5.6-12.5,12.2-12.5,19.9c0,4.8,1.4,8.9,4.2,12.3     c2.8,3.5,6.8,6.5,11.8,9.1c5.1,2.6,10.2,4.6,15.4,6c5.2,1.5,13.9,3.6,25.9,6.5c15.1,3.3,28.8,6.9,41.1,10.9     c12.3,4,22.8,8.8,31.5,14.5c8.7,5.7,15.6,13,20.4,21.8c4.9,8.8,7.4,19.6,7.4,32.2C322.5,280,318.3,293.7,309.8,305.8z" id="Skype"/></g></g>
    </SvgIcon>
)

export default SkypeIcon

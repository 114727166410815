import {SvgIcon} from '@mui/material'

const LinkedinIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '16px', height: '16px', color: 'rgba(0, 0, 0, 0.8)'}}
        viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.862412 0.32137C1.18087 0.110132 1.55492 -0.00171808 1.93707 1.99519e-05C2.44606 1.99311e-05 2.93421 0.202178 3.29417 0.562036C3.65413 0.921894 3.85643 1.40999 3.85658 1.91898C3.85842 2.30112 3.74668 2.6752 3.53553 2.99373C3.32439 3.31225 3.02336 3.56085 2.67066 3.70797C2.31796 3.85509 1.9295 3.8941 1.5546 3.82004C1.17969 3.74598 0.835243 3.56219 0.564982 3.29201C0.294721 3.02182 0.110834 2.67743 0.0366663 2.30254C-0.0375019 1.92766 0.001393 1.53919 0.148413 1.18645C0.295434 0.833709 0.543949 0.532609 0.862412 0.32137ZM15.997 15.982H16.0008V10.1196C16.0008 7.25214 15.3837 5.04358 12.0316 5.04358C11.4025 5.02059 10.7789 5.16867 10.2273 5.47205C9.67571 5.77543 9.21673 6.22275 8.89926 6.76636H8.85255V5.3112H5.67188V15.9814H8.98169V10.6977C8.98169 9.30683 9.24546 7.96158 10.9682 7.96158C12.6657 7.96158 12.691 9.54917 12.691 10.7873V15.982H15.997ZM0.285156 5.31177H3.59882V15.9814H0.285156V5.31177Z"
        />
    </SvgIcon>
)

export default LinkedinIcon

import {useMemo, useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useHasChanged, usePrevious} from '../../../customHooks'
import {Slider} from '@mui/material'
import {TDXIntegerInput} from '../index'
import {RangeSliderThumbIcon} from '../../../Icons'
import {parseSearchParams} from '../../AsideFilter/utils'
import {useAppDispatch, useAppSelector} from '../../../../shared/hooks/hooks'
import {asideFiltersActions} from '../../../../store/slice/asideFiltersSlice/asideFiltersSlice'
import {FilterDataData} from "../../../../store/slice/asideFiltersSlice/types";

const MIN_DISTANCE = 1

const TDXRangeSliderThump = ({children, style}:{
    children: React.ReactNode,
    style: React.CSSProperties,
}) => (
    <div className="tdx-range-slider-thumb" style={{...style}}>
        {children}
        <RangeSliderThumbIcon />
    </div>
)

const TDXFilterRangeSlider = ({id, data = {}}:{
    id: string | number,
    data: FilterDataData,
}) => {
    const dispatch = useAppDispatch()
    const throttle = 1500
    const [searchParams] = useSearchParams()
    const {isActive, values, allowFiltered, smartFilters} = useAppSelector(state => state.AsideFilters)
    const params = useMemo<Record<string, any>>(() => parseSearchParams(searchParams), [searchParams])
    const [isChanged, setChanged] = useState(false)
    const [range, setRange] = useState<number[]>([data.option?.from ?? 0, data.option?.to ?? 0] as number[])
    const [defaultRange, setDefaultRange] = useState<number[]>([data.option?.from ?? 0, data.option?.to ?? 0] as number[])

    const prevRange = usePrevious(range)

    const getState = (): number[] => {
        if (params[id]) {
            return [
                params[id].from ? +params[id].from : data?.option?.from ?? '',
                params[id].to ? +params[id].to : data?.option?.to ?? '',
            ] as number[]
        }

        return [range[0], range[1]]
    }

    const getInputValues = () => {
        if (params[id]) {
            return [params[id].from ?? '', params[id].to ?? '']
        }

        return [values[id].from ?? '', values[id].to ?? '']
    }

    const [state, setState] = useState(() => getState())
    const [inputValues, setInputValues] = useState(() => getInputValues())

    useEffect(() => {
        const state_tmp = getState()

        if(state_tmp !== state) {
            setState(state_tmp)
        }

        const input_tmp = getInputValues()

        if(input_tmp !== inputValues) {
            setInputValues(input_tmp)
        }
    },[values, smartFilters])

    const needCommit = useHasChanged(state)

    const handleChangeSlider = (event: any, newValue: any, activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return
        }

        let sliderFrom, sliderTo, inputFrom, inputTo

        if (activeThumb === 0) {
            sliderFrom = Math.min(newValue[0], state[1] - MIN_DISTANCE)
            sliderTo = state[1]

            inputFrom = newValue[0]
            inputTo = inputValues[1]
        } else {
            sliderFrom = state[0]
            sliderTo = Math.max(newValue[1], state[0] + MIN_DISTANCE)

            inputFrom = inputValues[0]
            inputTo = newValue[1]
        }
        setChanged(true)
        setInputValues([inputFrom, inputTo])
        setState([sliderFrom, sliderTo])
    }

    const handleChangeInput = (index: number) => (newVal: string | number) => {
        let sliderFrom, sliderTo, inputFrom, inputTo

        if (index === 0) {
            inputFrom = newVal
            inputTo = inputValues[1]
            sliderFrom = newVal && String(newVal).length > 0 ? Number(newVal) : prevRange ? prevRange[0]: undefined
            sliderTo = state[1]
        } else {
            inputFrom = inputValues[0]
            inputTo = newVal
            sliderFrom = state[0]
            sliderTo = newVal && String(newVal).length > 0 ? Number(newVal) : prevRange ? prevRange[1]: undefined
        }
        setChanged(true)
        setInputValues([inputFrom, inputTo])
        setState([sliderFrom, sliderTo])
    }

    useEffect(() => {
        if (
            smartFilters?.[id]?.min &&
            smartFilters?.[id]?.max &&
            JSON.stringify(range) !== JSON.stringify([smartFilters?.[id]?.min, smartFilters?.[id]?.max])
        ) {
            setRange([smartFilters?.[id]?.min, smartFilters?.[id]?.max] as number[])
        }
    }, [smartFilters])

    useEffect(() => {
        if (JSON.stringify(inputValues) === JSON.stringify(['', ''])) {
            setState(range)
        }
    }, [range])

    useEffect(() => {
        if (allowFiltered && Object.keys(isActive).length > 0 && !isActive[id]) {
            setState([range[0], range[1]])
        }
    }, [isActive])

    useEffect(() => {
        if (needCommit && isChanged) {
            const timeout = setTimeout(() => {
                dispatch(
                    asideFiltersActions.updateFilterValue({
                        id,
                        newValue: {from: inputValues[0], to: inputValues[1]},
                    })
                )
                setChanged(false)
                clearTimeout(timeout)
            }, throttle)

            return () => {
                clearTimeout(timeout)
            }
        }
    },[state])

    return (
        <div className="tdx-range-slider-container">
            <div className="inputs-container">
                <div className="range-input">
                    <span className="body-2">от</span>
                    <TDXIntegerInput
                        placeholder={range[0].toString() ?? '0'}
                        max={range[1]}
                        value={inputValues[0]}
                        onChange={handleChangeInput(0)}
                        allowNull={true}
                    />
                </div>
                <div className="range-input">
                    <span className="body-2">до</span>
                    <TDXIntegerInput
                        placeholder={range[1].toString() ?? '0'}
                        max={range[1]}
                        value={inputValues[1]}
                        onChange={handleChangeInput(1)}
                        allowNull={true}
                    />
                </div>
            </div>
            <Slider
                className="tdx-range-slider"
                min={defaultRange[0] ?? range[0]}
                max={range[1]}
                value={state}
                onChange={handleChangeSlider}
                disableSwap
                components={{
                    Thumb: TDXRangeSliderThump,
                }}
            />
        </div>
    )
}

export default TDXFilterRangeSlider

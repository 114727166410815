import {Input, InputAdornment} from '@mui/material'
import {SearchIcon} from '../../Icons'

const titles = ['пользователя', 'контрагента']

interface TDXSearchInterface {
    tab: number,
    value: string | number | boolean,
    onChange: (e: any) => any,
}

const TDXSearch = ({tab, value, onChange}: TDXSearchInterface) => (
    <Input
        className="search-counterparties-input tdx-search-input body-1 "
        placeholder={`Введите название ${titles[tab]}`}
        value={value}
        onChange={onChange}
        startAdornment={
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        }
    />
)

export default TDXSearch;

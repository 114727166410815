import {TDXButton, TDXProfileBalance, TDXSearchInput} from '../UI'
import {useState} from 'react'
import {ClickAwayListener, List, ListItem, Paper, Popper} from '@mui/material'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {CSVMenu} from '../Icons'
import SearchIcon from '../Icons/SearchIcon'
import HeaderMobileMenuWrapper from './HeaderMobileMenuWrapper'
import TDXLogo from "../UI/TDXLogo";
import {useEffect} from 'react'
import {getCategories} from "../../store/slice/catalogCategoriesSlice/services/getCategories/getCategories";
import {useWindowSize} from "../useWindowSize";

const HeaderMobileMenu = () => {
    const dispatch = useAppDispatch()
    const [windowWidth/*, windowHeight*/] = useWindowSize()
    const {isSpecial, isCustomPrice} = useAppSelector(state => state.Profile.profile)
    const categories = useAppSelector(state => state.CatalogCategories.categories)
    const categoriesLastFetch = useAppSelector(state => state.CatalogCategories.lastFetch)
    const isLoading = useAppSelector(state => state.CatalogCategories.loading)

    const [menu, setMenu] = useState<Record<string,any>>({
        type: 'general',
        anchorEl: null,
    })

    const targetMenu = (e?: MouseEvent | TouchEvent, type = '') => {
        if (type.length>0) {
            e.preventDefault()
        }

        if (menu.anchorEl) {
            setMenu({...menu, anchorEl: null})
        } else {
            setMenu({type, anchorEl: e.currentTarget})
        }
    }

    useEffect(() => {
        if (categoriesLastFetch === null && !isLoading && (
            categories === undefined || categories.length === 0 && windowWidth > 0)
        ) {
            dispatch<any>(getCategories())
        }
    }, [categories, isLoading])

    return (
        <>
            <TDXButton
                size="small"
                bg={'transparent'}
                startIcon={<CSVMenu />}
                onClick={(e: MouseEvent | TouchEvent) => targetMenu(e,'menu-popper')}
                className="logoMenu"
                id="burgerMenu"
            />
            <TDXLogo />
            <div className="combineIconsForMobile">
                {!isSpecial ? <TDXProfileBalance /> : ''}
                <TDXButton
                    size="small"
                    bg={'transparent'}
                    startIcon={<SearchIcon />}
                    onClick={(e: MouseEvent | TouchEvent) => targetMenu(e,'search-popper')}
                    className="searchIcon"
                    id="searchIcon"
                />
            </div>
            <Popper
                id="menu-popper"
                open={!!menu.anchorEl}
                anchorEl={menu.anchorEl}
                placement="bottom"
                style={{width: `100%`}}>
                <ClickAwayListener onClickAway={(e: MouseEvent | TouchEvent) => targetMenu(e, 'all-popper')}>
                    <Paper elevation={3}>
                        <List>
                            <ListItem>
                                {menu.type === 'menu-popper' ? <HeaderMobileMenuWrapper targetMenu={targetMenu} /> : ''}
                                {menu.type === 'search-popper' ? <TDXSearchInput targetMenu={targetMenu} /> : ''}
                            </ListItem>
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default HeaderMobileMenu

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Tooltip} from "@mui/material";

interface TDXCheckboxInterface {
    value?: string,
    label?: string | React.ReactNode,
    shadowLabel?: string,
    onChange?: (e: any) => any,
    onClick?: (item?: any) => any,
    checked?: boolean,
    disabled?: boolean,
    name?: string,
    tooltipLabel?: string,
    sx?: any,
    className?: string,
}

export default function TDXCheckbox (props: TDXCheckboxInterface) {
    const {value = '', label = '', shadowLabel = '', tooltipLabel = '', className = '', onChange, ...other} = props
    const concatLabels = (
        <>
            {label}&nbsp;<span className="shadow-label">{shadowLabel}</span>
        </>
    )

    const itemTemplate = (
        <div className={className}>
            {label ? (
                <FormControlLabel
                    control={
                        <Checkbox {...other} value={value} disableRipple className="tdx-checkbox" onChange={onChange} />
                    }
                    label={shadowLabel ? concatLabels : label}
                    sx={{margin: 0}}
                />
            ) : (
                <Checkbox {...other} value={value} disableRipple className="tdx-checkbox" onChange={onChange} />
            )}
        </div>
    )

    return tooltipLabel.length > 0 ? (
        <Tooltip title={tooltipLabel}>
            <div>
                {itemTemplate}
            </div>
        </Tooltip>
    ): itemTemplate
}

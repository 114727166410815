import {SvgIcon} from '@mui/material'

const MaintenanceIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '150px', height: '150px', color: 'rgba(0, 0, 0, 0.87)'}}
        x="0px" y="0px" viewBox="0 0 1010 1009.994">
        <g id="technical-support">
            <path id="label_26_" style={{fill:'#FC6220'}} d="M505.003,0C783.897,0,1010,226.084,1010,504.997
                c0,278.889-226.103,504.997-504.997,504.997C226.097,1009.994,0,783.885,0,504.997C0,226.084,226.097,0,505.003,0z"/>
                <g id="icon_27_">
                    <g>
                        <g>
                            <path style={{fill:'#FFFFFF'}} d="M318.925,544.814l-52.142-6.173c-3.692-0.404-6.749-3.833-6.749-7.593V478.82
                                c0-3.699,3.057-7.165,6.749-7.557l52.142-6.186c4.997-23.738,14.361-45.895,27.239-65.516l-32.574-41.326
                                c-2.334-2.878-2.071-7.448,0.563-10.093l36.953-36.966c2.645-2.621,7.183-2.927,10.159-0.563l41.272,32.507
                                c19.664-12.776,41.785-22.17,65.535-27.155l6.148-52.202c0.404-3.674,3.822-6.711,7.588-6.711h52.239
                                c3.748,0,7.141,3.038,7.588,6.711l6.173,52.202c23.725,4.986,45.87,14.38,65.51,27.155l41.295-32.507
                                c2.921-2.364,7.484-2.058,10.129,0.563l36.917,36.966c2.67,2.645,2.964,7.214,0.612,10.129l-32.532,41.289
                                c12.836,19.621,22.188,41.778,27.185,65.516l52.141,6.186c3.742,0.391,6.749,3.858,6.78,7.557l-0.03,52.227
                                c0.03,3.76-3.008,7.189-6.749,7.593l-52.141,6.173c-4.997,23.763-14.349,45.895-27.198,65.554l32.544,41.302
                                c2.352,2.902,2.058,7.446-0.612,10.104l-36.917,36.941c-2.646,2.67-7.208,2.891-10.148,0.576l-41.277-32.531
                                c-19.64,12.799-41.785,22.206-65.51,27.167l-6.173,52.13c-0.447,3.748-3.84,6.76-7.588,6.785l-52.239-0.025
                                c-3.766,0.025-7.184-3.013-7.588-6.76l-6.148-52.13c-14.779-3.062-28.808-8.047-42.117-14.342l42.625-41.314
                                c12.5,3.711,25.654,5.721,39.372,5.721c76.767,0,139.019-62.259,139.019-139.057c0-76.822-62.252-139.055-139.019-139.055
                                c-76.822,0-139.067,62.234-139.067,139.055c0,13.192,1.959,25.868,5.388,37.922l-42.22,40.946
                                C326.426,571.381,321.797,558.471,318.925,544.814z"/>
                        </g>
                    </g>
                    <path style={{fill:'#394145'}} d="M266.293,739.661c16.473,17.123,43.518,17.747,60.794,1.531l0.448,0.477l143.912-139.533
                        c34.859,12.359,75.811,4.703,104.901-23.284c29.016-27.865,38.423-68.235,27.675-103.474l-34.228,32.924
                        c-7.906,7.582-20.504,7.361-28.116-0.576l-33.083-34.406c-7.6-7.912-7.349-20.515,0.576-28.159l34.185-32.837
                        c-34.742-12.151-75.492-4.312-104.454,23.517c-28.851,27.717-38.228,67.782-27.742,102.837L266.924,678.541l0.03,0.025
                        C250.18,695.285,249.819,722.513,266.293,739.661z M280.556,709.788c5.64-5.438,14.599-5.268,20.031,0.342
                        c5.421,5.67,5.249,14.686-0.379,20.173c-5.598,5.438-14.582,5.292-20.014-0.355C274.751,724.289,274.916,715.263,280.556,709.788z
                        "/>
                </g>
        </g>
        <g id="Layer_1"></g>
    </SvgIcon>
)

export default MaintenanceIcon

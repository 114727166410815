import {createAsyncThunk} from '@reduxjs/toolkit'
import CatalogService from '../../../../../api/catalog.service'
import {asideFiltersActions} from '../../../asideFiltersSlice/asideFiltersSlice'

export const getCategory = createAsyncThunk<unknown, {id: string; signal: unknown}>(
    'catalog/getCategory',
    async ({id, signal}, {dispatch}) => {
        try {
            const category = (await CatalogService.getCategory(id, signal)) || {}

            dispatch(asideFiltersActions.setFiltersValues({filters: category.filters ?? []}))

            return category
        } catch (e) {
            console.error(e)
            return {}
        }
    }
)

import {
    SET_FAQ_QUESTIONS,
    SET_DISCOUNTS,
    SET_NEWS,
    SET_COMMON_DATA,
    SET_SEARCH,
    TOGGLE_SEARCH_CATEGORY,
    RESET_SEARCH,
    MOVE_SEARCH_CHECKED_CATEGORIES,
    SET_SEARCH_PRICES,
    GeneralState,
} from '../actions/GeneralActions'

const initialState = {
    questions: [],
    discounts: [],
    news: [],
    quickSearch: {
        keyword: '',
        results: {
            categories: [],
            products: [],
            count: 0,
        },
        selectedCategories: [],
    },
    search: {
        keyword: '',
        results: {
            categories: [],
            products: [],
            count: 0,
        },
        prices: [],
        selectedCategories: [],
    },
    common: {
        cities: [],
        cities_last_fetched: null,
        currencies: [],
        partnersTypes: [],
        organisations: [],
        polls: [],
        popups: [],
        banners: [],
        settings: null,
    },
} as GeneralState

export const GeneralReducer = (state = initialState, action : any) : GeneralState => {
    switch (action.type) {
        case SET_FAQ_QUESTIONS:
            return {
                ...state,
                questions: action.data.questions,
            }
        case SET_DISCOUNTS:
            return {
                ...state,
                discounts: action.data.discounts,
            }
        case SET_NEWS:
            return {
                ...state,
                news: action.data.news,
            }
        case SET_COMMON_DATA:
            return {
                ...state,
                common: {
                    ...state.common,
                    ...action.data.common,
                },
            }
        case SET_SEARCH: {
            const key = action.data.quick ? 'quickSearch' : 'search'

            return {
                ...state,
                [key]: {
                    ...state[key],
                    keyword: action.data.keyword,
                    results: action.data.results,
                },
            }
        }
        case SET_SEARCH_PRICES:
            return {
                ...state,
                search: {
                    ...state.search,
                    prices: action.data.prices,
                },
            }
        case TOGGLE_SEARCH_CATEGORY: {
            const key = action.data.quick ? 'quickSearch' : 'search'

            return {
                ...state,
                [key]: {
                    ...state[key],
                    selectedCategories: action.data.checked
                        ? [...state[key].selectedCategories, action.data.id]
                        : state[key].selectedCategories.filter(id => id !== action.data.id),
                },
            }
        }
        case MOVE_SEARCH_CHECKED_CATEGORIES:
            return {
                ...state,
                search: {
                    ...state.search,
                    selectedCategories: state.quickSearch.selectedCategories,
                },
            }
        case RESET_SEARCH: {
            const key = action.data.quick ? 'quickSearch' : 'search'

            return {
                ...state,
                [key]: {
                    keyword: '',
                    results: {
                        categories: [],
                        products: [],
                        count: 0,
                    },
                    selectedCategories: [],
                },
            }
        }
        default:
            return state
    }
}

import {useAppDispatch, useAppSelector} from "../../shared/hooks/hooks";
import {useCallback, useEffect, useState, useMemo,} from "react";
import {getCart} from "../../store/slice/cartSlice/asyncActions";
import TDXBufferModalItem from "./TDXBufferModalItem"
import {ClickAwayListener, List, ListItem, Paper, Popper} from "@mui/material";
import {useLocation, Link} from "react-router-dom";
import {
    TDXCircularProgress,
} from '../UI'
import {separateThousands} from '../../utils/separateThousands'
import {PopperPlacementType} from "@mui/base/Popper/Popper.types";

const TDXBufferModal = ({open, disablePortal = false, placement = 'bottom-start'} : {
    open: HTMLDivElement,
    placement?: PopperPlacementType,
    disablePortal?: boolean
}) => {
    const dispatch = useAppDispatch()

    const {region, isSpecial} = useAppSelector(s => s['Profile'].profile)
    const cart = useAppSelector(state => state.Cart.data)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null)
    const [visible, setVisible] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)
    const location = useLocation();

    const closeMenu = useCallback(() => {
        setAnchorEl(null)
        setVisible(false)
    }, [])

    useEffect(() => {
        closeMenu();
    }, [location])

    useEffect(() => {
        if (visible)
        {
            dispatch<any>(getCart())
        }
    }, [visible])

    useEffect(() => {
        if (visible && cart?.id > 0) {
            setLoading(false)
        }
    }, [visible,cart])

    useEffect(() => {
        if (open) {
            setAnchorEl(open)
            if (!visible)
            {
                setVisible(!visible)
            }
        } else {
            closeMenu()
        }
    }, [open])

    return (
        <Popper
            id="buffer-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement={placement}
            disablePortal={disablePortal}
        >
            <ClickAwayListener onClickAway={closeMenu}>
                <Paper elevation={3}>
                    {/*<p className="popper-title body-1 light">Буфер</p>*/}

                    {isLoading ? (
                        <div style={{marginBottom: '10px', display: 'flex', whiteSpace: 'nowrap', gap: '10px'}}>
                            <span>Получение {region === 'ru' ? 'корзины': 'буфера'}</span>
                            <TDXCircularProgress size={18} />
                        </div>
                    ) : (
                        <List>
                            {cart?.products.length>0 ? cart?.products.map((product) => (
                                <ListItem key={product._id}>
                                    <TDXBufferModalItem product={product} />
                                </ListItem>
                            )) : (
                                <ListItem key="1">
                                    {region === 'ru' ? 'Корзина пуста...': 'Буфер пустой...'}
                                </ListItem>
                            )}
                        </List>
                    )}
                    <div className={'buffer-footer'}>
                        <span className={'total'}>
                            {!isLoading ? (
                                <>
                                    <strong>Итого:</strong> {separateThousands(cart.total)} {!isSpecial ? cart.currency ?? '': ''}
                                </>
                            ): ''}
                        </span>

                        <Link
                            onClick={closeMenu}
                            to="/user/buffer" className="popper-link">
                            Перейти в {region === 'ru' ? 'корзину': 'буфер'}
                        </Link>
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popper>
    )
}

export default TDXBufferModal
import {store} from '../store/store'
import SettingsService from './settings.service'
//import AuthService from './auth.service'

const getAccessToken = () => {
    const {Auth} = store.getState()
    const user = Auth.user || JSON.parse(localStorage.getItem('user'))
    return user?.token
}

const getAccessPartnerId = () => {
    const {Auth} = store.getState()
    const user = Auth.user || JSON.parse(localStorage.getItem('user'))
    return user?.partner?.id
}

const updateAccessToken = (token: string) => {
    const user = JSON.parse(localStorage.getItem('user'))
    user.token = token
    localStorage.setItem('user', JSON.stringify(user))
}

const getUser = (detailed?: boolean) => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user !== undefined && user !== null)
    {
        if (user.partner === undefined && detailed !== true)
        {
            return undefined;
        }
    }

    return user
}

const setUser = (user: any) => localStorage.setItem('user', JSON.stringify(user))

const removeUser = () => localStorage.removeItem('user')

const TokenService = {
    getAccessToken,
    getAccessPartnerId,
    updateAccessToken,
    getUser,
    setUser,
    removeUser,
    ...SettingsService
}

export default TokenService

import {SET_PRODUCT, SET_PRODUCT_PRICES} from '../actions/ProductActions'
import {prepareProductId} from '../../utils/prepareProduct'
import {ProductSerializer, ProductState} from '../../store/slice/catalogSlice/types'

const initialState : ProductState = {
    product: null,
    prices: []
}

export const ProductReducer = (state = initialState, action : any) : ProductState => {
    switch (action.type) {
        case SET_PRODUCT: {
            const product = action.data.product as ProductSerializer;
            product?.offers?.forEach((item) => {
                item._id = prepareProductId(item);
            })

            return {
                ...state,
                product,
            }
        }
        case SET_PRODUCT_PRICES:
            return {
                ...state,
                prices: action.data.prices,
            }
        default:
            return state
    }
}

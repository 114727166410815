import {useMemo, useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {parseSearchParams} from '../../AsideFilter/utils'
import {useAppDispatch, useAppSelector/*, useAppSelector*/} from '../../../../shared/hooks/hooks'
import {asideFiltersActions} from '../../../../store/slice/asideFiltersSlice/asideFiltersSlice'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TDXInput from "../TDXInput";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, {Dayjs} from 'dayjs'
import {FilterDataData} from "../../../../store/slice/asideFiltersSlice/types";

const TDXFilterDateRange = ({id, data}:{
    id: string | number,
    data: FilterDataData
}) => {
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams()
    const params = useMemo<Record<string, any>>(() => parseSearchParams(searchParams), [searchParams])
    const {isActive, allowFiltered, values, smartFilters} = useAppSelector(state => state.AsideFilters)
    const [isChanged, setChanged] = useState(false)

    const date_format = "DD.MM.YYYY HH:mm:ss";

    const getState = (): string[] => {
        if (params[id]) {
            return [params[id].from ? params[id].from : '', params[id].to ? params[id].to : '']
        }

        return [values[id].from, values[id].to] as string[]
    }

    const [state, setState] = useState<string[]>(() => getState())

    useEffect(() => {
        const state_tmp = getState()

        if(state_tmp !== state) {
            setState(state_tmp)
        }
    },[values, smartFilters])

    const handleChange = (newVal: Dayjs, index: number) => {
        const newVal_tmp = newVal.format(date_format);
        setChanged(true)

        if (index === 0) {
            setState([String(newVal_tmp), state[1]])
        } else {
            setState([state[0], String(newVal_tmp)])
        }
    }

    useEffect(() => {
        if (allowFiltered && Object.keys(isActive).length > 0 && !isActive[id]) {
            setState([data.option.from ?? '', data.option.to ?? ''] as string[])
        }
    }, [isActive])

    useEffect(() => {
        if (state[0]?.length > 0 && state[1]?.length > 0 && isChanged) {
            dispatch(
                asideFiltersActions.updateFilterValue({
                    id,
                    newValue: {from: state[0], to: state[1]},
                })
            )
            setChanged(false)
        }
    }, [state])

    return (
        <div className="tdx-filter-range">
            <div className="inputs-container">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <div className={'tdx-datepicker-range-group'}>
                        <DatePicker
                            disableFuture
                            minDate={dayjs().subtract(3, 'month')}
                            value={dayjs(state[0], date_format)}
                            onChange={(newVal: Dayjs) => handleChange(newVal, 0) }
                            slots={{
                                textField: TDXInput,
                            }}
                            slotProps={{
                                day: {
                                    className: 'tdx-date-picker-day',
                                },
                                textField: { placeholder: data.placeholder?.from as string ?? '0' }
                            }}
                        />
                        <span className={'separator'}>-</span>
                        <DatePicker
                            disableFuture
                            minDate={dayjs(state[0], date_format)}
                            value={dayjs(state[1], date_format)}
                            onChange={(newVal: Dayjs) => handleChange(newVal, 1) }
                            slots={{
                                textField: TDXInput,
                            }}
                            slotProps={{
                                day: {
                                    className: 'tdx-date-picker-day',
                                },
                                textField: { placeholder: data.placeholder?.to as string }
                            }}
                        />
                    </div>
                </LocalizationProvider>
            </div>
        </div>
    )
}

export default TDXFilterDateRange

import {useState, useEffect, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {Avatar, ClickAwayListener, List, ListItem, Paper, Popper,Skeleton} from '@mui/material'

import {TDXSearchInput, TDXButton, TDXAutocomplete} from '../UI'
import {/*Logo, */PhoneIcon, PriceListIcon} from '../Icons'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import TDXModalWindow, {useModalWindow} from '../UI/TDXModalWindow'
import TokenService from '../../api/token.service'
import {authActions} from '../../store/slice/authSlice/authSlice'
import {Logout, KeyboardArrowDown} from '@mui/icons-material'
import UserIcon from '../Icons/UserIcon'
import TDXForm from '../UI/TDXForm'
import MailIcon from '../Icons/MailIcon'
import {
    resetCatalogCache
} from "../../store/slice/catalogCategoriesSlice/services/resetCatalogCache/resetCatalogCache";
import {useWindowSize} from "../useWindowSize";
import TDXPollPopupButton from "../UI/Buttons/TDXPollPopupButton";
import {Partner} from "../../store/slice/authSlice/types";

const HeaderPreMenu = () => {
    const dispatch = useAppDispatch()
    const {isOpen, openWindow, closeWindow} = useModalWindow()
    const {name} = useAppSelector(state => state.Profile.profile)
    const partners: Partner[] = useAppSelector(state => state.Auth.partners)
    const isResetting = useAppSelector(state => state.CatalogCategories.resetting)
    const [windowWidth/*, windowHeight*/] = useWindowSize()

    const [menu, setMenu] = useState<Record<string,any>>({
        type: 'general',
        anchorEl: null,
    })
    const [autoCompleteValue, setAutoCompleteValue] = useState(null)

    const partner = partners.find(p => p.id === TokenService.getAccessPartnerId()) ?? {} as Partner

    const targetMenu = (e?: MouseEvent | TouchEvent | React.MouseEvent<SVGSVGElement>, type = '') => {
        if (type.length>0) {
            e.preventDefault()
        }

        if (menu.anchorEl) {
            setMenu({...menu, anchorEl: null})
        } else {
            setMenu({type, anchorEl: e.currentTarget})
        }
    }

    const saveChanges = () => {
        closeWindow()

        const newPartner = partners.find(p => p.name === autoCompleteValue)

        dispatch(authActions.updateUserPartner(newPartner))
        const timeout = setTimeout(() => {
            window.location.reload()
            clearTimeout(timeout)
        }, 1000)
    }

    const onAutocompleteChange = (_:any, name:string) => setAutoCompleteValue(name)

    const logOutHandler = useCallback(() => {
        dispatch(authActions.logout())
    }, [])

    const updateCatalogOffers = () => {
        dispatch<any>(resetCatalogCache(
            {need_reset: true, need_message: false}
        ))
    }

    useEffect(() => {
        dispatch<any>(resetCatalogCache({need_reset: false, need_message: false}))
    }, [])

    return (
        <>
            <div style={{paddingBottom: '1em'}} className="header-info wrapper">
                <div className="currency-info body-1">
                    <TDXButton
                      size="medium"
                      bg="transparent border"
                      onClick={updateCatalogOffers}
                      loading={isResetting}
                      disabled={isResetting}
                      className={`updateProposal`}
                    >
                        Обновить предложения
                    </TDXButton>
                    <TDXPollPopupButton slug={'support_callback'} label={'Запрос в техподдержку'} bg={'green invert'} />
                </div>
                <div className="search-container">
                    <TDXSearchInput />
                    <Link to="/price-list" className="askPrice">
                        <TDXButton size="large" bg="gray" startIcon={<PriceListIcon/>}>
                            Запрос на прайс лист
                        </TDXButton>
                    </Link>

                    <div>
                        {windowWidth > 1023 ? (
                            <TDXButton
                                size="small"
                                bg="transparent"
                                startIcon={<PhoneIcon />}
                                onClick={(e: MouseEvent | TouchEvent) => targetMenu(e,'manager')}
                                className = "hiddenHelpService body-1">
                                Служба поддержки
                            </TDXButton>
                        ) : ''}
                    </div>

                    <div className="profile">
                        {name ? (
                            <>
                                <Avatar className="avatar body-2" alt={`${name}`}>
                                    {name.slice(0, 1)}
                                </Avatar>
                                <Link to="/user/profile" className="title body-1">
                                    {name.split(' ')[0]}
                                </Link>
                                <KeyboardArrowDown onClick={(e: React.MouseEvent<SVGSVGElement>) => targetMenu(e, 'general')} />
                            </>
                        ) : (
                            <>
                                <Skeleton className="avatar" variant="circular" />
                                <Skeleton className="title body-1" width={105} height={20} />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="header-border" />
            <Popper
                id="header-popper"
                open={!!menu.anchorEl}
                anchorEl={menu.anchorEl}
                placement="bottom-end">
                <ClickAwayListener onClickAway={(e: MouseEvent | TouchEvent) => targetMenu(e, 'all-popper')}>
                    <Paper elevation={3}>
                        <List>
                            <ListItem>
                                {menu.type === 'manager' ? (
                                    <>
                                        <UserIcon/>
                                        <span className="body-1">
                                            {partner?.manager?.name || 'Не определено'}
                                        </span>
                                    </>
                                ) : (
                                    <TDXButton size="large" bg="gray" onClick={openWindow}>
                                          Сменить контрагента
                                    </TDXButton>
                                )}
                            </ListItem>
                            <ListItem>
                                {menu.type === 'manager' ? (
                                    <>
                                        <PhoneIcon
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                padding: '3px',
                                            }}
                                        />
                                        {partner?.manager?.phone ? (
                                            <a
                                                className="body-1 light"
                                                href={`tel:${partner.manager.phone}`}>
                                                {partner.manager.phone}
                                            </a>
                                        ) : (
                                            <span className="body-1 light">Не определено</span>
                                        )}
                                    </>
                                ) : (
                                    <TDXButton
                                        size="large"
                                        bg="transparent"
                                        startIcon={<Logout/>}
                                        onClick={logOutHandler}>
                                        Выйти из системы
                                    </TDXButton>
                                )}
                            </ListItem>
                            {menu.type === 'manager' && (
                                <ListItem>
                                    <MailIcon/>
                                    {partner?.manager?.email ? (
                                        <a
                                            className="body-1 light"
                                            href={`mailto:${partner.manager.email}`}>
                                            {partner.manager.email}
                                        </a>
                                    ) : (
                                        <span className="body-1 light">Не определено</span>
                                    )}
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <TDXModalWindow
                isOpen={isOpen}
                title="Выбор контрагента для работы с заказами"
                onClose={closeWindow}>
                <TDXForm action={{disabled: !autoCompleteValue}} onSubmit={saveChanges}>
                    <div className="container">
                        <TDXAutocomplete
                            style={{width: '350px'}}
                            label="Контрагент"
                            options={partners.map(p => p.name)}
                            value={autoCompleteValue ?? partner.name}
                            onChange={onAutocompleteChange}
                        />
                    </div>
                </TDXForm>
            </TDXModalWindow>
        </>
    )
}

export default HeaderPreMenu

export default {
    set (obj: any, keys: any, value: any) {
        if (!Array.isArray(keys)) {
            keys = keys.split('.')
        }

        const key = keys.shift()

        if (!keys.length) {
            obj[key] = value
            return
        }

        this.set(obj[key], keys, value)
    },
    get (obj: any, keys: any) {
        if (typeof obj !== 'object' || !obj) return null

        if (!Array.isArray(keys)) {
            keys = keys.split('.')
        }

        const key = keys.shift()

        if (!keys.length) {
            return obj[key]
        }

        return this.get(obj[key], keys)
    },
}

import {Navigate, useSearchParams} from 'react-router-dom'
import {useCallback, useEffect, useState} from 'react'
import {PriceListFileTypes} from '../../../store/slice/priceListSlice/types'
import LinearProgress from '@mui/material/LinearProgress'
import ErrorIcon from '@mui/icons-material/Error'
import DoneIcon from '@mui/icons-material/Done'
import {PriceListService} from '../../../api/priceList.service'
import {downloadFile} from '../../../shared/utils/downloadFile/downloadFile'
import TDXBufferListButton from "../../../components/UI/Buttons/TDXBufferListButton";

export const PriceListGeneratePage = () => {
    const [searchParams] = useSearchParams()

    const type = searchParams.get('extension')
    const priceListHash = searchParams.get('hash')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [progress, setProgress] = useState(0)

    const isValidExtension = type === PriceListFileTypes.csv || type === PriceListFileTypes.xlsx

    const downloadPriceList = useCallback(async () => {
        setLoading(true)

        try {
            const {status, ...response} = await PriceListService.getPriceList(priceListHash, type)

            if (status === 200) {
                downloadFile(response.file, response.filename)

                const timeout = setTimeout(() => {
                    setLoading(false)
                    setSuccess(true)
                    clearTimeout(timeout)
                }, 1000)

                setProgress(100)
            }
        } catch (e) {
            setLoading(false)
            setError(e)
        }
    }, [priceListHash, type])

    useEffect(() => {
        if (!isValidExtension) return
        if (error || success) return

        downloadPriceList()

        const polling = setInterval(() => {
            downloadPriceList()
        }, 15 * 1000)

        return () => {
            clearInterval(polling)
        }
    }, [error, success, isValidExtension, downloadPriceList])

    useEffect(() => {
        if (!isValidExtension) return
        if (!loading) return

        const timer = setInterval(() => {
            setProgress(prevProgress =>
                prevProgress === 100 ? 100 : prevProgress === 99 ? 99 : prevProgress + 1
            )
        }, 600)

        return () => {
            clearInterval(timer)
        }
    }, [loading, isValidExtension])

    if (!isValidExtension) {
        return <Navigate to="/404" />
    }

    return (
        <>
            <div className="tdx-price-page wrapper">
                <div className="tdx-price-page-generate">
                    {loading && (
                        <>
                            <h2>{`${Math.round(progress)}%`}</h2>
                            <LinearProgress
                                className="progress"
                                variant="determinate"
                                value={progress}
                            />
                            <h3>
                                Формирование прас-листа запущено. Файл будет скачан автоматически после
                                успешного формирования.
                            </h3>
                        </>
                    )}
                    {error && (
                        <>
                            <ErrorIcon className="error-icon" />
                            <h3>
                                Произошла непредвиденная ошибка, Попробуйте, пожалуйста, обновить
                                страницу или свяжитесь с менеджером
                            </h3>
                        </>
                    )}
                    {success && (
                        <>
                            <DoneIcon className="done-icon" />
                            <h3>Загрузка прайс-листа завершена успешно.</h3>
                        </>
                    )}
                </div>
            </div>
            <div className={'sticky-button-wrapper wrapper'}>
                <TDXBufferListButton />
            </div>
        </>
    )
}

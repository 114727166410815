import {SvgIcon} from '@mui/material'

const SearchIcon = (props: any) => (
    <SvgIcon
        {...props}
        sx={{width: '18px', height: '18px', color: 'rgba(0, 0, 0, 0.6)'}}
        viewBox="0 0 18 18">
        <path d="M3.83018 11.7774C3.30774 11.258 2.89314 10.6403 2.61022 9.96006C2.32731 9.2798 2.18167 8.55031 2.18167 7.81356C2.18167 7.07681 2.32731 6.34733 2.61022 5.66706C2.89314 4.9868 3.30774 4.36917 3.83018 3.8497C4.34724 3.32299 4.96409 2.90462 5.64468 2.61902C6.32527 2.33342 7.05596 2.18632 7.79405 2.18632C8.53213 2.18632 9.26282 2.33342 9.94341 2.61902C10.624 2.90462 11.2409 3.32299 11.7579 3.8497C12.2804 4.36917 12.695 4.9868 12.9779 5.66706C13.2608 6.34733 13.4064 7.07681 13.4064 7.81356C13.4064 8.55031 13.2608 9.2798 12.9779 9.96006C12.695 10.6403 12.2804 11.258 11.7579 11.7774C11.2409 12.3041 10.624 12.7225 9.94341 13.0081C9.26282 13.2937 8.53213 13.4408 7.79405 13.4408C7.05596 13.4408 6.32527 13.2937 5.64468 13.0081C4.96409 12.7225 4.34724 12.3041 3.83018 11.7774V11.7774ZM17.0138 15.9962L13.2872 12.2696C14.4142 10.8533 14.9548 9.05849 14.7975 7.25532C14.6401 5.45215 13.7968 3.77811 12.4415 2.57841C11.0862 1.3787 9.32218 0.744816 7.51325 0.80746C5.70431 0.870105 3.98838 1.6245 2.7193 2.91509C1.45022 4.20568 0.724764 5.93405 0.692528 7.74378C0.660293 9.55352 1.32374 11.3066 2.54605 12.6416C3.76836 13.9766 5.45633 14.7916 7.26189 14.9186C9.06745 15.0456 10.8529 14.475 12.2501 13.3243L15.9767 17.0509C16.2579 17.3321 16.7149 17.3321 17.0138 17.0509C17.295 16.752 17.295 16.295 17.0138 15.9962Z" />
    </SvgIcon>
)

export default SearchIcon

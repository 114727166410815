import {useCallback, useRef, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {removeFromCart, updateQuantityInCart} from "../../store/slice/cartSlice/asyncActions";
import {CartProductSerializer} from "../../store/slice/cartSlice/types";
import {TDXIntegerInput} from '../../components/UI'
import {useAppDispatch, useAppSelector} from "../../shared/hooks/hooks";
import {separateThousands} from '../../utils/separateThousands'

interface CounterProps {
    id: string
    max: number
    maxStr: number
    initial: number
    onSubmitChange: (id: string, quantity: number) => void
    onLoading: () => void
}

const Counter = (props: CounterProps) => {
    const {id, max, maxStr, initial, onSubmitChange, onLoading} = props
    const [value, setValue] = useState(initial || 1)
    const updateTimer = useRef<ReturnType<typeof setTimeout>>()

    const onChange = useCallback((value: number) => {
        setValue(value)
        onLoading()

        if (updateTimer.current) clearTimeout(updateTimer.current)
        updateTimer.current = setTimeout(() => {
            onSubmitChange(id, value)
        }, 1000)
    }, [id, onLoading, onSubmitChange,])

    return <TDXIntegerInput counter max={max} maxStr={maxStr} value={value} onChange={onChange} />
}

export default function TDXBufferModalItem (
    {product} : {product : CartProductSerializer}
) {
    const dispatch = useAppDispatch()

    const {isSpecial, region} = useAppSelector(state => state.Profile.profile)
    const [isLoading, setLoading] = useState<boolean>(false)

    const onChangeItemQuantity = useCallback((id: string, quantity: number) => {
        dispatch<any>(
            quantity
            ? updateQuantityInCart({id, quantity})
            : removeFromCart({ids: [id], region})
        )
    }, [product, region])

    useEffect(() => {
        setLoading(false)
    }, [product])

    return (
        <div className="buffer-modal-item">
            <img src={product.image} alt={product.name} />
            <Link to={`/product/${product.product_id}`}>
                <h6>{product.name}</h6>
            </Link>
            <div className="sku body-1">{product.sku ?? ''}</div>
            <div className="quantity body-1">
                <Counter
                    key={product.quantity}
                    id={product._id}
                    max={product['max_quantity']}
                    maxStr={product['max_quantity_template']}
                    initial={product.quantity}
                    onSubmitChange={onChangeItemQuantity}
                    onLoading={() => { setLoading(true) }}
                />
            </div>
            <p className="total body-1">
                {isLoading ? (<span>Обновление...</span>): (
                    <span>{separateThousands(product.total)} {!isSpecial ? product.currency ?? '': ''}</span>
                )}
            </p>
        </div>
    )
}

import {useEffect, useState, useMemo} from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {ClickAwayListener, FormControl, Paper, Popper} from '@mui/material'
import {TDXCheckbox} from '../index'
import TDXButton from '../../Buttons/TDXButton'
import {useSearchParams} from 'react-router-dom'
import {parseSearchParams} from '../../AsideFilter/utils'
import {useAppDispatch, useAppSelector} from '../../../../shared/hooks/hooks'
import {asideFiltersActions, filterIsIsset} from '../../../../store/slice/asideFiltersSlice/asideFiltersSlice'
import {
    FilterDictionaryData,
    filterOptionIsDisabled,
    SmartFilters
} from '../../../../store/slice/asideFiltersSlice/types'
import {useHasChanged} from '../../../customHooks'
import {useWindowSize} from "../../../useWindowSize";
import ungapStructuredClone from '@ungap/structured-clone'
import {CatalogSchema, FiltersStats} from "../../../../store/slice/catalogSlice/types";
import {OrderStatus, orderStatuses, orderStatusesDescription} from "../../../OrderWindow/order-statuses";
// const MAX_COUNT = 10

const TDXFilterDictionary = ({id, data, rememberKey}:{
    id: string,
    data: FilterDictionaryData,
    rememberKey?: string
}) => {
    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams()
    const {isActive, smartFilters, values, disableEmptyValues} = useAppSelector(state => state.AsideFilters)
    const filterData = useAppSelector(state => state.AsideFilters.data)
    const [isChanged, setChanged] = useState(false)
    const {stats} = useAppSelector(state => state.Catalog)

    const getState = (include_storage = true) => {
        let params: Record<string | number, any> = parseSearchParams(searchParams)

        if (rememberKey && include_storage) {
            params = {
                ...params,
                ...parseSearchParams(JSON.parse(localStorage.getItem(rememberKey)) ?? {}),
            }
        }

        if (params[id]) {
            return {
                ...values[id],
                ...params[id],
            }
        }

        return values[id]
    }

    const [state, setState] = useState<Record<string, boolean>>(() => getState())
    const [popper, setPopper] = useState({
        open: false,
        anchorEl: null,
    })

    useEffect(() => {
        const state_tmp = getState(false)
        if(JSON.stringify(state_tmp) !== JSON.stringify(state)) {
            setState(state_tmp)
        }
    },[values[id], smartFilters])

    const defaultPopularValues = useMemo(() => {
        return (stats as FiltersStats<FilterDictionaryData>)?.[id]?.[0]?.data?.popular_dictionary_values ?? data?.popular_dictionary_values
    },[data, stats])

    const defaultSmartFilters = useMemo(() => {
        const smartFilters_tmp = ungapStructuredClone(smartFilters)

        if (stats?.[id]) {
            const values = {} as Record<string, any>
            (stats as FiltersStats<FilterDictionaryData>)[id]?.[0]?.data.options.forEach(item => {
                values[item.name] = item.amount
            })
            smartFilters_tmp[id] = values
        }

        return smartFilters_tmp
    },[smartFilters, data, stats])

    const [popularValues, setPopularValues] = useState(
        defaultPopularValues.map(item => String(item)) ??
        []
    )

    useEffect(() => {
        const params: Record<string | number, any> = parseSearchParams(searchParams)

        if (Object.keys(isActive).length > 0 && !isActive[id] && !params[id]) {
            setState({})
        }
    }, [values])

    const needCommit = useHasChanged(state)
    // const isDictionaryDisabled = () =>
    //     Object.keys(state).filter(key => state[key]).length >= MAX_COUNT

    const columnCount = data.options?.length >= 50 ? (data.options?.length >= 85 ? 4 : 3) : 2

    const openPopper = (e: any) => {
        setPopper({
            open: !popper.open,
            anchorEl: e.currentTarget,
        })
    }

    const closePopper = () => popper.open && setPopper({open: false, anchorEl: null})

    const handleChange = (id: string | number) => (e: any) => {
        if (!popularValues.includes(String(id))) {
            setPopularValues(prevState => [...prevState, String(id)])
        }
        setChanged(true)
        setState(prevState => ({...prevState, [String(id)]: e.target.checked}))
    }

    function formatCount ({smartFilters, propId, filterId}:{
        smartFilters: SmartFilters,
        propId: string | number,
        filterId: string | number
    })
    {
        const count = smartFilters?.[filterId]?.[propId];
        if (count !== undefined && count > 0)
        {
            return `(${count})`;
        }
        else
        {
            return ''
        }
    }

    useEffect(() => {
        if (needCommit && isChanged) {
            dispatch(asideFiltersActions.updateFilterValue({id, newValue: state}))
            setChanged(false)
        }
    }, [state])

    useEffect(() => {
        let popular_main_tmp = defaultPopularValues.map(item => String(item)) ?? []

        if (values[id]) {
            const popular_tmp = Object.keys(
                Object.fromEntries(
                    Object.entries(values[id]).filter(item => item[1])
                )
            ).map(item => String(item))
                .filter(
                    item => filterIsIsset({
                        state: filterData, filter_id: id,
                        ...(
                            id.startsWith('fltr')
                            ? {value_name: item}
                            : {value_id: item}
                        )
                    }) &&
                    !popular_main_tmp.includes(item)
                )
            popular_main_tmp = popular_main_tmp.concat(popular_tmp)
        }

        setPopularValues([...popular_main_tmp])
    },[values, stats])

    const [windowWidth/*, windowHeight*/] = useWindowSize()

    return (
        <div className="tdx-filter-dictionary">
            {!!popularValues.length &&
                popularValues.map(opt_id => {
                    const opt = data.options?.find(o => (
                        id.startsWith('fltr')
                        ? o.name.toLowerCase() == opt_id
                        : o.id == opt_id
                    ))

                    return opt ? (
                        <TDXCheckbox
                            key={opt_id}
                            checked={state[opt.id] ?? false}
                            label={opt?.name}
                            shadowLabel={formatCount({
                                smartFilters: defaultSmartFilters,
                                filterId: id,
                                propId: opt.name
                            })}
                            tooltipLabel={orderStatusesDescription?.[orderStatuses?.[Number(opt.id)] as OrderStatus] ?? ''}
                            onChange={handleChange(opt.id)}
                            disabled={
                                /*isDictionaryDisabled() && !state[opt.id] ||*/
                                filterOptionIsDisabled({
                                    //filterIsActive: isActive,
                                    filterId: id,
                                    optId: opt.id,
                                    optName: opt.name,
                                    smartFilters: defaultSmartFilters,
                                }) && disableEmptyValues && !state[opt.id]
                            }
                        />
                    ): ''
                })}
            {/*{data.options?.length > 10 && (*/}

            {/*data.options.map(opt => (
                <div key={`${opt.id}`}>
                    {state[opt.id] ? (
                        <TDXCheckbox
                            key={opt.id}
                            checked={state[opt.id] ?? false}
                            label={opt.name}
                            shadowLabel={opt.count ? `(${opt.count})` : ''}
                            onChange={handleChange(opt.id)}
                            disabled={isDictionaryDisabled() && !state[opt.id]}
                        />
                    ):''}
                </div>
            ))*/}

            <>
                <TDXButton
                    size="small"
                    bg="gray select"
                    endIcon={<ArrowDropDownIcon />}
                    onClick={openPopper}>
                    Все {data.options.length} вариантов
                </TDXButton>
                <Popper open={popper.open} anchorEl={popper.anchorEl} placement={`${windowWidth>768? 'right': 'bottom-end'}`}>
                    <ClickAwayListener onClickAway={closePopper}>
                        <Paper className="tdx-dictionary-paper" elevation={12}>
                            <FormControl className="tdx-scrollbar">
                                <div className={`columns-wrapper column-${columnCount}`}>
                                    {data.options.map(opt => (
                                        <div className="dictionary-option" key={opt.id}>
                                            <TDXCheckbox
                                                checked={state[opt.id] ?? false}
                                                label={opt.name}
                                                shadowLabel={formatCount({
                                                    smartFilters: defaultSmartFilters,
                                                    filterId: id,
                                                    propId: opt.name
                                                })}
                                                tooltipLabel={orderStatusesDescription?.[orderStatuses?.[Number(opt.id)] as OrderStatus] ?? ''}
                                                onChange={handleChange(opt.id)}
                                                disabled={
                                                    /*isDictionaryDisabled() && !state[opt.id] ||*/
                                                    filterOptionIsDisabled({
                                                        //filterIsActive: isActive,
                                                        filterId: id,
                                                        optId: opt.id,
                                                        optName: opt.name,
                                                        smartFilters: defaultSmartFilters,
                                                    }) && disableEmptyValues && !state[opt.id]
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </FormControl>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </>
            {/*)}*/}
        </div>
    )
}

export default TDXFilterDictionary

export function valuesToFalse (obj: object): any {
    return {
        ...Object.fromEntries(
            Object.entries(obj).map((val: any) => {
                return [
                    val[0],
                    val[1] ? (typeof val[1] === 'object' ? valuesToFalse(val[1]) : false) : false,
                ]
            })
        ),
    }
}

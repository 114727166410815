export function translit (str: string = '', str_default: string = '', lower: boolean = false) {
    const isString = (value: any) => typeof value === 'string' || value instanceof String;

    if (!isString(str) || str?.length === 0) {
        return lower ? str_default.toLowerCase() : str_default;
    }

    const ru: Record<string, string> = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya', ' ': '-'
    };

    let n_str: any = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

    for (let i = 0; i < str.length; ++i) {
        n_str.push(
            (
                ru[str[i]] || (
                    ru[str[i].toLowerCase()] === undefined && str[i]
                )
            ) ||
            ru[str[i].toLowerCase()].replace(
                /^(.)/, function (match) { return match.toUpperCase() }
            )
       );
    }

    n_str = n_str.join('');

    return lower ? n_str.toLowerCase() : n_str;
}

import {createAsyncThunk} from '@reduxjs/toolkit'
import CatalogService from '../../../../../api/catalog.service'
import {ProductSerializer, CategoryProductsSerializer} from '../../types'
import {FilterData} from '../../../asideFiltersSlice/types'

export const getCategoryProducts = createAsyncThunk<
    CategoryProductsSerializer,
    {id: string; signal: unknown; params: unknown}
>('catalog/getCategoryProducts', async ({id, signal, params}) => {
    try {
        return (await CatalogService.getCategoryProducts(id, signal, params))
    } catch (e) {
        console.error(e)
        return []
    }
})

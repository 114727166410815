import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {clearCompare} from '../../../store/actions/CompareActions'
import {useAppSelector} from "../../../shared/hooks/hooks";

const TDXCompareListButton = () => {
    const dispatch = useDispatch()

    const {items} = useAppSelector(state => state['CompareList'])

    const clearCompareList = () => dispatch(clearCompare())

    if (!items.length) return null

    return (
        <div className="tdx-compare-list-button">
            <div className="button-block">
                <Link to={`/compare/${items.join('+')}`} className="button body-2">
                    {items.length} товар(а) в сравнении
                </Link>
                <p className="button body-2" onClick={clearCompareList}>
                    Очистить
                </p>
            </div>
        </div>
    )
}

export default TDXCompareListButton

export const emptyUser = {
    username: 'guest',
    token: 'guest',
    partner: {
        id: 0,
        name: '',
        manager: {
            name: '',
            email: '',
            phone: '',
        },
    },
}

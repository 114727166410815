import {requestHelper} from '../../helpers/requestHelper'
import catalogService, {CatalogRequestsEnum} from '../../api/catalog.service'
import {CommonSettings, NewsSerializer, Question, SearchSerializer} from "./GeneralActions";
import {ProductOfferSerializer} from "../slice/catalogSlice/types";

export const TOGGLE_COMPARE_PRODUCT = 'TOGGLE_COMPARE_PRODUCT'
export const CLEAR_COMPARE = 'CLEAR_COMPARE'
export const SAVE_PRODUCTS_TO_COMPARE = 'SAVE_PRODUCTS_TO_COMPARE'

export interface CompareState {
    items: number[],
    products: {
        id?: Record<number, number>,
        names?: Record<number, string>,
        images?: Record<number, string>,
        prices?: Record<number, number>,
        offers?: Record<number, ProductOfferSerializer[]>,
        params?: {
            name: string,
            options:{
                name: string,
                values: Record<number, any>
            }[],
        }[],
    }
}

export const toggleCompareProduct = (id: number) => ({
    type: TOGGLE_COMPARE_PRODUCT,
    data: {id},
})

export const clearCompare = () => ({
    type: CLEAR_COMPARE,
})

export const saveProductsToCompare = (ids: number[]) => (dispatch: any) => {
    requestHelper(dispatch, CatalogRequestsEnum.compareProducts, async () => {
        const products = await catalogService.compareProducts(ids) as CompareState['products']

        dispatch({
            type: SAVE_PRODUCTS_TO_COMPARE,
            data: products,
        })
    })
}

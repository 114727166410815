import {get, post} from './api'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Cart, CartProductSerializer} from '../store/slice/cartSlice/types'
//import {enqueueSnackbar} from 'notistack'

export interface OrderRearrangeSerializer {
    source_order_id?: number
    target_order_id?: number
    source_products?: {
        catalogapp_id: number,
        contract_id: number
    }[]
    source_order_product_ids?: number[]
}

export interface OrderCreateSerializer {
    comment?: string
    products?: {
        contract_id: number,
        catalogapp_id: number,
        supplier_id: number,
    }[]
}

export const CartService = {
    createOrder: async (body : OrderCreateSerializer, raw : boolean) => {
        let res : any = null;

        if (raw === undefined || raw === null)
        {
            raw = false;
        }

        if (body.comment === '')
        {
            body.comment = undefined
        }

        try
        {
            res = await post(
                '/order/create', body, {abc: 1},
            );
        } catch (e) {
            console.error(e)
            if (e.status === 503)
            {
                res = e.error.response.data;
            }
            else
            {
                throw e;
            }
            //return thunkAPI.rejectWithValue('cart/removeFromCart error, see logs')
        }

        if (raw)
        {
            return res;
        }
        else
        {
            if (res.meta !== undefined)
            {
                return res.meta;
            }
            else
            {
                return res;
            }
        }
    },
    rearrangeOrder: (body : OrderRearrangeSerializer) => post('/order/rearrange', body),
    getCart: async () : Promise<Cart> => {
      const res = await get('/cart');
      if (!(
        res?.total !== undefined &&
        res?.currency !== undefined
      ))
      {
        throw Error('invalid data from backend');
      }

      return res as Cart;
    },
    addToCart: async (body : any, {raw = false} = {}) => {
        const res = await post('/cart/add', body);
        if (raw)
        {
            return res;
        }
        else
        {
            return res.data;
        }
    },
    removeFromCart: (body : any) => post('/cart/remove', body),
    updateCartById: (body : any) => post('/cart/update', body),
    getStockQuantity: () => get('/cart/get_stock_quantity'),
}

export const CartServiceEnum = {
    getCart: () => `/cart`,
    getStockQuantity: () => `/cart/get_stock_quantity`,
}
import {Chip, Tooltip} from '@mui/material'

export default function TDXChip (props: any) {
    const {bg = '', label = '', tooltipLabel = '', initialClassName=null, ...other}: {
        bg: string,
        label: string,
        tooltipLabel?: string,
        initialClassName?: string | null,
    } = props

    let className = `tdx-chip ${'bg-' + bg}`;

    if (initialClassName !== null)
    {
        className += ' ' + initialClassName;
    }

    const chipTemplate = (
        <Chip {...other} label={label} className={className} />
    )

    return tooltipLabel.length > 0 ? (
        <Tooltip title={tooltipLabel}>
            {chipTemplate}
        </Tooltip>
    ): chipTemplate
}

import {useEffect} from 'react'
import {getFAQ} from '../../store/actions/GeneralActions'
import {getProfile} from '../../store/actions/ProfileActions'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import HeaderCategoriesMenu from './HeaderCategoriesMenu'
import HeaderPreMenu from './HeaderPreMenu'
import HeaderNotify from "./HeaderNotify";
import HeaderMenu from "./HeaderMenu";
import HeaderMobileMenu from "./HeaderMobileMenu";
import {TDXProfileBalance} from "../UI";
import { useWindowSize } from "../useWindowSize"
import TDXLogo from "../UI/TDXLogo"
import {enqueueSnackbar} from "notistack";
import GeneralService from "../../api/general.service";

const Header = () => {
    const [windowWidth/*, windowHeight*/] = useWindowSize()

    const dispatch = useAppDispatch()
    const questions = useAppSelector(state => state.General.questions)
    const {isSpecial} = useAppSelector(state => state.Profile.profile)
    const { lastMessage } = GeneralService.getNotification();

    useEffect(() => {
        dispatch<any>(getProfile())
        !questions.length && dispatch<any>(getFAQ())
    }, [])

    useEffect(() => {
        if(lastMessage) {
            let data = lastMessage.data
            try {
                data = JSON.parse(lastMessage.data);
            } finally {
                //console.log(data)
            }

            enqueueSnackbar((
                <div className={'notify-html'}>
                    <span dangerouslySetInnerHTML={{
                        __html: data?.message ?? data
                    }}>
                    </span>
                </div>
            ), {variant: data?.status ?? 'success', autoHideDuration: 15000})
        }
    }, [lastMessage]);


    if (windowWidth > 1280) {
        return (
            <>
                <header className="header header-pc">
                    <HeaderPreMenu />
                    <div className="header-navigation wrapper">
                        <TDXLogo />
                        <HeaderMenu />
                        <div>
                        {!isSpecial ? <TDXProfileBalance /> : ''}
                        </div>
                    </div>
                    <HeaderNotify />
                </header>
                <HeaderCategoriesMenu />
            </>
        )
    } else {
        return (
            <>
                <header className="header header-mob">
                    <HeaderPreMenu />
                    <div className="header-navigation wrapper">
                        <HeaderMobileMenu />
                    </div>
                    <HeaderNotify />
                </header>
            </>
        )
    }
}

export default Header

import {useState, useCallback, useEffect} from 'react'
import PollPopup from '../Popups/PollPopup'
import {useAppSelector} from "../../../shared/hooks/hooks";
import {namedRequestsInProgress} from '../../../store/RequestSelectors'
import {GeneralRequestsEnum} from '../../../api/general.service'
import {TDXButton} from '../../UI'

const TDXPollPopupButton = ({
    label='',
    slug,
    bg=null
} : {
    label: string,
    slug: string,
    bg?: string
}) => {
    const [open, setOpen] = useState<HTMLDivElement>(null)
    const {polls, isLoading} = useAppSelector(state => ({
        ...state.General.common,
        isLoading: namedRequestsInProgress(state, GeneralRequestsEnum.getPolls),
    }))

    const toggleMenu = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (open) {
            setOpen(null)
        } else {
            setOpen(e?.currentTarget)
        }
    }, [open])

    return (
        <>
            <TDXButton
                size="medium"
                bg={bg}
                onClick={toggleMenu}
                loading={isLoading}>
                {label}
            </TDXButton>
            {polls?.length > 0 && polls.filter(p => p.slug === slug).map(poll => (
                <PollPopup poll={poll} key={poll.id} isOpenPopup={open != null} onClose={toggleMenu} />
            ))}
        </>
    )
}

export default TDXPollPopupButton
export const downloadFile = (url: Blob, fileName: string) => {
    const fileURL = URL.createObjectURL(url)

    const anchor = document.createElement('a')
    anchor.href = fileURL
    anchor.download = fileName

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(fileURL)
}

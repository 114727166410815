import {Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason} from '@mui/material'
import {TDXCircularProgress} from '../index'
import TDXOutlinedInput from './TDXOutlinedInput'

interface TDXAutocompleteInterface {
    options?: any[],
    label?: string,
    className?: string,
    error?: boolean,
    clearable?: boolean,
    disabled?: boolean,
    value?: any,
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => void,
    getOptionLabel?: (option: any) => string,
    style?: any,
    loading?: boolean,
    disableListWrap?: boolean,
    renderOption?: (optionProps:any, option: any, selected: any) => React.ReactNode,
    ListboxComponent?: any,
}

const TDXAutocomplete = (props: TDXAutocompleteInterface) => {
    const {options, label = '', className = '', error = false, clearable = false, ...other} = props

    return (
        <Autocomplete
            {...other}
            className={`tdx-autocomplete ${className}`}
            options={options}
            disableClearable={!clearable}
            noOptionsText="Нет вариантов"
            renderInput={params => {
                return (
                    <TDXOutlinedInput
                        {...params}
                        label={label}
                        inputProps={{
                            ...params.inputProps,
                        }}
                        disabled={props.disabled}
                        error={error}
                    />
                )
            }}
            ListboxProps={{className: 'tdx-scrollbar'}}
            loadingText={<TDXCircularProgress size={20} />}
            disabled={props.disabled}
        />
    )
}

export default TDXAutocomplete

import {configureStore} from '@reduxjs/toolkit'
import reducers from './reducers'
import {asideFiltersReducer} from './slice/asideFiltersSlice/asideFiltersSlice'
import {authReducer} from './slice/authSlice/authSlice'
import {cartReducer} from './slice/cartSlice/cartSlice'
import {priceListReducer} from './slice/priceListSlice/priceListSlice'
import {catalogCategoriesReducer} from './slice/catalogCategoriesSlice/catalogCategoriesSlice'
import {catalogReducer} from './slice/catalogSlice/catalogSlice'
import {ordersReducer} from './slice/ordersSlice/ordersSlice'
import {useSelector as useReduxSelector, TypedUseSelectorHook} from 'react-redux'

export const store = configureStore({
    reducer: {
        ...reducers,
        Auth: authReducer,
        AsideFilters: asideFiltersReducer,
        Cart: cartReducer,
        PriceList: priceListReducer,
        CatalogCategories: catalogCategoriesReducer,
        Catalog: catalogReducer,
        Orders: ordersReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const typedUseSelector : TypedUseSelectorHook<RootState> = useReduxSelector

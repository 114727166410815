import {
    CLEAR_COMPARE, CompareState,
    SAVE_PRODUCTS_TO_COMPARE,
    TOGGLE_COMPARE_PRODUCT,
} from '../actions/CompareActions'

const initialState: CompareState = {
    items: [],
    products: {},
}

const sliceItems = (items: CompareState['items'], id: number) => {
    const i = items.indexOf(id)
    switch (i) {
        case -1:
            return [...items, id]
        case 0:
            return [...items.slice(1)]
        case items.length - 1:
            return [...items.slice(0, -1)]
        default:
            return [...items.slice(0, i), ...items.slice(i + 1)]
    }
}

export const CompareReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TOGGLE_COMPARE_PRODUCT:
            return {
                ...state,
                items: sliceItems(state.items, action.data.id),
            }
        case CLEAR_COMPARE:
            return {
                ...state,
                items: [],
            }
        case SAVE_PRODUCTS_TO_COMPARE:
            return {
                ...state,
                products: action.data,
            }
        default:
            return state
    }
}

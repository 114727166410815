import {get, post} from './api'
import {Partner, User} from "../store/slice/authSlice/types";

export const ManagerService = {
    getUsersList: () => get('/users'),
    createUser: (user: User) => post('/user/create', user),
    updateUser: (user: User) => post(`/user/${user.id}`, user),
    getPartnersList: () => get('/partners'),
    createPartner: (partner: Partner) => post('/partner/create', partner),
    updatePartner: (partner: Partner) => post(`/partner/${partner.id}`, partner),
    getUsers1CList: () => get('/partner/users'),
    getPartnerContactInformation: () => get('/partner/contact-information'),
}

export const ManagerRequestsEnum = {
    getUsersList: '/users',
    createUser: '/user/create',
    updateUser: (id: number) => `/user/${id}`,
    getPartnersList: '/partners',
    createPartner: '/partner/create',
    updatePartner: (id: number) => `/partner/${id}`,
    getUsers1CList: '/partner/users',
    getPartnerContactInformation: () => '/partner/contact-information',
}

export default ManagerService

import {get, post} from './api'

export interface NotificationSerializer {
    type: string,
    msg: string
}

const ProfileService = {
    getProfile: () => get('/auth/profile'),
    updateProfile: (body : any) => post('/auth/profile', body),
    getProfilePayments: () => get('/auth/payments'),
    getProfileContracts: () => get('/auth/contracts'),
    updateProfileContracts: () => post('/auth/contracts'),
    getWaitingList: () => get('/waitlist'),
    getWaitingListMini: () => get('/waitlist/mini'),
    updateWaitingList: ({type, body} : {type: string, body: any}) => post(`/waitlist/${type}`, body),
    updateUserPassword: (body : any) => post('/auth/change', body),
    getOrders: (signal : any, params : any) => get('/order/', {signal, params}),
    getReserveOrders: (signal : any, params : any) => get('/order/', {signal, params}),
    getOrdersFilters: () => get('/order/filters'),
    getSupplies: () => get('/supplies'),
    getSuppliersList: () => get('/suppliers'),
    getRefusals: () => get('/cancels'),
    getMatching: (body : any) => post('/matching', body),
    generateApiData: () => get('/user/generate-api-data'),
}

export const ProfileRequestsEnum = {
    getProfile: '/auth/profile',
    updateProfile: '/auth/profile/update',
    getProfilePayments: '/auth/payments',
    getProfileContracts: '/auth/contracts',
    updateProfileContracts: '/auth/contracts',
    getWaitingList: '/waitlist',
    getWaitingListMini: '/waitlist/mini',
    //updateWaitingList: (sku : number) => `/waitlist/add/${sku}`,
    // likely just hashing for requests, based on first sku
    // but looks really weird
    updateWaitingList: (sku : string | number) => `/waitlist/add/${sku}`,
    updateUserPassword: '/auth/change',
    getOrders: '/order/',
    getReserveOrders: '/order/',
    getOrdersFilters: '/order/filters',
    getSupplies: '/supplies',
    getSuppliersList: '/suppliers',
    getRefusals: '/cancels',
    getMatching: '/matching',
    generateApiData: '/user/generate-api-data',
}

export default ProfileService

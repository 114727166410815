import {Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason} from '@mui/material'
import {TDXCircularProgress} from '../index'
import TDXInput from './TDXInput'

interface TDXAutocompleteDefaultInterface {
    id?: string,
    options: any[],
    label?: string,
    className?: string,
    error?: boolean,
    clearable?: boolean,
    disabled?: boolean,
    value?: any,
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => void,
    getOptionLabel?: (option: any) => string,
    style?: any,
    loading?: boolean,
}

const TDXAutocompleteDefault = (props: TDXAutocompleteDefaultInterface) => {
    const {options, label = '', className = '', error = false, clearable = false, ...other} = props

    return (
        <Autocomplete
            {...other}
            className={`tdx-autocomplete ${className}`}
            options={options}
            disableClearable={!clearable}
            noOptionsText="Нет вариантов"
            renderInput={params => {
                return (
                    <>
                        <TDXInput
                            {...params}
                            label={label}
                            inputProps={{
                                ...params.inputProps,
                            }}
                            disabled={props.disabled}
                            error={error}
                        />
                    </>
                )
            }}
            ListboxProps={{className: 'tdx-scrollbar'}}
            loadingText={<TDXCircularProgress size={20} />}
            disabled={
                props.disabled ||
                options?.length === 0 ||
                options?.filter(o => o === undefined).length > 0
            }
        />
    )
}

export default TDXAutocompleteDefault

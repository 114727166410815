import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Fade, Skeleton} from '@mui/material'
import {resizer} from '../../utils/resizer'
import {getNoun} from '../../utils/getNoun'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {catalogCategoriesActions} from '../../store/slice/catalogCategoriesSlice/catalogCategoriesSlice'
import {getCategories} from "../../store/slice/catalogCategoriesSlice/services/getCategories/getCategories";

const HeaderCategoriesMenu = () => {
    const dispatch = useAppDispatch()
    const categories = useAppSelector(state => state.CatalogCategories.categories)
    const categoriesLastFetch = useAppSelector(state => state.CatalogCategories.lastFetch)
    const activeCategory = useAppSelector(state => state.CatalogCategories.activeCategory)
    const activeSubCategory = useAppSelector(state => state.CatalogCategories.activeSubCategory)
    const currentCategory = useAppSelector(state => state.Catalog.category)
    const isLoading = useAppSelector(state => state.CatalogCategories.loading)
    const isOpen = useAppSelector(state => state.CatalogCategories.open)
    const isActiveCategory = (id: number) => activeCategory && activeCategory.id === id
    const isActiveSubCategory = (id: number) => activeSubCategory && activeSubCategory.id === id

    useEffect(() => {
        if (categoriesLastFetch === null && !isLoading && (
            categories === undefined || categories.length === 0)
        ) {
            dispatch<any>(getCategories())
        }
    }, [categories, isLoading])

    const toggleMenu = (visible: boolean) => {
        const body = document.querySelector('body')
        const main = document.querySelector('main')

        if (visible) {
            body.style.overflow = 'hidden scroll'
            main.style.height = '0'
        } else {
            setTimeout(() => {
                body.style.overflow = 'auto'
            }, 300)
            main.style.height = 'auto'
        }
    }

    const closeCategoriesMenu = () => {
        dispatch(catalogCategoriesActions.setVisibility(false))
        // setTimeout(() => {
        //     dispatch(catalogCategoriesActions.resetActiveCategories())
        // }, 300)
    }

    useEffect(() => { activeCategory && toggleMenu(isOpen) }, [isOpen])

    return (
        <div className="height-fix">
            <div className={`tdx-catalog-menu ${isOpen ? 'active' : ''}`} data-catalog-menu={true}>
                <div className="height-fix">
                    {isLoading ? (
                        <>
                            <ul className="aside-catalog-menu tdx-scrollbar">
                                {[...Array(10)].map((v,k) => (
                                    <li key={k} className={`aside-item subtitle-1`}>
                                        <Skeleton variant="rectangular" height={25} />
                                    </li>
                                ))}
                            </ul>
                            <ul className="aside-catalog-menu tdx-scrollbar">
                                {[...Array(5)].map((v,k) => (
                                    <li key={k} className={`aside-item subtitle-1`}>
                                        <Skeleton variant="rectangular" height={25} />
                                    </li>
                                ))}
                            </ul>
                            <div className="beside-catalog-menu tdx-scrollbar">
                                {[...Array(8)].map((v,k) => (
                                        <Fade in={true} timeout={500} key={k}>
                                            <div className={`beside-item`} style={{gap: '10px'}}>
                                                <Skeleton variant="rectangular" width={48} height={48} className="subtitle-2" />
                                                <div className="info" style={{gap: '10px'}}>
                                                    <Skeleton variant="rectangular" height={15} className="body-2" />
                                                    <Skeleton variant="rectangular" width={'50%'} height={15} className="subtitle-1" />
                                                </div>
                                            </div>
                                        </Fade>
                                    ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <ul className="aside-catalog-menu tdx-scrollbar">
                                {categories.length > 0 &&
                                    categories.map(category => (
                                        <li
                                            key={category.id}
                                            className={`aside-item subtitle-1 ${
                                                isActiveCategory(category.id) ? 'active' : ''
                                            }`}
                                            onClick={() =>
                                                dispatch(
                                                    catalogCategoriesActions.setActiveCategory(category)
                                                )
                                            }>
                                            {category.name}
                                        </li>
                                    ))}
                            </ul>
                            <ul className="aside-catalog-menu tdx-scrollbar">
                                {activeCategory &&
                                    activeCategory['subcategories'].map(category => (
                                        <li
                                            key={category.id}
                                            className={`aside-item subtitle-1 ${
                                                isActiveSubCategory(category.id) ? 'active' : ''
                                            }`}
                                            onClick={() =>
                                                dispatch(
                                                    catalogCategoriesActions.setActiveSubCategory(category)
                                                )
                                            }>
                                            {category.name}
                                        </li>
                                    ))}
                            </ul>
                            <div className="beside-catalog-menu tdx-scrollbar">
                                {activeSubCategory &&
                                    activeSubCategory['subcategories'].map(category => (
                                        <Fade in={!!activeSubCategory} timeout={500} key={category.id}>
                                            {currentCategory?.id === category.id ? (
                                                <div className={`beside-item current`} onClick={closeCategoriesMenu}>
                                                    <img
                                                        className="subtitle-2"
                                                        src={resizer(category.image, 48, 48)}
                                                        alt={category.name}
                                                    />
                                                    <div className="info">
                                                        <span className="body-2">{category.name}</span>
                                                        <span className="subtitle-1">
                                                            {category.count}{' '}
                                                            {getNoun(
                                                                category.count,
                                                                'товар',
                                                                'товара',
                                                                'товаров'
                                                            )}
                                                        </span>
                                                        {/*<span className="subtitle-1">*/}
                                                        {/*    от {category.price} BYN*/}
                                                        {/*</span>*/}
                                                    </div>
                                                </div>
                                            ): (
                                                <Link className={`beside-item`} to={`/catalog/${category.id}`}>
                                                    <img
                                                        className="subtitle-2"
                                                        src={resizer(category.image, 48, 48)}
                                                        alt={category.name}
                                                    />
                                                    <div className="info">
                                                        <span className="body-2">{category.name}</span>
                                                        <span className="subtitle-1">
                                                            {category.count}{' '}
                                                            {getNoun(
                                                                category.count,
                                                                'товар',
                                                                'товара',
                                                                'товаров'
                                                            )}
                                                        </span>
                                                        {/*<span className="subtitle-1">*/}
                                                        {/*    от {category.price} BYN*/}
                                                        {/*</span>*/}
                                                    </div>
                                                </Link>
                                            )}
                                        </Fade>
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HeaderCategoriesMenu

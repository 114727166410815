import {TDXButton} from './Buttons'

const TDXForm = ({onSubmit, children, action = {}, className = ''}: {
    onSubmit?: () => void,
    children?: React.ReactNode,
    action?: Record<string, any>,
    className?: string,
}) => {
    const handleSubmit = (e: any) => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <form className={`tdx-form `+className} onSubmit={handleSubmit}>
            <div className="inputs-container">{children}</div>
            <div className="submit-container">
                {Array.isArray(action) ? (
                    <div className="buttons">
                        {action.map((btn, i) => (
                            <TDXButton
                                key={i}
                                type={btn.type ?? "submit"}
                                size="medium"
                                bg={btn.btnBg ?? ((+i + 1) % 2 !== 0 ? 'transparent border' : 'black')}
                                onClick={btn.onClick}
                                disabled={btn.disabled}
                                loading={btn.loading}
                            >
                                {btn.label}
                            </TDXButton>
                        ))}
                    </div>
                ) : (
                    <TDXButton
                        type="submit"
                        size="medium"
                        bg={action.submitBg ?? 'black'}
                        disabled={action.disabled ?? false}
                        loading={action.loading ?? false}>
                        {action.label || 'Сохранить изменения'}
                    </TDXButton>
                )}
            </div>
        </form>
    )
}

export default TDXForm

import {createAsyncThunk} from '@reduxjs/toolkit'
import {enqueueSnackbar} from 'notistack'
import {Link} from 'react-router-dom'
import OrderService from "../../../api/order.service";
import {ordersActions} from "./ordersSlice";
import {RootState} from "../../store";
import {downloadFile} from "../../../shared/utils/downloadFile/downloadFile";
import {CertificatesRequest, generateCertificatesResponse} from "./types";

export const getCertificates = createAsyncThunk<void, CertificatesRequest>(
    'order/getCertificates',
    async ({order_id, products}, {getState, rejectWithValue, dispatch}) => {
        try {
            dispatch(
                ordersActions.setCertificateData({
                    order_id: order_id,
                    polling: 3,
                    loading: true,
                })
            )

            if (products.length > 1) {
                enqueueSnackbar("Процесс скачивания сертификатов запущен и требует дополнительного времени. " +
                    "Как только файлы будут готовы, вам придет уведомление со ссылкой на скачивание.", {variant: 'success'})
            }

            const {polling} = (getState() as RootState).Orders.certificates[order_id]

            const interval = setInterval(async () => {
                const {polling} = (getState() as RootState).Orders.certificates[order_id]
                if (polling == 0) {
                    clearInterval(interval)
                } else {
                    await dispatch(generateCertificates({order_id, products}))
                }
            }, (polling as number) * 1000)

        } catch (e) {
            console.error(e)
            return rejectWithValue('order/getCertificates error, see logs')
        }
    }
)

export const generateCertificates = createAsyncThunk<void, CertificatesRequest>(
    'order/generateCertificates',
    async ({order_id, products}, {getState, rejectWithValue, dispatch}) => {
        try {
            const resp = await OrderService.generateCertificate(order_id, products) as generateCertificatesResponse;

            if (resp?.url?.length == 0 && resp?.errors?.length > 0) {
                enqueueSnackbar((
                    <div className={'notify-html'}>
                        <span>{resp?.errors}</span>
                    </div>
                ), {variant: 'warning'})
                dispatch(ordersActions.setCertificateData({order_id: order_id, polling: 0, loading: false}))
            }

            if (resp?.url?.length > 0) {
                dispatch(ordersActions.setCertificateData({order_id: order_id, url: resp.url}))

                if (products.length > 1) {
                    enqueueSnackbar((
                        <div className={'notify-html'}>
                            <p className={'no-margin'}>{resp?.details} <Link to={resp?.url} target={'_blank'}>{resp?.url}</Link></p>
                            {resp?.errors?.length > 0 && <p className={'no-margin'}>{resp?.errors}</p>}
                        </div>
                    ), {variant: 'success', autoHideDuration: 120000})
                } else {
                    enqueueSnackbar((
                        <div className={'notify-html'}>
                            <span>{resp?.details}</span>
                        </div>
                    ), {variant: 'success'})

                    const {...response}: generateCertificatesResponse = await OrderService.getCertificate(order_id, {filename: resp?.filename})
                    if (response?.file) {
                        downloadFile(response?.file, response?.filename)
                    } else {
                        console.log(response)
                    }
                }

                dispatch(ordersActions.setCertificateData({order_id: order_id, polling: 0, loading: false}))
            }

        } catch (e) {
            console.error(e)
            return rejectWithValue('order/generateCertificates error, see logs')
        }
    }
)